import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { Guideline } from "@pages";
import ListItemIcon from "@mui/material/ListItemIcon";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import CloseIcon from "@mui/icons-material/Close";

/* import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

const stylesPDF = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }

});

const MyPDFDocument = () => (
  <Document>
    <Page size="A4" style={stylesPDF.page}>
      <View style={stylesPDF.section}>
        <Text>Section #1</Text>
      </View>
      <View style={stylesPDF.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
); */

const validationSchemaTraining = yup.object({
  Name: yup.string("Enter something meaningful").required("Field is required"),
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

function Training() {
  let navigate = useNavigate();
  const confirm = useConfirm();

  const [tab, setTab] = useState("1");
  const [tabPreview, setTabPreview] = useState("1");
  const [themes, setThemes] = useState([]);
  const [themesAttack, setThemesAttack] = useState([]);
  const [themesDefend, setThemesDefend] = useState([]);

  const theUserID = sessionStorage.getItem("userId");
  const theUserType = sessionStorage.getItem("userType");

  const [values, setValues] = useState([]);

  const [openGuide, setOpenGuide] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);

  const toggleGuide = (newOpen) => () => {
    setOpenGuide(newOpen);
  };

  const togglePreview = (newOpen) => () => {
    setOpenPreview(newOpen);
  };

  useEffect(() => {
    GetTraining();
    FetcThemes();
  }, []);

  const [searchParams] = useSearchParams();
  const theId = searchParams.get("id");

  const sftoken = sessionStorage.getItem("sftoken");

  const sfdemouser = sessionStorage.getItem("demoUser");

  const CloneProgram = async () => {
    if (sfdemouser === "true") {
      toast("Data is not cloned because this is a demo session!");
      return;
    }
    var cloneValues = values;

    cloneValues.Name = "Cloned from " + cloneValues.Name;
    cloneValues.Coach__c = theUserID;
    cloneValues.State__c = "Draft";
    delete cloneValues.Id;
    delete cloneValues.SystemModstamp;
    delete cloneValues.IsDeleted;
    delete cloneValues.CreatedById;
    delete cloneValues.CreatedDate;
    delete cloneValues.LastActivityDate;
    delete cloneValues.LastModifiedById;
    delete cloneValues.LastModifiedDate;
    delete cloneValues.Sequence_Number__c;

    //console.log("cloneValues = ", cloneValues);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(cloneValues);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/",
      requestOptions
    );

    const data = await response.json();
    //console.log("data = ", data);

    try {
      toast("Program cloned");
      GetTraining(data.id);
    } catch (err) {
      console.log(err);
    }
  };

  function confirmDeleteFirst() {
    confirm({
      description: "Do you really want to delete this program",
    })
      .then(() => {
        DeleteTraining();
      })
      .catch(() => {
        /* ... */
      });
  }

  function confirmCloneFirst() {
    confirm({
      description: "Do you really want to clone this program",
    })
      .then(() => {
        CloneProgram();
      })
      .catch(() => {
        /* ... */
      });
  }

  function confirmPublishFirst() {
    confirm({
      description: "Do you really want to publish this program",
    })
      .then(() => {
        PublishTraining(formikTraining.values);
      })
      .catch(() => {
        /* ... */
      });
  }

  const DeleteTraining = () => {
    if (sfdemouser === "true") {
      toast("Data is not deleted because this is a demo session!");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/" +
        theId,
      requestOptions
    )
      .then(() => navigate("/training-programs"))
      .catch((error) => console.log("error", error));
  };

  const GetTraining = async (theCloneId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var theGetId = theId;

    if (theCloneId) {
      theGetId = theCloneId;
    }

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/" +
        theGetId,
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);

    try {
      setValues(data);
    } catch (err) {
      console.log(err);
    }
  };

  function splitThemes(rawData) {
    var attackData = rawData;
    attackData = attackData.reduce((filterItems, item) => {
      if (item.Grouping__c === "Attacking - ”Creating”") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setThemesAttack(attackData);

    var defendData = rawData;
    defendData = defendData.reduce((filterItems, item) => {
      if (item.Grouping__c === "Defending – ”Resolving”") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setThemesDefend(defendData);
  }

  const FetcThemes = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Grouping__c";
    sqol = sqol + ", Sequence__c";
    sqol = sqol + " from Theme__c";
    sqol = sqol + " ORDER BY Grouping__c ASC, Sequence__c ASC";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      setThemes(data.records);
      splitThemes(data.records);
    } catch (err) {
      console.log(err);
    }
  };

  function lookupTheme(theId) {
    for (let x in themes) {
      if (themes[x].Id === theId) {
        return themes[x].Name;
      }
    }
    return null;
  }

  function SaveTraining(theValues) {
    if (sfdemouser === "true") {
      toast("Data is not saved because this is a demo session!");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: theValues,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/" +
        values.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then(() => navigate("/training-programs"))
      .catch((error) => console.log("error", error));
  }

  function blockPublish(theValues) {
    var somethingIsMissing = false;
    if (theValues.Togetherness_Moment_How__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Togetherness_Moment_Intro__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Togetherness_Moment_Progress__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Togetherness_Moment_What__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Togetherness_Moment_Why__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Technical_Moment_How__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Technical_Moment_Intro__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Technical_Moment_Progress__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Technical_Moment_What__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Technical_Moment_Why__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Progress__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_What__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Why__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_How__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Intro__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Moment_How__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Moment_Intro__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Moment_Progress__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Moment_What__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Game_Moment_Why__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Theme__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Name === "") {
      somethingIsMissing = true;
    }
    return somethingIsMissing;
  }

  function PublishTraining(theValues) {
    if (sfdemouser === "true") {
      toast("Data is not saved because this is a demo session!");
      return;
    }

    if (blockPublish(theValues)) {
      toast("All fields are mandatory, except Reflection!");
      return;
    }

    var publishValues = theValues;

    publishValues.State__c = "Published";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(publishValues, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/" +
        values.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then(() => navigate("/training-programs"))
      .catch((error) => console.log("error", error));
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleTabChangePreview = (event, newValue) => {
    setTabPreview(newValue);
  };

  const formikTraining = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: values?.Name || "",
      Coach__c: values?.Coach__c || "",
      Theme__c: values?.Theme__c || "",
      State__c: values?.State__c || "",
      Team__c: values?.Team__c || "",
      Program__c: values?.Program__c || "",
      Type__c: values?.Type__c || "",

      Reflections__c: values?.Reflections__c || "",

      Game_How__c: values?.Game_How__c || "",
      Game_Intro__c: values?.Game_Intro__c || "",
      Game_Progress__c: values?.Game_Progress__c || "",
      Game_What__c: values?.Game_What__c || "",
      Game_Why__c: values?.Game_Why__c || "",
      Game_Moment_How__c: values?.Game_Moment_How__c || "",
      Game_Moment_Intro__c: values?.Game_Moment_Intro__c || "",
      Game_Moment_Progress__c: values?.Game_Moment_Progress__c || "",
      Game_Moment_What__c: values?.Game_Moment_What__c || "",
      Game_Moment_Why__c: values?.Game_Moment_Why__c || "",
      Technical_Moment_How__c: values?.Technical_Moment_How__c || "",
      Technical_Moment_Intro__c: values?.Technical_Moment_Intro__c || "",
      Technical_Moment_Progress__c: values?.Technical_Moment_Progress__c || "",
      Technical_Moment_What__c: values?.Technical_Moment_What__c || "",
      Technical_Moment_Why__c: values?.Technical_Moment_Why__c || "",
      Togetherness_Moment_How__c: values?.Togetherness_Moment_How__c || "",
      Togetherness_Moment_Intro__c: values?.Togetherness_Moment_Intro__c || "",
      Togetherness_Moment_Progress__c:
        values?.Togetherness_Moment_Progress__c || "",
      Togetherness_Moment_What__c: values?.Togetherness_Moment_What__c || "",
      Togetherness_Moment_Why__c: values?.Togetherness_Moment_Why__c || "",
    },
    validationSchema: validationSchemaTraining,
    onSubmit: (values) => {
      SaveTraining(JSON.stringify(values, null, 2));
    },
  });

  return (
    <AuthenticatedLayout title="Training Program">
      <div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ m: 2 }} onClick={toggleGuide(true)}>
            Review Guideline
          </Button>
          <Button sx={{ m: 2 }} onClick={togglePreview(true)}>
            Preview Program
          </Button>
        </Box>

        <Drawer
          PaperProps={{
            sx: { width: "90%" },
          }}
          anchor="right"
          open={openGuide}
          onClose={toggleGuide(false)}
          onOpen={toggleGuide(true)}
        >
          {" "}
          <Box sx={{ pt: 2, pr: 2 }} display="flex" justifyContent="flex-end">
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleGuide(false)}
            />
          </Box>
          <Guideline />
        </Drawer>
        <Drawer
          PaperProps={{
            sx: { width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            } },
          }}
          anchor="right"
          open={openPreview}
          onClose={togglePreview(false)}
          onOpen={togglePreview(true)}
        >
          <Container>
            <Box
              sx={{ pt: 2, pr: 2 }}
              display="flex"
              justifyContent="space-between"
            >
              <Typography align="left" variant="h4">
                {formikTraining.values.Name || ""}
              </Typography>

              <CloseIcon
                fontSize="large"
                align="right"
                onClick={togglePreview(false)}
              />
            </Box>
            <Typography align="left" variant="body1">
              By {values.CoachName__c || "Name"}
            </Typography>
            {formikTraining.values.Theme__c && (
              <div>
                <NiceTextField
                  id="training-Theme__c"
                  InputProps={{
                    readOnly: Boolean(true),
                    disableUnderline: true,
                  }}
                  label="Theme"
                  name="Theme__c"
                  variant="standard"
                  multiline
                  value={lookupTheme(formikTraining.values.Theme__c)}
                />
              </div>
            )}
            {formikTraining.values.Reflections__c && (
              <div>
                <NiceTextField
                  id="training-Reflections__c"
                  InputProps={{
                    readOnly: Boolean(true),
                    disableUnderline: true,
                  }}
                  label="Reflection"
                  name="Reflections__c"
                  variant="standard"
                  multiline
                  value={formikTraining.values.Reflections__c || ""}
                />
              </div>
            )}
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabPreview}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChangePreview}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="1 Togetherness moment"
                      sx={{
                        color: "#FF3200",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                          xl: "flex",
                        },
                      }}
                      value="1"
                    />
                    <Tab
                      icon={<SportsSoccerIcon />}
                      sx={{
                        color: "#FF3200",
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "none",
                          xl: "none",
                        },
                      }}
                      value="1"
                    />
                    <Tab
                      label="2 Technical moment"
                      sx={{
                        color: "#FF33F3",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                          xl: "flex",
                        },
                      }}
                      value="2"
                    />
                    <Tab
                      icon={<SportsSoccerIcon />}
                      sx={{
                        color: "#FF33F3",
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "none",
                          xl: "none",
                        },
                      }}
                      value="2"
                    />
                    <Tab
                      label="3 Game moment"
                      sx={{
                        color: "#FAC200",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                          xl: "flex",
                        },
                      }}
                      value="3"
                    />
                    <Tab
                      icon={<SportsSoccerIcon />}
                      sx={{
                        color: "#FAC200",
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "none",
                          xl: "none",
                        },
                      }}
                      value="3"
                    />
                    <Tab
                      label="4 Game"
                      sx={{
                        color: "#00B287",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                          xl: "flex",
                        },
                      }}
                      value="4"
                    />
                    <Tab
                      icon={<SportsSoccerIcon />}
                      sx={{
                        color: "#00B287",
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "none",
                          xl: "none",
                        },
                      }}
                      value="4"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {" "}
                  <Grid
                    sx={{ display: "block" }}
                    align="left"
                    container
                    spacing={2}
                  >
                    <Grid item>
                      <div>
                        {formikTraining.values.Togetherness_Moment_What__c && (
                          <div>
                            <NiceTextField
                              sx={{ display: "flex" }}
                              id="training-togetherness-what"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="What - Togetherness Moment"
                              name="Togetherness_Moment_What__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Togetherness_Moment_What__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Togetherness_Moment_Why__c && (
                          <div>
                            <NiceTextField
                              id="training-togetherness-why"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Why - Togetherness Moment"
                              name="Togetherness_Moment_Why__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Togetherness_Moment_Why__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Togetherness_Moment_How__c && (
                          <div>
                            <NiceTextField
                              id="training-togetherness-how"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="How - Togetherness Moment"
                              name="Togetherness_Moment_How__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Togetherness_Moment_How__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Togetherness_Moment_Intro__c && (
                          <div>
                            <NiceTextField
                              id="training-togetherness-intro"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Introduction - Togetherness Moment"
                              name="Togetherness_Moment_Intro__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Togetherness_Moment_Intro__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values
                          .Togetherness_Moment_Progress__c && (
                          <div>
                            <NiceTextField
                              id="training-togetherness-Progression"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Progression - Togetherness Moment"
                              name="Togetherness_Moment_Progress__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Togetherness_Moment_Progress__c || ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <Grid
                    sx={{ display: "block" }}
                    align="left"
                    container
                    spacing={2}
                  >
                    <Grid item>
                      <div>
                        {formikTraining.values.Technical_Moment_What__c && (
                          <div>
                            <NiceTextField
                              sx={{ display: "flex" }}
                              id="training-Technical-what"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="What - Technical Moment"
                              name="Technical_Moment_What__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Technical_Moment_What__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Technical_Moment_Why__c && (
                          <div>
                            <NiceTextField
                              id="training-Technical-why"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Why - Technical Moment"
                              name="Technical_Moment_Why__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Technical_Moment_Why__c ||
                                ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Technical_Moment_How__c && (
                          <div>
                            <NiceTextField
                              id="training-Technical-how"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="How - Technical Moment"
                              name="Technical_Moment_How__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Technical_Moment_How__c ||
                                ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Technical_Moment_Intro__c && (
                          <div>
                            <NiceTextField
                              id="training-Technical-intro"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Introduction - Technical Moment"
                              name="Technical_Moment_Intro__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Technical_Moment_Intro__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Technical_Moment_Progress__c && (
                          <div>
                            <NiceTextField
                              id="training-Technical-Progression"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Progression - Technical Moment"
                              name="Technical_Moment_Progress__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values
                                  .Technical_Moment_Progress__c || ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  {" "}
                  <Grid
                    sx={{ display: "block" }}
                    align="left"
                    container
                    spacing={2}
                  >
                    <Grid item>
                      <div>
                        {formikTraining.values.Game_Moment_What__c && (
                          <div>
                            <NiceTextField
                              sx={{ display: "flex" }}
                              id="training-Game-what"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="What - Game Moment"
                              name="Game_Moment_What__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Moment_What__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Moment_Why__c && (
                          <div>
                            <NiceTextField
                              id="training-Game-why"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Why - Game Moment"
                              name="Game_Moment_Why__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Moment_Why__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Moment_How__c && (
                          <div>
                            <NiceTextField
                              id="training-game-how"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="How - Game Moment"
                              name="Game_Moment_How__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Moment_How__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Moment_Intro__c && (
                          <div>
                            <NiceTextField
                              id="training-game-intro"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Introduction - Game Moment"
                              name="Game_Moment_Intro__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Moment_Intro__c || ""
                              }
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Moment_Progress__c && (
                          <div>
                            <NiceTextField
                              id="training-game-Progression"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Progression - Game Moment"
                              name="Game_Moment_Progress__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Moment_Progress__c ||
                                ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="4">
                  {" "}
                  <Grid
                    sx={{ display: "block" }}
                    align="left"
                    container
                    spacing={2}
                  >
                    <Grid item>
                      <div>
                        {formikTraining.values.Game_What__c && (
                          <div>
                            <NiceTextField
                              sx={{ display: "flex" }}
                              id="training-game2-what"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="What - Game"
                              name="Game_What__c"
                              variant="standard"
                              multiline
                              value={formikTraining.values.Game_What__c || ""}
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Why__c && (
                          <div>
                            <NiceTextField
                              id="training-game2-why"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Why - Game"
                              name="Game_Why__c"
                              variant="standard"
                              multiline
                              value={formikTraining.values.Game_Why__c || ""}
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_How__c && (
                          <div>
                            <NiceTextField
                              id="training-game2-how"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="How - Game"
                              name="Game_How__c"
                              variant="standard"
                              multiline
                              value={formikTraining.values.Game_How__c || ""}
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Intro__c && (
                          <div>
                            <NiceTextField
                              id="training-game2-intro"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Introduction - Game"
                              name="Game_Intro__c"
                              variant="standard"
                              multiline
                              value={formikTraining.values.Game_Intro__c || ""}
                            />
                          </div>
                        )}
                        {formikTraining.values.Game_Progress__c && (
                          <div>
                            <NiceTextField
                              id="training-game2-Progression"
                              InputProps={{
                                readOnly: Boolean(true),
                                disableUnderline: true,
                              }}
                              label="Progression - Game"
                              name="Game_Progress__c"
                              variant="standard"
                              multiline
                              value={
                                formikTraining.values.Game_Progress__c || ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </Drawer>
      </div>
      <Container maxWidth="lg">
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          CREATE TRAINING PROGRAM
        </Typography>
        <Typography sx={{ p: 1, m: 1, pb: 1 }} align="left" variant="body1">
          By {values.CoachName__c || "Name"} as {values.State__c || "Status"}
        </Typography>
        <form onSubmit={formikTraining.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <NiceTextField
                  id="program-name"
                  label="Title"
                  name="Name"
                  value={formikTraining.values.Name || ""}
                  onChange={formikTraining.handleChange}
                  error={
                    formikTraining.touched.Name &&
                    Boolean(formikTraining.errors.Name)
                  }
                  helperText={
                    formikTraining.touched.Name && formikTraining.errors.Name
                  }
                />
                <NiceSelectFormControl
                  fullWidth
                >
                  <InputLabel id="activity-theme-select-label">
                    Theme
                  </InputLabel>
                  <Select
                    labelId="activity-theme-select-label"
                    id="activity-theme"
                    label="Theme"
                    name="Theme__c"
                    value={formikTraining.values.Theme__c || ""}
                    onChange={formikTraining.handleChange}
                    error={
                      formikTraining.touched.Theme__c &&
                      Boolean(formikTraining.errors.Theme__c)
                    }
                    helperText={
                      formikTraining.touched.Theme__c &&
                      formikTraining.errors.Theme__c
                    }
                  >
                    <ListSubheader>Attacking - ”Creating”</ListSubheader>
                    {themesAttack.map((row) => (
                      <MenuItem value={row.Id}>{row.Name}</MenuItem>
                    ))}
                    <ListSubheader>Defending – ”Resolving”</ListSubheader>
                    {themesDefend.map((row) => (
                      <MenuItem value={row.Id}>{row.Name}</MenuItem>
                    ))}
                  </Select>
                </NiceSelectFormControl>
                <NiceTextField
                  id="training-Reflections__c"
                  label="Reflection"
                  name="Reflections__c"
                  placeholder="Write here - max xxx words"
                  multiline
                  rows={5}
                  value={formikTraining.values.Reflections__c || ""}
                  onChange={formikTraining.handleChange}
                  error={
                    formikTraining.touched.Reflections__c &&
                    Boolean(formikTraining.errors.Reflections__c)
                  }
                  helperText={
                    formikTraining.touched.Reflections__c &&
                    formikTraining.errors.Reflections__c
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="1 Togetherness moment"
                    sx={{
                      color: "#FF3200",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="1"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FF3200",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="1"
                  />
                  <Tab
                    label="2 Technical moment"
                    sx={{
                      color: "#FF33F3",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="2"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FF33F3",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="2"
                  />
                  <Tab
                    label="3 Game moment"
                    sx={{
                      color: "#FAC200",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="3"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FAC200",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="3"
                  />
                  <Tab
                    label="4 Game"
                    sx={{
                      color: "#00B287",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="4"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#00B287",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="4"
                  />
                </TabList>
              </Box>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Typography
                  sx={{ p: 1, m: 1, pb: 0 }}
                  align="left"
                  variant="body2"
                >
                  All fields are mandatory to give you the best training tool
                  and get familiar with how the elements are connected and has
                  purpose. It will also help you get into a good habit of
                  documenting your training. Remember you can always save your
                  training and continue your work later. When the training
                  program is ready, just click the 'save & publish’ button.
                </Typography>
              </Box>

              <TabPanel value="1">
                {" "}
                <Grid align="left" container spacing={2}>
                  <Grid item>
                    <div>
                      <NiceTextField
                        id="training-togetherness-what"
                        label="What - Togetherness Moment"
                        name="Togetherness_Moment_What__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Togetherness_Moment_What__c ||
                          ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Togetherness_Moment_What__c &&
                          Boolean(
                            formikTraining.errors.Togetherness_Moment_What__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Togetherness_Moment_What__c &&
                          formikTraining.errors.Togetherness_Moment_What__c
                        }
                      />
                      <NiceTextField
                        id="training-togetherness-why"
                        label="Why - Togetherness Moment"
                        name="Togetherness_Moment_Why__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Togetherness_Moment_Why__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Togetherness_Moment_Why__c &&
                          Boolean(
                            formikTraining.errors.Togetherness_Moment_Why__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Togetherness_Moment_Why__c &&
                          formikTraining.errors.Togetherness_Moment_Why__c
                        }
                      />
                      <NiceTextField
                        id="training-togetherness-how"
                        label="How - Togetherness Moment"
                        name="Togetherness_Moment_How__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Togetherness_Moment_How__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Togetherness_Moment_How__c &&
                          Boolean(
                            formikTraining.errors.Togetherness_Moment_How__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Togetherness_Moment_How__c &&
                          formikTraining.errors.Togetherness_Moment_How__c
                        }
                      />
                      <NiceTextField
                        id="training-togetherness-intro"
                        label="Introduction - Togetherness Moment"
                        name="Togetherness_Moment_Intro__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Togetherness_Moment_Intro__c ||
                          ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Togetherness_Moment_Intro__c &&
                          Boolean(
                            formikTraining.errors.Togetherness_Moment_Intro__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Togetherness_Moment_Intro__c &&
                          formikTraining.errors.Togetherness_Moment_Intro__c
                        }
                      />
                      <NiceTextField
                        id="training-togetherness-progres"
                        label="Progression - Togetherness Moment"
                        name="Togetherness_Moment_Progress__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values
                            .Togetherness_Moment_Progress__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched
                            .Togetherness_Moment_Progress__c &&
                          Boolean(
                            formikTraining.errors
                              .Togetherness_Moment_Progress__c
                          )
                        }
                        helperText={
                          formikTraining.touched
                            .Togetherness_Moment_Progress__c &&
                          formikTraining.errors.Togetherness_Moment_Progress__c
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                {" "}
                <Grid align="left" container spacing={2}>
                  <Grid item>
                    <div>
                      <NiceTextField
                        id="training-Technical-what"
                        label="What - Technical Moment"
                        name="Technical_Moment_What__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Technical_Moment_What__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Technical_Moment_What__c &&
                          Boolean(
                            formikTraining.errors.Technical_Moment_What__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Technical_Moment_What__c &&
                          formikTraining.errors.Technical_Moment_What__c
                        }
                      />
                      <NiceTextField
                        id="training-Technical-why"
                        label="Why - Technical Moment"
                        name="Technical_Moment_Why__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Technical_Moment_Why__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Technical_Moment_Why__c &&
                          Boolean(formikTraining.errors.Technical_Moment_Why__c)
                        }
                        helperText={
                          formikTraining.touched.Technical_Moment_Why__c &&
                          formikTraining.errors.Technical_Moment_Why__c
                        }
                      />
                      <NiceTextField
                        id="training-tech-how"
                        label="How - Technical Moment"
                        name="Technical_Moment_How__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Technical_Moment_How__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Technical_Moment_How__c &&
                          Boolean(formikTraining.errors.Technical_Moment_How__c)
                        }
                        helperText={
                          formikTraining.touched.Technical_Moment_How__c &&
                          formikTraining.errors.Technical_Moment_How__c
                        }
                      />
                      <NiceTextField
                        id="training-tech-intro"
                        label="Introduction - Technical Moment"
                        name="Technical_Moment_Intro__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Technical_Moment_Intro__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Technical_Moment_Intro__c &&
                          Boolean(
                            formikTraining.errors.Technical_Moment_Intro__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Technical_Moment_Intro__c &&
                          formikTraining.errors.Technical_Moment_Intro__c
                        }
                      />
                      <NiceTextField
                        id="training-tech-progres"
                        label="Progression - Technical Moment"
                        name="Technical_Moment_Progress__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Technical_Moment_Progress__c ||
                          ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Technical_Moment_Progress__c &&
                          Boolean(
                            formikTraining.errors.Technical_Moment_Progress__c
                          )
                        }
                        helperText={
                          formikTraining.touched.Technical_Moment_Progress__c &&
                          formikTraining.errors.Technical_Moment_Progress__c
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                {" "}
                <Grid align="left" container spacing={2}>
                  <Grid item>
                    <div>
                      <NiceTextField
                        id="training-Game_Moment-what"
                        label="What - Game Moment"
                        name="Game_Moment_What__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Moment_What__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Moment_What__c &&
                          Boolean(formikTraining.errors.Game_Moment_What__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Moment_What__c &&
                          formikTraining.errors.Game_Moment_What__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Moment_Why__c"
                        label="Why - Game Moment"
                        name="Game_Moment_Why__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Moment_Why__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Moment_Why__c &&
                          Boolean(formikTraining.errors.Game_Moment_Why__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Moment_Why__c &&
                          formikTraining.errors.Game_Moment_Why__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Moment_How__c"
                        label="How - Game Moment"
                        name="Game_Moment_How__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Moment_How__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Moment_How__c &&
                          Boolean(formikTraining.errors.Game_Moment_How__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Moment_How__c &&
                          formikTraining.errors.Game_Moment_How__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Moment_Intro__c"
                        label="Introduction - Game Moment"
                        name="Game_Moment_Intro__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Moment_Intro__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Moment_Intro__c &&
                          Boolean(formikTraining.errors.Game_Moment_Intro__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Moment_Intro__c &&
                          formikTraining.errors.Game_Moment_Intro__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Moment_Progress__c"
                        label="Progression - Game Moment"
                        name="Game_Moment_Progress__c"
                        multiline
                        rows={5}
                        value={
                          formikTraining.values.Game_Moment_Progress__c || ""
                        }
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Moment_Progress__c &&
                          Boolean(formikTraining.errors.Game_Moment_Progress__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Moment_Progress__c &&
                          formikTraining.errors.Game_Moment_Progress__c
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="4">
                {" "}
                <Grid align="left" container spacing={2}>
                  <Grid item>
                    <div>
                      <NiceTextField
                        id="training-Game_What__c"
                        label="What - Game"
                        name="Game_What__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_What__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_What__c &&
                          Boolean(formikTraining.errors.Game_What__c)
                        }
                        helperText={
                          formikTraining.touched.Game_What__c &&
                          formikTraining.errors.Game_What__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Why__c"
                        label="Why - Game"
                        name="Game_Why__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Why__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Why__c &&
                          Boolean(formikTraining.errors.Game_Why__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Why__c &&
                          formikTraining.errors.Game_Why__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_How__c"
                        label="How - Game"
                        name="Game_How__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_How__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_How__c &&
                          Boolean(formikTraining.errors.Game_How__c)
                        }
                        helperText={
                          formikTraining.touched.Game_How__c &&
                          formikTraining.errors.Game_How__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Intro__c"
                        label="Introduction - Game"
                        name="Game_Intro__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Intro__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Intro__c &&
                          Boolean(formikTraining.errors.Game_Intro__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Intro__c &&
                          formikTraining.errors.Game_Intro__c
                        }
                      />
                      <NiceTextField
                        id="training-Game_Progress__c"
                        label="Progression - Game"
                        name="Game_Progress__c"
                        multiline
                        rows={5}
                        value={formikTraining.values.Game_Progress__c || ""}
                        onChange={formikTraining.handleChange}
                        error={
                          formikTraining.touched.Game_Progress__c &&
                          Boolean(formikTraining.errors.Game_Progress__c)
                        }
                        helperText={
                          formikTraining.touched.Game_Progress__c &&
                          formikTraining.errors.Game_Progress__c
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
          <Box>
            <Button
              disabled={!formikTraining.dirty || formikTraining.isSubmitting}
              variant="contained"
              type="submit"
              sx={{ mb: 2 }}
            >
              Save for later
            </Button>
            <Button
              sx={{ mb: 2, ml: 2 }}
              variant="contained"
              onClick={() => confirmPublishFirst()}
              disabled={!formikTraining.dirty || formikTraining.isSubmitting}
            >
              Save & Publish
            </Button>
            <Button
              sx={{ mb: 2, ml: 2 }}
              variant="outlined"
              color="error"
              onClick={() => confirmDeleteFirst()}
              disabled={formikTraining.isSubmitting}
            >
              Delete Program
            </Button>
            <Button
              sx={{ mb: 2, ml: 2 }}
              component={Link}
              to={"/training-programs"}
              size="small"
            >
              Return
            </Button>
          </Box>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Training;
