import { createSlice } from "@reduxjs/toolkit";

export const programSlice = createSlice({
  name: "program",
  initialState: {
    programInStore: false,
    Name: "EIR",
    App_Show_Library__c: true,
    EIR_program_colour__c: "FFF",
    Program_Logo__c: "EIR",
    App_Show_Training_Program__c: false,
    App_Show_Calendar__c: false,
    App_Show_Scorecards__c: false,
    Program_hashtags__c: "#closetheplaygap #ggwcup",
  },
  reducers: {
    setProgramInStore: (state, data) => {
      state.programInStore = true;
      state.Name = data.payload.Name;
      state.App_Show_Library__c = data.payload.App_Show_Library__c;
      state.EIR_program_colour__c = data.payload.EIR_program_colour__c;
      state.Program_Logo__c = data.payload.Program_Logo__c;
      state.App_Show_Training_Program__c =
        data.payload.App_Show_Training_Program__c;
      state.App_Show_Calendar__c = data.payload.App_Show_Calendar__c;
      state.App_Show_Scorecards__c = data.payload.App_Show_Scorecards__c;
      state.Program_hashtags__c = data.payload.Program_hashtags__c;
    },
    clearProgramInStore: (state) => {
      state.programInStore = false;
      state.Name = "EIR";
      state.App_Show_Library__c = false;
      state.EIR_program_colour__c = "FFF";
      state.Program_Logo__c = "EIR";
      state.App_Show_Training_Program__c = false;
      state.App_Show_Calendar__c = false;
      state.App_Show_Scorecards__c = false;
      state.Program_hashtags__c = "#closetheplaygap #ggwcup";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProgramInStore, clearProgramInStore } = programSlice.actions;

export default programSlice.reducer;
