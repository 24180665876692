import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useSelector } from "react-redux";

const ProgramBanner = () => {
  const eirProgram = useSelector((state) => state.program);
  //console.log("userProgram= ",userProgram);
  return (
    <Box
      sx={{
        height: 50,
        backgroundColor: "#" + eirProgram.EIR_program_colour__c,
        flexGrow: 1,
      }}
    >
      <Typography sx={{ color: "#FFF", pt: 1, pb: 0 }} variant="h5">
        {eirProgram.Name}
      </Typography>
    </Box>
  );
};

export default ProgramBanner;
