import { createSlice } from "@reduxjs/toolkit";

export const membersSlice = createSlice({
  name: "members",
  initialState: [],
  reducers: {
    setMembersInStore: (state, data) => {
        state.payload = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMembersInStore } = membersSlice.actions;

export default membersSlice.reducer;
