import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "react-avatar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;

const Input = styled("input")({
  display: "none",
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  width: "95%",
  margin: "10px",
});

function Profile() {
  let navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    GetUser();
  }, []);

  const showJSON = () => {
    SaveContact();
  };

  const sftoken = sessionStorage.getItem("sftoken");
  const sfdemouser = sessionStorage.getItem("demoUser");
  const sfuserid = sessionStorage.getItem("userId");

  function updateAvatar() {
    navigate("/avatar?id=" + values.Id + "&src=" + values.Avatar__c);
  }

  const GetUser = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        sfuserid,
      requestOptions
    );
    const data = await response.json();
    try {
      setValues(data);
    } catch (err) {
      console.log(err);
    }
  };

  function SaveContact() {
    if (sfdemouser === "true") {
      toast("Data is not saved because this is a demo session!");
      return;
    }

    var theJSON = {
      LastName: values.LastName,
      FirstName: values.FirstName,
      Bio__c: values.Bio__c,
      Play_Philosophy__c: values.Play_Philosophy__c,
      Email: values.Email,
      MobilePhone: values.MobilePhone,
      Global_Goal__c: values.Global_Goal__c,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        sfuserid,
      requestOptions
    )
      .then((response) => response.text())
      .then((response) => setSaveButtonDisabled(true))
      .then((result) => toast("Data saved"))
      .catch((error) => console.log("error", error));
  }

  const handleInputChange = (e) => {
    //console.log("e.target=", e.target);
    //console.log("e.target.checked=", e.target.checked);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setSaveButtonDisabled(false);
  };

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <div>
                  <Box sx={{ p: 1, pt: 3 }}>
                    <Avatar
                      name={values.Name || "XX XX"}
                      size="150"
                      src={values.Avatar__c}
                      color={Avatar.getRandomColor("sitebase", ["red", "blue"])}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <Button
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      startIcon={<PhotoCamera />}
                      onClick={updateAvatar}
                    >
                      Update Avatar
                    </Button>
                  </Box>
                </div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  PERSONAL DETAILS
                </Typography>
                <NiceTextField
                  disabled
                  id="profile-role"
                  label="Role"
                  value={values.UserProfile__c || ""}
                />
                <NiceTextField
                  required
                  id="profile-firstname"
                  label="First Name"
                  value={values.FirstName || ""}
                  name="FirstName"
                  onChange={handleInputChange}
                />
                <NiceTextField
                  required
                  id="profile-lastname"
                  label="Last Name"
                  value={values.LastName || ""}
                  name="LastName"
                  onChange={handleInputChange}
                />
                <NiceTextField
                  disabled
                  id="profile-email"
                  label="Email"
                  value={values.Email || ""}
                  name="Email"
                  onChange={handleInputChange}
                />
                <NiceTextField
                  id="profile-phone"
                  label="Mobile Phone"
                  value={values.MobilePhone || ""}
                  name="MobilePhone"
                  onChange={handleInputChange}
                />
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Global Goal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="profile-globalgoal"
                    value={values.Global_Goal__c || ""}
                    label="Global Goal"
                    name="Global_Goal__c"
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"a0u090000010VD5AAM"}>
                      Global Goal 1 - No Poverty
                    </MenuItem>
                    <MenuItem value={"a0u090000010VDAAA2"}>
                      Global Goal 2 - Zero Hunger
                    </MenuItem>
                    <MenuItem value={"a0u090000010VyvAAE"}>
                      Global Goal 3 - Good Health and Well-being
                    </MenuItem>
                    <MenuItem value={"a0u090000010VyhAAE"}>
                      Global Goal 4 - Quality Education
                    </MenuItem>
                    <MenuItem value={"a0u090000010Vz1AAE"}>
                      Global Goal 5 - Gender Equality
                    </MenuItem>
                    <MenuItem value={"a0u090000010VyiAAE"}>
                      Global Goal 6 - Clean Water and Sanitation
                    </MenuItem>
                    <MenuItem value={"a0u090000010Vz5AAE"}>
                      Global Goal 7 - Affordable and Clean Energy
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzAAAU"}>
                      Global Goal 8 - Decent Work and Economic Growth
                    </MenuItem>
                    <MenuItem value={"a0u090000010Vz6AAE"}>
                      Global Goal 9 - Industry, Innovation and Infrastructure
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzKAAU"}>
                      Global Goal 10 - Reduced Inequalities
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzLAAU"}>
                      Global Goal 11 - Sustainable Cities and Communities
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzBAAU"}>
                      Global Goal 12 - Responsible Consumption and Production
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzPAAU"}>
                      Global Goal 13 - Climate Action
                    </MenuItem>
                    <MenuItem value={"a0u090000010VymAAE"}>
                      Global Goal 14 - Life Below Water
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzUAAU"}>
                      Global Goal 15 - Life On Land
                    </MenuItem>
                    <MenuItem value={"a0u090000010VzZAAU"}>
                      Global Goal 16 - Peace, Justice and Strong Institutions
                    </MenuItem>
                    <MenuItem value={"a0u090000010VywAAE"}>
                      Global Goal 17 - Partnership For The Goals
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                <NiceCheckboxFormControl
                  control={
                    <Checkbox
                      id="profile-showme"
                      disabled
                      checked={true}
                      name="Show_details_on_GGWCUP__c"
                    />
                  }
                  label="Yes, my details can be shown on www.ggwcup.com"
                />
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  OTHER DETAILS
                </Typography>
                <NiceTextField
                  id="profile-bio"
                  label="Bio"
                  value={values.Bio__c || ""}
                  name="Bio__c"
                  placeholder="Write your bio here - max 150 words"
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                />
                <NiceTextField
                  id="profile-philosophy"
                  label="Play Philosophy"
                  placeholder="Write your play philosophy here - max 150 words"
                  value={values.Play_Philosophy__c || ""}
                  name="Play_Philosophy__c"
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button
                  variant="contained"
                  disabled={saveButtonDisabled}
                  onClick={() => showJSON()}
                >
                  Save Changes
                </Button>
                <Button sx={{ pl: 4 }} component={Link} to={"/"} size="small">
                  Return to Dashboard
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Profile;
