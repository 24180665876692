import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { formatRFC3339 } from "date-fns";
import { useFormik } from "formik";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as yup from "yup";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  margin: "10px",
});

const validationSchemaEvent = yup.object({
  Name: yup.string("Enter a meaningful title").required("Title is required"),
  Message__c: yup
    .string("Enter a meaningful message")
    .required("Message is required"),
  Date_and_Time__c: yup.date().nullable(),
});

function Event() {
  let navigate = useNavigate();

  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState([]);
  const [tabvalue, setTabValue] = useState("1");
  const [headcoaches, setHeadcoaches] = useState([]);

  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      setCheckedInPerson(true);
    } else {
      setCheckedInPerson(false);
    }
    setTabValue(newValue);
  };

  const [checkedInPerson, setCheckedInPerson] = useState(true);
  const [checkedZoomTeam, setCheckedZoomTeam] = useState(false);
  const [checkedZoomHC, setCheckedZoomHC] = useState(false);

  const theUserID = sessionStorage.getItem("userId");
  const theUserName = sessionStorage.getItem("userName");
  const theUserEmail = sessionStorage.getItem("userEmail");
  const theTeamID = sessionStorage.getItem("userTeam");
  const theUserType = sessionStorage.getItem("userType");
  const theUserProgram = sessionStorage.getItem("userProgram");
  const theTeamList = JSON.parse(sessionStorage.getItem("userTeamList"));

  const handleChangeInPerson = (event) => {
    setCheckedInPerson(event.target.checked);
  };
  const handleChangeZoomTeam = (event) => {
    setCheckedZoomTeam(event.target.checked);
  };
  const handleChangeZoomHC = (event) => {
    setCheckedZoomHC(event.target.checked);
  };

  useEffect(() => {
    FetcPrograms();
    FetcProgram();
    FetchHCs();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const sfdemouser = sessionStorage.getItem("demoUser");

  const FetcPrograms = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Coach__r.Name";
    sqol = sqol + " from Training_Program__c";
    sqol = sqol + " where (Team__c = '" + theTeamID + "' and State__c = 'Published')";
    sqol = sqol + " or Type__c = 'Template'";
    sqol = sqol + " ORDER BY Name DESC";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );

    try {
      const data = await response.json();
      // console.log("FetcPrograms= ", data);
      if (!response.ok) {
        console.log("Bad response = ", response.json());
        setPrograms([]);
      } else {
        console.log("Good response");
        setPrograms(data.records);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetcProgram = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Zoom_Link__c";
    sqol = sqol + ", Zoom_Meeting_ID__c";
    sqol = sqol + ", Zoom_Passcode__c";
    sqol = sqol + " from Account";
    sqol = sqol + " where Id = '" + theTeamID + "'";
    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );

    try {
      const rawdata = await response.json();
      const data = rawdata.records[0];
      //console.log("Program Data= ", data);
      if (!response.ok) {
        console.log("Bad response = ", response.json());
        setProgram([]);
      } else {
        console.log("Good response");
        setProgram(data);
        formikEvent.setFieldValue("Zoom_Link__c", data.Zoom_Link__c);
        formikEvent.setFieldValue(
          "Zoom_Meeting_ID__c",
          data.Zoom_Meeting_ID__c
        );
        formikEvent.setFieldValue("Zoom_Passcode__c", data.Zoom_Passcode__c);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function SendInvitations(theEvent) {
    //console.log("Event: ", theEvent.id);

    if (sfdemouser === "true") {
      toast("Invitations are not sent because this is a demo session!");
      return;
    }

    var sentInvitations = [];

    CreateInvitations(
      theUserEmail,
      theUserName,
      theUserID,
      theEvent.id,
      theEvent.Type__c,
      "Yes"
    );
    sentInvitations.push(theUserEmail);

    if (checkedInPerson) {
      theTeamList.forEach((member) => {
        if (!sentInvitations.includes(member.Email)) {
          CreateInvitations(
            member.Email,
            member.Name,
            member.Id,
            theEvent.id,
            theEvent.Type__c,
            "Awaiting"
          );
          sentInvitations.push(member.Email);
        }
      });
    }
    if (checkedZoomTeam) {
      theTeamList.forEach((member) => {
        if (!sentInvitations.includes(member.Email)) {
          CreateInvitations(
            member.Email,
            member.Name,
            member.Id,
            theEvent.id,
            theEvent.Type__c,
            "Awaiting"
          );
          sentInvitations.push(member.Email);
        }
      });
    }
    if (checkedZoomHC) {
      headcoaches.forEach((member) => {
        if (!sentInvitations.includes(member.Email)) {
          CreateInvitations(
            member.Email,
            member.Name,
            member.Id,
            theEvent.id,
            theEvent.Type__c,
            "Awaiting"
          );
          sentInvitations.push(member.Email);
        }
      });
    }
    navigate("/calendar");
  }

  function CreateInvitations(
    theEmail,
    theName,
    theId,
    theEventId,
    theEventType,
    theRSVP
  ) {
    var theJSON = {
      Email__c: theEmail,
      Name__c: theName,
      Inviter__c: theUserID,
      Invitee__c: theId,
      Training_Event__c: theEventId,
      Source__c: "App Event",
      Type__c: "Training Invitation",
      RSVP__c: theRSVP,
    };

    //console.log("theInvitation= ", theJSON);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Invitation__c/",
      requestOptions
    )
      .then((response) => response.text())
      .catch((error) => console.log("error", error));
  }

  const SaveEvent = async (theValues) => {
    //console.log("Event data=", theValues);
    if (sfdemouser === "true") {
      toast("Event is not saved because this is a demo session!");
      return;
    }

    if (
      theValues.Training_Program__c === "" &&
      theValues.Global_Goal__c === "" &&
      tabvalue === "1"
    ) {
      toast("Event should have at least a program attached or a Global Goal");
      return;
    }

    if (theValues.Zoom_Link__c === "" && tabvalue === "2") {
      toast("An online event should have at least a Zoom link");
      return;
    }

    if (!checkedZoomTeam && !checkedZoomHC && tabvalue === "2") {
      toast("Event should have at least one group invited");
      return;
    }

    var theJSON = {
      Date_and_Time__c: formatRFC3339(theValues.Date_and_Time__c, {
        fractionDigits: 0,
      }),
      Minutes__c: theValues.Minutes__c,
      Name: theValues.Name,
      Message__c: theValues.Message__c,
      Group__c: theValues.Group__c,
      Global_Goal__c: theValues.Global_Goal__c,
      Inviter__c: theValues.Inviter__c,
      Location__c: theValues.Location__c,
      Zoom_Link__c: theValues.Zoom_Link__c,
      Zoom_Meeting_ID__c: theValues.Zoom_Meeting_ID__c,
      Zoom_Passcode__c: theValues.Zoom_Passcode__c,
      State__c: "Execute",
      Training_Program__c: theValues.Training_Program__c,
    };

    if (theJSON.Training_Program__c !== "" && theJSON.Global_Goal__c !== "") {
      theJSON.Type__c = "Training/Activation";
    }

    if (theJSON.Training_Program__c === "" && theJSON.Global_Goal__c !== "") {
      theJSON.Type__c = "Activation";
    }

    if (theJSON.Training_Program__c !== "" && theJSON.Global_Goal__c === "") {
      theJSON.Type__c = "Training";
    }

    if (tabvalue === "2") {
      theJSON.Type__c = "Zoom";
    }

    //console.log("theJSON= ", theJSON);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_event__c/",
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      toast("Event created");
      SendInvitations(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchHCs = async () => {
    var sqol = "select id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Avatar__c";
    sqol = sqol + ", Email";
    sqol = sqol + ", npe01__WorkPhone__c";
    sqol = sqol + ", UserProfile__c";
    sqol = sqol + ", Country_Label__c";
    sqol = sqol + ", City_Label__c";
    sqol = sqol + ", npsp__Primary_Affiliation__c";
    sqol = sqol + ", Affiliated_Team__c";
    sqol = sqol + " from Contact";
    sqol =
      sqol +
      " where Contact.npsp__Primary_Affiliation__r.Program_context__r.id = '" +
      theUserProgram +
      "'";
    sqol = sqol + " and UserProfile__c = 'Head Coach'";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    try {
      const data = await response.json();
      //console.log("HCs= ", data.records);
      if (!response.ok) {
        console.log("Bad response = ", response.json());
        setHeadcoaches([]);
      } else {
        console.log("Good response=",data.records);
        setHeadcoaches(data.records);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const today = new Date();

  const formikEvent = useFormik({
    initialValues: {
      Name: "",
      Date_and_Time__c: today,
      Minutes__c: "60",
      Message__c: "",
      Location__c: "",
      Global_Goal__c: "",
      Training_Program__c: "",
      Group__c: theTeamID,
      Inviter__c: theUserID,
      State__c: "Draft",
      Type__c: "",
      Zoom_Link__c: "",
      Zoom_Meeting_ID__c: "",
      Zoom_Passcode__c: "",
    },
    validationSchema: validationSchemaEvent,
    onSubmit: (values) => {
      SaveEvent(values);
    },
  });

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form onSubmit={formikEvent.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  CREATE EVENT
                </Typography>
                <NiceTextField
                  id="event-title"
                  label="Subject"
                  name="Name"
                  placeholder="Add title"
                  value={formikEvent.values.Name || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.Name && Boolean(formikEvent.errors.Name)
                  }
                  helpertext={
                    formikEvent.touched.Name && formikEvent.errors.Name
                  }
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <NiceTextField {...props} />}
                    label="Date & Time"
                    minDate={today}
                    ampm={false}
                    value={formikEvent.values.Date_and_Time__c}
                    onChange={(value) => {
                      formikEvent.setFieldValue("Date_and_Time__c", value);
                    }}
                  />
                </LocalizationProvider>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="event-simple-select-label">
                    Duration (in minutes)
                  </InputLabel>
                  <Select
                    labelId="event-simple-select-label"
                    id="event-minutes"
                    label="Duration (in minutes)"
                    name="Minutes__c"
                    value={formikEvent.values.Minutes__c || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.Minutes__c &&
                      Boolean(formikEvent.errors.Minutes__c)
                    }
                    helpertext={
                      formikEvent.touched.Minutes__c &&
                      formikEvent.errors.Minutes__c
                    }
                  >
                    <MenuItem key="30" value="30">
                      30 minutes
                    </MenuItem>
                    <MenuItem key="60" value="60">
                      1 hour
                    </MenuItem>
                    <MenuItem key="90" value="90">
                      1 hour and 30 minutes
                    </MenuItem>
                    <MenuItem key="120" value="120">
                      2 hours
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>

                <NiceSelectFormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Global Goal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="training-globalgoal"
                    label="Global Goal"
                    name="Global_Goal__c"
                    value={formikEvent.values.Global_Goal__c || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.Global_Goal__c &&
                      Boolean(formikEvent.errors.Global_Goal__c)
                    }
                    helpertext={
                      formikEvent.touched.Global_Goal__c &&
                      formikEvent.errors.Global_Goal__c
                    }
                  >
                    <MenuItem key="1" value={"a0u090000010VD5AAM"}>
                      Global Goal 1 - No Poverty
                    </MenuItem>
                    <MenuItem key="2" value={"a0u090000010VDAAA2"}>
                      Global Goal 2 - Zero Hunger
                    </MenuItem>
                    <MenuItem key="3" value={"a0u090000010VyvAAE"}>
                      Global Goal 3 - Good Health and Well-being
                    </MenuItem>
                    <MenuItem key="4" value={"a0u090000010VyhAAE"}>
                      Global Goal 4 - Quality Education
                    </MenuItem>
                    <MenuItem key="5" value={"a0u090000010Vz1AAE"}>
                      Global Goal 5 - Gender Equality
                    </MenuItem>
                    <MenuItem key="6" value={"a0u090000010VyiAAE"}>
                      Global Goal 6 - Clean Water and Sanitation
                    </MenuItem>
                    <MenuItem key="7" value={"a0u090000010Vz5AAE"}>
                      Global Goal 7 - Affordable and Clean Energy
                    </MenuItem>
                    <MenuItem key="8" value={"a0u090000010VzAAAU"}>
                      Global Goal 8 - Decent Work and Economic Growth
                    </MenuItem>
                    <MenuItem key="9" value={"a0u090000010Vz6AAE"}>
                      Global Goal 9 - Industry, Innovation and Infrastructure
                    </MenuItem>
                    <MenuItem key="10" value={"a0u090000010VzKAAU"}>
                      Global Goal 10 - Reduced Inequalities
                    </MenuItem>
                    <MenuItem key="11" value={"a0u090000010VzLAAU"}>
                      Global Goal 11 - Sustainable Cities and Communities
                    </MenuItem>
                    <MenuItem key="12" value={"a0u090000010VzBAAU"}>
                      Global Goal 12 - Responsible Consumption and Production
                    </MenuItem>
                    <MenuItem key="13" value={"a0u090000010VzPAAU"}>
                      Global Goal 13 - Climate Action
                    </MenuItem>
                    <MenuItem key="14" value={"a0u090000010VymAAE"}>
                      Global Goal 14 - Life Below Water
                    </MenuItem>
                    <MenuItem key="15" value={"a0u090000010VzUAAU"}>
                      Global Goal 15 - Life On Land
                    </MenuItem>
                    <MenuItem key="16" value={"a0u090000010VzZAAU"}>
                      Global Goal 16 - Peace, Justice and Strong Institutions
                    </MenuItem>
                    <MenuItem key="17" value={"a0u090000010VywAAE"}>
                      Global Goal 17 - Partnership For The Goals
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                <NiceTextField
                  id="event-message"
                  label="Message"
                  name="Message__c"
                  placeholder="Tell your team what to expect at this event"
                  multiline
                  rows={4}
                  value={formikEvent.values.Message__c || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.Message__c &&
                    Boolean(formikEvent.errors.Message__c)
                  }
                  helpertext={
                    formikEvent.touched.Message__c &&
                    formikEvent.errors.Message__c
                  }
                />
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabvalue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="In Person" value="1" />
                        <Tab label="Online" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Typography
                        sx={{ p: 1 }}
                        gutterBottom
                        variant="body1"
                        component="div"
                      >
                        Organize an in person meeting
                      </Typography>
                      <NiceSelectFormControl fullWidth>
                        <InputLabel id="event-simple-select-label2">
                          Training Program
                        </InputLabel>
                        <Select
                          labelId="event-simple-select-label2"
                          id="event-program"
                          label="Training Program"
                          name="Training_Program__c"
                          value={formikEvent.values.Training_Program__c || ""}
                          onChange={formikEvent.handleChange}
                          error={
                            formikEvent.touched.Training_Program__c &&
                            Boolean(formikEvent.errors.Training_Program__c)
                          }
                          helpertext={
                            formikEvent.touched.Training_Program__c &&
                            formikEvent.errors.Training_Program__c
                          }
                        >
                          {programs.map((row) => (
                            <MenuItem key="xxxx" value={row.Id}>
                              {row.Name + " by " + row.Coach__r.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </NiceSelectFormControl>

                      <NiceTextField
                        id="event-location"
                        label="Location"
                        name="Location__c"
                        placeholder="Tell your team where to meet up"
                        multiline
                        rows={4}
                        value={formikEvent.values.Location__c || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.Location__c &&
                          Boolean(formikEvent.errors.Location__c)
                        }
                        helpertext={
                          formikEvent.touched.Location__c &&
                          formikEvent.errors.Location__c
                        }
                      />
                      <NiceCheckboxFormControl
                        control={
                          <Checkbox
                            id="event-invite-team"
                            disabled
                            checked={checkedInPerson}
                            onChange={handleChangeInPerson}
                            name="Invite_team"
                          />
                        }
                        label="Invite your team"
                      />
                    </TabPanel>
                    <TabPanel value="2">
                      <Typography
                        sx={{ p: 1 }}
                        gutterBottom
                        variant="body1"
                        component="div"
                      >
                        Organize a Zoom online meeting
                      </Typography>
                      <NiceTextField
                        id="event-zoom-url"
                        label="URL"
                        name="Zoom_Link__c"
                        value={formikEvent.values.Zoom_Link__c || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.Zoom_Link__c &&
                          Boolean(formikEvent.errors.Zoom_Link__c)
                        }
                        helpertext={
                          formikEvent.touched.Zoom_Link__c &&
                          formikEvent.errors.Zoom_Link__c
                        }
                      />

                      <NiceTextField
                        id="event-zoom-id"
                        label="Meeting ID"
                        name="Zoom_Meeting_ID__c"
                        value={formikEvent.values.Zoom_Meeting_ID__c || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.Zoom_Meeting_ID__c &&
                          Boolean(formikEvent.errors.Zoom_Meeting_ID__c)
                        }
                        helpertext={
                          formikEvent.touched.Zoom_Meeting_ID__c &&
                          formikEvent.errors.Zoom_Meeting_ID__c
                        }
                      />
                      <NiceTextField
                        id="event-zoom-passcode"
                        label="Passcode"
                        name="Zoom_Passcode__c"
                        value={formikEvent.values.Zoom_Passcode__c || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.Zoom_Passcode__c &&
                          Boolean(formikEvent.errors.Zoom_Passcode__c)
                        }
                        helpertext={
                          formikEvent.touched.Zoom_Passcode__c &&
                          formikEvent.errors.Zoom_Passcode__c
                        }
                      />
                      <NiceCheckboxFormControl
                        control={
                          <Checkbox
                            id="event-invite-zoom-team"
                            checked={checkedZoomTeam}
                            onChange={handleChangeZoomTeam}
                            name="Invite_zoom-team"
                          />
                        }
                        label="Invite your whole team"
                      />

                      <NiceCheckboxFormControl
                        control={
                          <Checkbox
                            id="event-invite-zoom-hcs"
                            disabled={theUserType !== "EIR"}
                            checked={checkedZoomHC}
                            onChange={handleChangeZoomHC}
                            name="Invite_zoom-hcs"
                          />
                        }
                        label="Invite all Head Coaches"
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button variant="contained" type="submit">
                  Create Event
                </Button>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/calendar"}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Event;
