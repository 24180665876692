import React, { useState, useEffect } from "react";
import { GGWCUPLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const Teams = () => {
  const [team, setTeam] = useState([]);
  const [searchParams] = useSearchParams();
  const theTeam = searchParams.get("id");
  const theURL =
    "https://enigmatic-atoll-29291.herokuapp.com/appTeam/" + theTeam;

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function lookupGG(theId) {
    if (theId === "a0u090000010VD5AAM") {
      return "Global Goal 1 - No Poverty";
    }
    if (theId === "a0u090000010VDAAA2") {
      return "Global Goal 2 - Zero Hunger";
    }
    if (theId === "a0u090000010VyvAAE") {
      return "Global Goal 3 - Good Health and Well-being";
    }
    if (theId === "a0u090000010VyhAAE") {
      return "Global Goal 4 - Quality Education";
    }
    if (theId === "a0u090000010Vz1AAE") {
      return "Global Goal 5 - Gender Equality";
    }
    if (theId === "a0u090000010VyiAAE") {
      return "Global Goal 6 - Clean Water and Sanitation";
    }
    if (theId === "a0u090000010Vz5AAE") {
      return "Global Goal 7 - Affordable and Clean Energy";
    }
    if (theId === "a0u090000010VzAAAU") {
      return "Global Goal 8 - Decent Work and Economic Growth";
    }
    if (theId === "a0u090000010Vz6AAE") {
      return "Global Goal 9 - Industry, Innovation and Infrastructure";
    }
    if (theId === "a0u090000010VzKAAU") {
      return "Global Goal 10 - Reduced Inequalities";
    }
    if (theId === "a0u090000010VzLAAU") {
      return "Global Goal 11 - Sustainable Cities and Communities";
    }
    if (theId === "a0u090000010VzBAAU") {
      return "Global Goal 12 - Responsible Consumption and Production";
    }
    if (theId === "a0u090000010VzPAAU") {
      return "Global Goal 13 - Climate Action";
    }
    if (theId === "a0u090000010VymAAE") {
      return "Global Goal 14 - Life Below Water";
    }
    if (theId === "a0u090000010VzUAAU") {
      return "Global Goal 15 - Life On Land";
    }
    if (theId === "a0u090000010VzZAAU") {
      return "Global Goal 16 - Peace, Justice and Strong Institutions";
    }
    if (theId === "a0u090000010VywAAE") {
      return "Global Goal 17 - Partnership For The Goals";
    }
    return null;
  }

  const FetchTeam = async () => {
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log("data=", data);
      setTeam(data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchTeam();
  }, []);

  return (
    <GGWCUPLayout>
      <Container maxWidth="lg">
        <Typography sx={{ pt: 3 }} variant="h6">
          TEAM
        </Typography>
        <Typography color={"#f26e26"} sx={{ pb: 1 }} variant="h3">
          {team.name}
        </Typography>
        <Link
          href="https://ggwcup.com/blog/tag/Team"
          target="_blank"
          rel="noreferrer"
          color="inherit"
        >
          Team
        </Link>
        ,&nbsp;&nbsp;
        <Link
          href="https://ggwcup.com/blog/tag/GGWCUP+Denmark+2022"
          target="_blank"
          rel="noreferrer"
          color="inherit"
        >
          {team.program_name__c}
        </Link>
        ,&nbsp;&nbsp;
        <Link
          href="https://ggwcup.com/blog/tag/SDG10"
          target="_blank"
          rel="noreferrer"
          color="inherit"
        >
          SDG10
        </Link>
        <Typography color={"#434343"} sx={{ pt: 4, pb: 4 }} variant="h5">
          {team.program_name__c}
        </Typography>
        {team.primary_photo__c ? (
          <NiceCardMedia
            component="img"
            sx={{ height: 600 }}
            image={team.primary_photo__c}
            alt="Team Img"
          />
        ) : (
          <div />
        )}
        <Typography align="left" sx={{ pt: 1, pb: 4, pl: 1 }} variant="h5">
          {team.name} plays for {lookupGG(team.global_goal__c)}
          {team.program_show_local_language__c ? (
            <div>
              <Grid align="left" container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ pt: 1, pb: 0 }} variant="body1">
                    <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: team.introduce_the_team_club__c,
                        }}
                      />
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    align="right"
                    sx={{ pt: 1, pb: 0 }}
                    variant="body1"
                  >
                    <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: team.introduce_the_team_club_local__c,
                        }}
                      />
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography sx={{ pt: 1, pb: 0 }} variant="body1">
              <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: team.introduce_the_team_club__c,
                  }}
                />
              </Box>
            </Typography>
          )}
        </Typography>
        <Divider />
        <Grid align="left" container spacing={2}>
          <Grid item xs={12} md={6}>
            <NiceCardMedia
              component="img"
              sx={{
                width: 300,
                pt: 4,
                display: { xs: "flex", md: "none" },
              }}
              image={flipGG(team.global_goal__c)}
              alt="GGWCUP Global Goal"
            />
            {team.global_goal_why__c ? (
              <div>
                <Typography sx={{ pt: 4, pb: 0 }} variant="h5">
                  Why this Global Goal?
                  <Typography sx={{ pt: 1, pb: 0 }} variant="body1">
                    <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: team.global_goal_why__c,
                        }}
                      />
                    </Box>
                  </Typography>
                </Typography>
              </div>
            ) : (
              <div />
            )}
            {team.global_goal_impact__c ? (
              <div>
                <Typography sx={{ pt: 4, pb: 0 }} variant="h5">
                  How will the Team take action for the Goal?
                  <Typography sx={{ pt: 1, pb: 0 }} variant="body1">
                    <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: team.global_goal_impact__c,
                        }}
                      />
                    </Box>
                  </Typography>
                </Typography>
              </div>
            ) : (
              <div />
            )}
            {team.global_goal_impact__c ? (
              <div>
                <Typography sx={{ pt: 4, pb: 0 }} variant="h5">
                  How will the Team measure impact and reach?
                  <Typography sx={{ pt: 1, pb: 0 }} variant="body1">
                    <Box sx={{ pl: 1, m: 1, pb: 4 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: team.global_goal_action__c,
                        }}
                      />
                    </Box>
                  </Typography>
                </Typography>
              </div>
            ) : (
              <div />
            )}
            {team.instragram_handle__c ? (
              <Box sx={{ pl: 1, m: 1, pt: 4 }}>
                <Link
                  href={
                    "https://www.instagram.com/" +
                    team.instragram_handle__c +
                    "/"
                  }
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                >
                  Instagram ({team.instragram_handle__c})
                </Link>
              </Box>
            ) : (
              <div />
            )}
            {team.twitter_handle__c ? (
              <Box sx={{ pl: 1, m: 1, pt: 4 }}>
                <Link
                  href={"https://www.twitter.com/" + team.twitter_handle__c}
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                >
                  Twitter (@{team.twitter_handle__c})
                </Link>
              </Box>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "flex" } }}>
            <NiceCardMedia
              component="img"
              sx={{ height: 500, pl: 5, pt: 4 }}
              image={flipGG(team.global_goal__c)}
              alt="GGWCUP Global Goal"
            />
          </Grid>
        </Grid>
        <Divider sx={{ pt: 2, pb: 2 }} />
      </Container>
    </GGWCUPLayout>
  );
};

export default Teams;
