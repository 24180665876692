import React, { useState, createContext, useContext } from "react";

// Create the context 
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

   const sessionAuthenticated = JSON.parse(sessionStorage.getItem("authenticated"));
   const sessionAuthResultPayload = JSON.parse(sessionStorage.getItem("authResultPayload"));
   
   const [authenticated, setAuthenticated] = useState(sessionAuthenticated);
   const authResultPayload = sessionAuthResultPayload;

   //console.log("sessionAuthResultPayload in AutrhProvider= ", sessionAuthResultPayload);
   //console.log("authenticated in AutrhProvider= ", authenticated);

   return (
      <AuthContext.Provider value={{ authenticated, setAuthenticated, authResultPayload }}>
         {children}
      </AuthContext.Provider>
   );
};

// Finally creating the custom hook 
export const useAuth = () => useContext(AuthContext);