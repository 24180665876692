import { configureStore } from '@reduxjs/toolkit'
import programReducer from '@redux/programSlice'
import userReducer from '@redux/userSlice'
import credentialReducer from '@redux/credentialSlice'
import membersReducer from '@redux/membersSlice'

export default configureStore({
  reducer: {
    program: programReducer,
    user: userReducer,
    credential: credentialReducer,
    members: membersReducer,
  },
})