import React from "react";
import { PublicLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const SignUpConfirmation = () => {
  return (
    <PublicLayout>
      <Typography variant="h2">SIGN UP CONFIRMATION</Typography>
      <Box sx={{ p: 2, backgroundColor: "#FFF", flexGrow: 1 }}>
        <img src="assets/hero_1.jpg" alt="EIR Close The Gap" height="400px" />
      </Box>
    </PublicLayout>
  );
};

export default SignUpConfirmation;
