import React from 'react';
import { Home, SignUpConfirmation, OrgPortal, Gate, Roster, PublicLeague, Scorecards, SignUp, Teams, TeamProfile, WeeklyReportCoach, LeagueReportCaptain, Profile, Feedback, OnBoarding, Program, Community, Survey, CropAvatar, CropTeamPicture,  DisplayProfile, Training, Guard, Calendar, TrainingPrograms, Event, Guideline, DisplayEvent} from "@pages";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import './App.css';

function Gated({ children }) {
  let authenticated = false;
  const location = useLocation();
  if (sessionStorage.getItem("sftoken")) {
    authenticated = true;
  };

  return authenticated === true ? (
    children
  ) : (
    <Navigate to="/gate" replace state={{ path: location.pathname }} />
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/signup" element={<SignUp />}/>
          <Route path="/public-teams" element={<Teams />}/>
          <Route path="/signup-confirm" element={<SignUpConfirmation />}/>
          <Route path="/gate" element={<Gate />}/>
          <Route path="/guard" element={<Guard />}/>
          <Route path="/onboarding" element={<OnBoarding />} />
          <Route path="/public-league" element={<PublicLeague />} />
          <Route path="/impact-league" element={<PublicLeague />} />

          <Route path="/" element={<Gated><Home /></Gated>}/>
          <Route path="/home" element={<Gated><Home /></Gated>}/>
          <Route path="/feedback" element={<Feedback />}/>
          <Route path="/roster" element={<Gated><Roster /></Gated>}/>
          <Route path="/orgportal" element={<Gated><OrgPortal /></Gated>}/>
          <Route path="/user" element={<Gated><Profile /></Gated>}/>
          <Route path="/profile" element={<Gated><DisplayProfile /></Gated>}/>
          <Route path="/team" element={<Gated><TeamProfile /></Gated>}/>
          <Route path="/scorecards" element={<Gated><Scorecards /></Gated>}/>
          <Route path="/directory" element={<Gated><Community /></Gated>}/>
          <Route path="/library" element={<Gated><Program /></Gated>}/>
          <Route path="/survey" element={<Gated><Survey /></Gated>}/>
          <Route path="/avatar" element={<Gated><CropAvatar /></Gated>}/>
          <Route path="/team-picture" element={<Gated><CropTeamPicture /></Gated>}/>
          <Route path="/training" element={<Gated><Training /></Gated>}/>
          <Route path="/weekly-coach" element={<Gated><WeeklyReportCoach /></Gated>}/>
          <Route path="/ar-league-captain" element={<Gated><LeagueReportCaptain /></Gated>}/>
          <Route path="/training-programs" element={<Gated><TrainingPrograms /></Gated>}/>
          <Route path="/calendar" element={<Gated><Calendar /></Gated>}/>
          <Route path="/event" element={<Gated><Event /></Gated>}/>
          <Route path="/displayevent" element={<Gated><DisplayEvent /></Gated>}/>
          <Route path="/guideline" element={<Gated><Guideline /></Gated>}/>
      </Routes>
    </div>
   );
}

export default App;
