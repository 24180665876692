export const TRANSLATIONS_DK = {
    signup_welcome: "Velkommen til Global Goals World Cup",
    signup_intro: "Du er ved at tilmelde et hold til at deltage i en GGWCUP. ",
    signup_subintro: "Dit hold bliver en del af et globalt netværk af kvinder, der spiller for en bæredygtig og en mere retfærdig fremtid for alle.",
    signup_tournament: "Vælg turnering",
    signup_globalgoal: "Vælg verdensmål (du kan ændre senere)",
    signup_firstname: "Dit fornavn",
    signup_lastname: "Dit efternavn",
    signup_email: "Din e-mailadresse",
    signup_email_verify: "Gentag din e-mailadresse ",
    signup_team: "Dit holdnavn (du kan ændre senere)",
    signup_acknowledge: "Du ved, at turneringen er ekslusivt for kvinder?",
    signup_concent: "Consent and Liability Waiver ",
    signup_remind: "Efter din holdtilmelding, vil du modtage en e-mail med mere information om turneringen, hvordan du tilføjer spillere og meget mere",
    signup_submit: "Tilmeld hold",
   };