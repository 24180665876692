import React, { useState, useEffect } from "react";
import { SidebarLayout } from "@layouts";
import { PublicLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { setCredentialInStore } from "@redux/credentialSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInStore } from "@redux/userSlice";
import { clearProgramInStore } from "@redux/programSlice";

//import { Lock } from "@components";
//<Lock />

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const WhiteTypography = styled(Typography)({
  width: "95%",
  margin: "10px",
  color: "#FFFFFF",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  width: "95%",
  margin: "10px",
});

const Gate = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  const [waiverURL, setWaiverURL] = useState(
    "https://ggwcup-user-uploads.s3.eu-central-1.amazonaws.com/GGWCUP-Consent-and-Liability-Waiver-English-July-2022.pdf"
  );

  const [searchParams] = useSearchParams();
  const theLanguage = searchParams.get("language");
  const theContext = searchParams.get("context");
  const ref = React.useRef(null);

  const [checkAcknowledge, setCheckAcknowledge] = useState(false);
  const [checkWaiver, setCheckWaiver] = useState(false);

  const [program, setProgram] = useState();
  const [lastNext, setLastNext] = useState("Intro");
  const [disableNext, setDisableNext] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [textNext, setTextNext] = useState();
  const [bgNext, setBgNext] = useState();
  const [colorNext, setColorNext] = useState();
  const [textReset, setTextReset] = useState();
  const [activeTournaments, setActiveTournaments] = useState([]);
  const [loginStep, setLoginStep] = useState("Intro");
  const [showErrorMandatory, setShowErrorMandatory] = useState(false);
  const [showErrorPasswordMismatch, setShowErrorPasswordMismatch] =
    useState(false);
  const [showErrorPasswordWeak, setShowErrorPasswordWeak] = useState(false);
  const [showErrorPasswordWrong, setShowErrorPasswordWrong] = useState(false);
  const [showErrorEmail100, setShowErrorEmail100] = useState(false);
  const [showErrorMagic100, setShowErrorMagic100] = useState(false);
  const [showErrorMagic101, setShowErrorMagic101] = useState(false);

  const eirSF = useSelector((state) => state.credential);

  const [values, setValues] = useState({
    program: "",
    team: "",
    email: "",
    magiccode: "",
    hash: "",
    password: "",
    password2: "",
  });

  const FetchUsersTournaments = async (theID) => {
    //const theURL = "http://localhost:5000/userPrograms/" + theID;
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/userPrograms/" + theID;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log("tour=", data);
      setActiveTournaments(data);
      sessionStorage.setItem("userActivePrograms", JSON.stringify(data));
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const verifyEmail = async (theEmail) => {
    //const theURL = "http://localhost:5000/verifyEmail";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/verifyEmail";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    //console.log(data);
    try {
      if (data.code !== 100) {
        var tournaments = await FetchUsersTournaments(data.contact_id);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const verifyMagic = async (theEmail, theMagic) => {
    //const theURL = "http://localhost:5000/verifyMagic";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/verifyMagic";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
      magic: theMagic,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const verifyHash = async (theEmail, theHash) => {
    //const theURL = "http://localhost:5000/verifyHash";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/verifyHash";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
      hash: theHash,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      dispatch(setCredentialInStore(data));
      sessionStorage.setItem("sfid", data.sfid);
      sessionStorage.setItem("sftoken", data.sftoken.access_token);
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const updateHash = async (theEmail, theHash) => {
    //const theURL = "http://localhost:5000/updateHash";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/updateHash";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
      hash: theHash,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const updateLoginCount = async (theEmail) => {
    //const theURL = "http://localhost:5000/updateLoginCount";
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/updateLoginCount";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const sendMagic = async (theEmail) => {
    //const theURL = "http://localhost:5000/sendMagic";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/sendMagic";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("Version 30");
    if (theContext === "switchProgram") {
      setActiveTournaments(JSON.parse(sessionStorage.getItem("userActivePrograms")));
      showPickTournament();
    } else {
      setBgNext("#4a7830");
      setColorNext("#FFF");
      sessionStorage.clear();
      dispatch(clearUserInStore());
      dispatch(clearProgramInStore());

      i18n.changeLanguage("en");
      setLanguage("en");
      setTextNext(t("login_start"));
      setTextReset(t("login_reset"));
      if (theLanguage !== "") {
        i18n.changeLanguage(theLanguage);
        setLanguage(theLanguage);
      }
      ref.current.ownerDocument.body.scrollTop = 0;
    }
  }, []);

  const handleChangeAcknowledge = (event) => {
    setCheckAcknowledge(event.target.checked);
  };
  const handleChangeWaiver = (event) => {
    setCheckWaiver(event.target.checked);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  function showEmail() {
    setShowErrorMandatory(false);
    setShowErrorEmail100(false);
    setDisableNext(false);
    setTextNext(t("login_next"));
    setLoginStep("AddEmail");
  }
  function showResetPassword() {
    setShowErrorMandatory(false);
    setShowErrorPasswordMismatch(false);
    setShowErrorPasswordWeak(false);
    setDisableNext(false);
    setTextNext(t("login_next"));
    setLoginStep("ResetPassword");
  }
  function showPassword() {
    setShowErrorMandatory(false);
    setDisableNext(false);
    setTextNext(t("login_next"));
    setLoginStep("AddPassword");
  }
  function showMagic() {
    setShowErrorMandatory(false);
    setShowErrorMagic100(false);
    setShowErrorMagic101(false);
    setDisableNext(false);
    setTextNext(t("login_next"));
    setLoginStep("AddMagicCode");
  }
  function showAppBlocked() {
    setDisableNext(true);
    setTextNext(t("login_blank"));
    setBgNext("#FFF");
    setLoginStep("AppBlocked");
  }
  function showConfirmWaivers() {
    setDisableNext(false);
    setTextNext(t("signup_submit"));
    setLoginStep("ConfirmWaivers");
  }
  function showMandatoryReset() {
    setDisableNext(true);
    setTextNext(t("login_blank"));
    setLoginStep("MandatoryPasswordReset");
  }
  function showMagicSent() {
    sendMagic(values.email);
    setDisableNext(true);
    setTextNext(t("login_blank"));
    setLoginStep("MagicSent");
  }
  function gotoApp(theState) {
    setDisableNext(true);
    setDisableReset(true);
    setTextNext(t("login_blank"));
    if (theState === "newHash") {
      updateHash(values.email, values.password);
    }
    updateLoginCount(values.email);
    //setLoginStep("TheApp");
    navigate("/home");
  }
  function showPickTournament() {
    setDisableNext(true);
    setTextNext(t("login_blank"));
    setLoginStep("PickTournament");
  }

  function selectTournament(theTeamId, theProgramId) {
    setProgram(theProgramId);
    setValues({
      ...values,
      program: theProgramId,
      team: theTeamId,
    });
    sessionStorage.setItem("userProgram", theProgramId);
    sessionStorage.setItem("userTeam", theTeamId);
    gotoApp("direct");
  }

  const handleChange = async (event, newValue) => {
    if (newValue === "reset") {
      setLoginStep("Intro");
      setBgNext("#4a7830");
      setColorNext("#FFF");
      setDisableNext(false);
      setTextNext(t("login_start"));
      sessionStorage.clear();
      dispatch(clearUserInStore());
      dispatch(clearProgramInStore());
      setValues({
        program: "",
        team: "",
        email: "",
        magiccode: "",
        hash: "",
        password: "",
        password2: "",
      });
    }
    if (newValue === "next") {
      if (loginStep === "Intro") {
        showEmail();
      }
      //Magic
      if (loginStep === "AddMagicCode") {
        setShowErrorMandatory(false);
        if (values.magiccode.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        var stateMagic = await verifyMagic(values.email, values.magiccode);
        switch (stateMagic.code) {
          case 100:
            setShowErrorMagic100(true);
            break;
          case 101:
            setShowErrorMagic101(true);
            break;
          // code 200
          default:
            showResetPassword();
        }
      }
      //Email
      if (loginStep === "AddEmail") {
        setLastNext("Intro");
        setShowErrorMandatory(false);
        setShowErrorEmail100(false);
        if (values.email.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        var stateEmail = await verifyEmail(values.email);
        var stateTour = await FetchUsersTournaments(stateEmail.contact_id);
        //console.log("stateEmail=", stateEmail);
        //console.log("stateTour=", stateTour);
        if (stateTour.length === 0) {
          showAppBlocked();
          return;
        } else {
          switch (stateEmail.code) {
            case 100:
              setShowErrorEmail100(true);
              break;
            case 101:
              showAppBlocked();
              break;
            case 300:
              showMagic();
              break;
            case 400:
              showMandatoryReset();
              break;
            // code 200
            default:
              showPassword();
          }
        }
      }
      //Password
      if (loginStep === "AddPassword") {
        setShowErrorMandatory(false);
        setShowErrorPasswordWrong(false);
        if (values.hash.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        var stateHash = await verifyHash(values.email, values.hash);
        switch (stateHash.code) {
          case 100:
            setShowErrorPasswordWrong(true);
            break;
          // code 200
          default:
            if (activeTournaments.length > 1) {
              showPickTournament();
            } else {
              sessionStorage.setItem(
                "userProgram",
                activeTournaments[0].program_id
              );
              sessionStorage.setItem("userTeam", activeTournaments[0].team_id);
              gotoApp("direct");
            }
        }
      }
      //waivers & acknowledgements
      if (loginStep === "ConfirmWaivers") {
        setShowErrorMandatory(false);
        if (!checkAcknowledge) {
          setShowErrorMandatory(true);
          return;
        }
        if (!checkWaiver) {
          setShowErrorMandatory(true);
          return;
        }
        gotoApp("direct");
      }
      //Password reset
      if (loginStep === "ResetPassword") {
        setShowErrorMandatory(false);
        setShowErrorPasswordMismatch(false);
        setShowErrorPasswordWeak(false);
        if (values.password.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.password2.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.password !== values.password2) {
          setShowErrorPasswordMismatch(true);
          return;
        }
        if (values.password.length < 10) {
          setShowErrorPasswordWeak(true);
          return;
        }
        gotoApp("newHash");
      }
    }
    if (newValue === "cancel") {
      window.location.replace("https://ggwcup.com");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <PublicLayout>
      <Container maxWidth="lg">
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          <TabContext value={loginStep}>
            <TabPanel value="Intro">
              {/*               <FormControl size="small" sx={{ pt: 4, pb: 1, minWidth: 100 }}>
                <Select
                  labelId="language-simple-select-label"
                  id="i18n1"
                  name="i18n1"
                  value={language || "en"}
                  onChange={handleLanguageChange}
                >
                  <MenuItem key="1" value="en">
                    English
                  </MenuItem>
                  <MenuItem key="3" value="arab">
                    عربي
                  </MenuItem>
                </Select>
              </FormControl>
 */}{" "}
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_welcome")}
              </Typography>
              <Typography sx={{ pt: 1, pb: 2 }} variant="body2">
                {t("login_intro")}
              </Typography>
              <Typography sx={{ pt: 0, pb: 3 }} variant="body2">
                {t("login_subintro")}
              </Typography>
            </TabPanel>
            <TabPanel value="PickTournament">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("Login_tournament")}
              </Typography>
              {activeTournaments.map((row) => (
                <Box
                  id={row.team_id}
                  sx={{
                    height: "75px",
                    flexGrow: 1,
                    border: 1,
                    mb: 2,
                  }}
                  onClick={() => selectTournament(row.team_id, row.program_id)}
                >
                  <Typography sx={{ pt: 1, pb: 0 }} variant="h6">
                    {row.program_name}
                  </Typography>
                  <Typography sx={{ pt: 0, pb: 1 }} variant="body1">
                    {row.team_name}
                  </Typography>
                </Box>
              ))}
            </TabPanel>
            <TabPanel value="AppBlocked">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_app_blocked")}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("login_app_blocked_info")}
              </Typography>
            </TabPanel>
            <TabPanel value="MagicSent">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_magic_sent")}
              </Typography>
              <MuiLink
                component="button"
                onClick={() => showMagic()}
                variant="body1"
                underline="hover"
              >
                {t("login_return_to_magic")}
              </MuiLink>
            </TabPanel>
            <TabPanel value="AddEmail">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_email_headline")}
              </Typography>
              <NiceTextField
                required
                id="email"
                label={t("login_email")}
                name="email"
                value={values.email || ""}
                onChange={handleInputChange}
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorEmail100 ? (
                <div>
                  <Box
                    sx={{
                      height: "50px",
                      backgroundColor: "red",
                      flexGrow: 1,
                    }}
                  >
                    <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                      {t("error_email_wrong")}
                    </WhiteTypography>
                  </Box>
                </div>
              ) : (
                <div />
              )}
            </TabPanel>
            <TabPanel value="AddMagicCode">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_magiccode_headline")}
              </Typography>
              <NiceTextField
                required
                id="magiccode"
                label={t("login_magiccode")}
                name="magiccode"
                value={values.magiccode || ""}
                onChange={handleInputChange}
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorMagic100 ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_magic_100")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorMagic101 ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_magic_101")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              <MuiLink
                component="button"
                onClick={() => showMagicSent()}
                variant="body1"
                underline="hover"
              >
                {t("login_resend_magic")}
              </MuiLink>
            </TabPanel>
            <TabPanel value="ResetPassword">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_resetpassword_headline")}
              </Typography>
              <NiceTextField
                required
                id="password"
                type="password"
                label={t("login_password")}
                name="password"
                value={values.password || ""}
                onChange={handleInputChange}
              />
              <NiceTextField
                required
                id="password2"
                type="password"
                label={t("login_password_verify")}
                name="password2"
                value={values.password2 || ""}
                onChange={handleInputChange}
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorPasswordMismatch ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_password_mishmatch")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorPasswordWeak ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_password_weak")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
            </TabPanel>
            <TabPanel value="AddPassword">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_password_headline")}
              </Typography>
              <NiceTextField
                required
                id="hash"
                type="password"
                label={t("login_password")}
                name="hash"
                value={values.hash || ""}
                onChange={handleInputChange}
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorPasswordWrong ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_password_wrong")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              <MuiLink
                component="button"
                onClick={() => showMagicSent()}
                variant="body1"
                underline="hover"
              >
                {t("login_resend_magic")}
              </MuiLink>
            </TabPanel>
            <TabPanel value="MandatoryPasswordReset">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_magic_information_headline")}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("login_magic_information")}
              </Typography>
              <MuiLink
                component="button"
                onClick={() => showMagicSent()}
                variant="body1"
                underline="hover"
              >
                {t("login_resend_magic")}
              </MuiLink>
            </TabPanel>
            <TabPanel value="ConfirmWaivers">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("login_waiver_accept")}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("signup_concent")}
              </Typography>
              <NiceCheckboxFormControl
                align="left"
                sx={{ mb: 0, pb: 0 }}
                control={
                  <Checkbox
                    id="acknowledge"
                    checked={checkAcknowledge}
                    onChange={handleChangeAcknowledge}
                    name="acknowledge"
                  />
                }
                label={t("signup_acknowledge")}
              />
              <NiceCheckboxFormControl
                sx={{ mt: 0, pt: 0 }}
                control={
                  <Checkbox
                    id="waiver"
                    checked={checkWaiver}
                    onChange={handleChangeWaiver}
                    name="waiver"
                  />
                }
                label={
                  <Link target="_blank" rel="noopener" href={waiverURL}>
                    {t("signup_concent")}
                  </Link>
                }
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_boxes_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
            </TabPanel>

            <TabPanel value="TheApp">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                THIS IS SUPPOSE TO BE INSIDE THE APP
              </Typography>
            </TabPanel>
          </TabContext>
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              value={loginStep}
              showLabels
              onChange={handleChange}
            >
              <BottomNavigationAction
                disabled={disableReset}
                label={textReset}
                value="reset"
              />
              <BottomNavigationAction
                sx={{ bgcolor: bgNext, color: colorNext }}
                disabled={disableNext}
                label={textNext}
                value="next"
              />
              <BottomNavigationAction
                label={t("signup_cancel")}
                value="cancel"
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default Gate;
