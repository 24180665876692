import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "react-avatar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import CardMedia from "@mui/material/CardMedia";

window.Buffer = window.Buffer || require("buffer").Buffer;

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

function TeamProfile() {
  let navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [publishState, setPublishState] = useState(true);
  const [headcoaches, setHeadcoaches] = useState([]);
  const [members, setMembers] = useState([]);

  const confirm = useConfirm();

  const sftoken = sessionStorage.getItem("sftoken");
  const sfid = sessionStorage.getItem("sfid");

  const sfdemouser = sessionStorage.getItem("demoUser");
  const IsDataManager = sessionStorage.getItem("userDataManager");
  const userName = sessionStorage.getItem("userName");
  const userTeam = sessionStorage.getItem("userTeam");
  const userReviewer = sessionStorage.getItem("userReviewer");

  useEffect(() => {
    GetTeam();
  }, []);

  function updateAvatar() {
    navigate(
      "/team-picture?id=" + values.Id + "&src=" + values.Primary_Photo__c
    );
  }

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function reduceToHC(theMembers) {

    var scanData = theMembers;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.UserProfile__c === "Head Coach") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setHeadcoaches(scanData);
  }

  const GetTeam = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
        userTeam,
      requestOptions
    );
    const data = await response.json();
    try {
      setValues(data);
      if (data.Team_Profile_Status__c === "Published") {
        setSaveButtonDisabled(true);
        setPublishState(false);
      } else {
        setSaveButtonDisabled(false);
        setPublishState(false);
      }
  
      FetchMembers(data.Id);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchMembers = async (theTeam) => {
    var myHeaders = new Headers();
    var sqol = "select id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Avatar__c";
    sqol = sqol + ", UserProfile__c";
    sqol = sqol + ", Is_App_Data_Manager__c";
    sqol = sqol + ", Email";
    sqol = sqol + " from Contact";
    sqol = sqol + " where npsp__Primary_Affiliation__c = '" + theTeam + "'";
    sqol = sqol + " ORDER BY UserProfile__c, Name";

    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      reduceToHC(data.records);
    } catch (err) {
      console.log(err);
    }
  };

  function blockPublish(theValues) {
    var somethingIsMissing = false;

    if (theValues.Name === "") {
      somethingIsMissing = true;
    }
    if (theValues.Introduce_the_team_club__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Global_Goal__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Global_Goal_Action__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Global_Goal_Impact__c === "") {
      somethingIsMissing = true;
    }
    if (theValues.Global_Goal_Why__c === "") {
      somethingIsMissing = true;
    }
    return somethingIsMissing;
  }

  function PublishProfile() {
    if (sfdemouser === "true") {
      toast("Data is not saved because this is a demo session!");
      return;
    }
    const theID = values.Id.substr(0, 15);

    var publishValues = {
      Name: values.Name,
      Introduce_the_team_club__c: values.Introduce_the_team_club__c,
      Global_Goal__c: values.Global_Goal__c,
      Global_Goal_Action__c: values.Global_Goal_Action__c,
      Global_Goal_Impact__c: values.Global_Goal_Impact__c,
      Global_Goal_Why__c: values.Global_Goal_Why__c,
      Website: values.Website,
      App_Data_Manager__c: values.App_Data_Manager__c,
      Facebook_handle__c: values.Facebook_handle__c,
      Instragram_handle__c: values.Instragram_handle__c,
      Twitter_handle__c: values.Twitter_handle__c,
      Team_Profile_Status__c: "Published",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(publishValues, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
      theID,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Profile published"))
      .then((result) => GetTeam())
      .catch((error) => console.log("error", error));
  }

  function SaveProfile() {
    if (sfdemouser === "true") {
      toast("Data is not saved because this is a demo session!");
      return;
    }

    var publishValues = {
      Name: values.Name,
      Introduce_the_team_club__c: values.Introduce_the_team_club__c,
      Global_Goal__c: values.Global_Goal__c,
      Global_Goal_Action__c: values.Global_Goal_Action__c,
      Global_Goal_Impact__c: values.Global_Goal_Impact__c,
      Global_Goal_Why__c: values.Global_Goal_Why__c,
      Website: values.Website,
      App_Data_Manager__c: values.App_Data_Manager__c,
      Facebook_handle__c: values.Facebook_handle__c,
      Instragram_handle__c: values.Instragram_handle__c,
      Twitter_handle__c: values.Twitter_handle__c,
      Team_Profile_Status__c: "Draft",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(publishValues, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
        values.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Profile saved"))
      .then((result) => setSaveButtonDisabled(true))
      .then((result) => setPublishState(true))
      .catch((error) => console.log("error", error));
  }

  function confirmPublishFirst() {
    if (blockPublish(values)) {
      toast("All fields, except social handles, are mandatory!");
      return;
    }

    confirm({
      description: "Do you really want to publish this program",
    })
      .then(() => {
        PublishProfile(values);
      })
      .catch(() => {
        /* ... */
      });
  }

  const handleInputChange = (e) => {
    //console.log("e.target=", e.target);
    //console.log("e.target.checked=", e.target.checked);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (values.Team_Profile_Status__c === "Published") {
      setSaveButtonDisabled(true);
      setPublishState(false);
    } else {
      setSaveButtonDisabled(false);
      setPublishState(false);
    }
  };

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form>
          <Grid align="left" container spacing={2}>
            <Grid item>
              {IsDataManager === "true" || userReviewer === "true" ? (
                <div>
                  <div>
                    <Box sx={{ p: 1, pt: 3 }}>
                      <Avatar
                        name={values.Name || "XX XX"}
                        size="150"
                        src={values.Primary_Photo__c}
                        color={Avatar.getRandomColor("sitebase", [
                          "red",
                          "blue",
                        ])}
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <Button
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        startIcon={<PhotoCamera />}
                        onClick={updateAvatar}
                      >
                        Update Team Picture
                      </Button>
                    </Box>
                  </div>
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    TEAM DETAILS
                  </Typography>
                  <NiceTextField
                    required
                    id="profile-teamname"
                    label="Team Name"
                    value={values.Name || ""}
                    placeholder="Be creative and pick a name that reflects who you are, your team's Goals and works well when yelled out loud."
                    name="Name"
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-bio"
                    label="Team Bio"
                    value={values.Introduce_the_team_club__c || ""}
                    name="Introduce_the_team_club__c"
                    placeholder="Introduce the team and what makes the team significant"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                  />
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Your Global Goal
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="profile-globalgoal"
                      value={values.Global_Goal__c || ""}
                      label="Global Goal"
                      name="Global_Goal__c"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={"a0u090000010VD5AAM"}>
                        Global Goal 1 - No Poverty
                      </MenuItem>
                      <MenuItem value={"a0u090000010VDAAA2"}>
                        Global Goal 2 - Zero Hunger
                      </MenuItem>
                      <MenuItem value={"a0u090000010VyvAAE"}>
                        Global Goal 3 - Good Health and Well-being
                      </MenuItem>
                      <MenuItem value={"a0u090000010VyhAAE"}>
                        Global Goal 4 - Quality Education
                      </MenuItem>
                      <MenuItem value={"a0u090000010Vz1AAE"}>
                        Global Goal 5 - Gender Equality
                      </MenuItem>
                      <MenuItem value={"a0u090000010VyiAAE"}>
                        Global Goal 6 - Clean Water and Sanitation
                      </MenuItem>
                      <MenuItem value={"a0u090000010Vz5AAE"}>
                        Global Goal 7 - Affordable and Clean Energy
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzAAAU"}>
                        Global Goal 8 - Decent Work and Economic Growth
                      </MenuItem>
                      <MenuItem value={"a0u090000010Vz6AAE"}>
                        Global Goal 9 - Industry, Innovation and Infrastructure
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzKAAU"}>
                        Global Goal 10 - Reduced Inequalities
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzLAAU"}>
                        Global Goal 11 - Sustainable Cities and Communities
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzBAAU"}>
                        Global Goal 12 - Responsible Consumption and Production
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzPAAU"}>
                        Global Goal 13 - Climate Action
                      </MenuItem>
                      <MenuItem value={"a0u090000010VymAAE"}>
                        Global Goal 14 - Life Below Water
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzUAAU"}>
                        Global Goal 15 - Life On Land
                      </MenuItem>
                      <MenuItem value={"a0u090000010VzZAAU"}>
                        Global Goal 16 - Peace, Justice and Strong Institutions
                      </MenuItem>
                      <MenuItem value={"a0u090000010VywAAE"}>
                        Global Goal 17 - Partnership For The Goals
                      </MenuItem>
                    </Select>
                  </NiceSelectFormControl>
                  <NiceTextField
                    id="profile-whyGG"
                    label="Why is this Goal important to the Team?"
                    value={values.Global_Goal_Why__c || ""}
                    name="Global_Goal_Why__c"
                    placeholder="This Global Goal is important to us because …"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-actionGG"
                    label="How will the Team take action for the Goal?"
                    value={values.Global_Goal_Action__c || ""}
                    name="Global_Goal_Action__c"
                    placeholder="Describe the team’s campaign for the Goal. You will be awarded for how the team create and take actions as a team."
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-impactGG"
                    label="How will the Team measure impact and reach?"
                    value={values.Global_Goal_Impact__c || ""}
                    name="Global_Goal_Impact__c"
                    placeholder="What is success for the team's actions for the Goal?"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-website-handle"
                    label="Website URL"
                    value={values.Website || ""}
                    name="Website"
                    onChange={handleInputChange}
                  />
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    Social handles
                  </Typography>
                  <NiceTextField
                    id="profile-fb-handle"
                    label="Facebook handle"
                    value={values.Facebook_handle__c || ""}
                    name="Facebook_handle__c"
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-instergram-handle"
                    label="Instagram handle"
                    value={values.Instragram_handle__c || ""}
                    name="Instragram_handle__c"
                    onChange={handleInputChange}
                  />
                  <NiceTextField
                    id="profile-twitter-handle"
                    label="Twitter handle"
                    value={values.Twitter_handle__c || ""}
                    name="Twitter_handle__c"
                    onChange={handleInputChange}
                  />
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    Team administration
                  </Typography>
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="demo-simple-select-label-dm">
                      Data Manager
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-dm"
                      id="profile-data-manager"
                      value={values.App_Data_Manager__c || ""}
                      label="Data Manager"
                      name="App_Data_Manager__c"
                      onChange={handleInputChange}
                    >
                      {headcoaches.map((row) => (
                        <MenuItem key={row.Id} value={row.Id}>
                          {row.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </NiceSelectFormControl>
                  <Box sx={{ m: 1, pb: 4 }}>
                    <Button
                      variant="contained"
                      disabled={saveButtonDisabled}
                      onClick={() => SaveProfile()}
                    >
                      Save Changes
                    </Button>
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      onClick={() => confirmPublishFirst()}
                      disabled={Boolean(publishState)}
                    >
                      Save & Publish
                    </Button>
                    <Button
                      sx={{ pl: 4 }}
                      component={Link}
                      to={"/"}
                      size="small"
                    >
                      Return to Dashboard
                    </Button>
                  </Box>
                </div>
              ) : (
                <div>
                  <Box sx={{ p: 1, pt: 3 }}>
                    <Avatar
                      name={values.Name || "XX XX"}
                      size="250"
                      src={values.Primary_Photo__c}
                      color={Avatar.getRandomColor("sitebase", ["red", "blue"])}
                    />
                  </Box>

                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    TEAM DETAILS
                  </Typography>
                  <NiceTextField
                    id="profile-teamname"
                    label="Team Name"
                    value={values.Name || ""}
                    name="Name"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-bio"
                    label="Team Bio"
                    value={values.Introduce_the_team_club__c || ""}
                    name="Introduce_the_team_club__c"
                    multiline
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceCardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={flipGG(values.Global_Goal__c)}
                    alt="GGWCUP Ball"
                  />
                  <NiceTextField
                    id="profile-whyGG"
                    label="Why is this Goal important to the Team?"
                    value={values.Global_Goal_Why__c || ""}
                    name="Global_Goal_Why__c"
                    multiline
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-actionGG"
                    label="How will the Team take action for the Goal?"
                    value={values.Global_Goal_Action__c || ""}
                    name="Global_Goal_Action__c"
                    multiline
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-impactGG"
                    label="How will the Team measure impact and reach?"
                    value={values.Global_Goal_Impact__c || ""}
                    name="Global_Goal_Impact__c"
                    multiline
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-fb-handle"
                    label="Facebook handle?"
                    value={values.Facebook_handle__c || ""}
                    name="Facebook_handle__c"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-instergram-handle"
                    label="Instagram handle?"
                    value={values.Instragram_handle__c || ""}
                    name="Instragram_handle__c"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-twitter-handle"
                    label="Twitter handle?"
                    value={values.Twitter_handle__c || ""}
                    name="Twitter_handle__c"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <NiceTextField
                    id="profile-website-handle"
                    label="Website URL?"
                    value={values.Website || ""}
                    name="Website"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    Team administration
                  </Typography>
                  <NiceTextField
                    id="profile-data-manager"
                    label="Data Manager"
                    value={values.App_Data_Manager_Name__c || ""}
                    name="App_Data_Manager_Name__c"
                    InputProps={{
                      readOnly: Boolean(true),
                      disableUnderline: true,
                    }}
                  />

                  <Box sx={{ m: 1, pb: 4 }}>
                    <Button
                      sx={{ pl: 4 }}
                      component={Link}
                      to={"/"}
                      size="small"
                    >
                      Return to Dashboard
                    </Button>
                  </Box>
                </div>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default TeamProfile;
