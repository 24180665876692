import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
        userInStore: false,
        userId: "",
        userEmail: "",
        userProgram: "",
        userName: "",
        userTeam: "",
        userDataManager: false,
        userReviewer: false,
        AccountId: "",
        Access_to_App__c: "",
        UserProfile__c: "",
        MobilePhone: "",
        Global_Goal__c: "",
        Play_Philosophy__c: "",
        Program__c: "",
        Avatar__c: "",
        Show_details_on_GGWCUP__c: "",
        Bio__c: "",
        Affiliated_Team__c: ""
  },
  reducers: {
    setUserInStore: (state, data) => {
        state.userInStore = true;
        state.userId = data.payload.Id;
        state.userEmail = data.payload.Email;
        state.userName = data.payload.Name;
        state.userTeam = data.payload.npsp__Primary_Affiliation__c;
        state.userProgram = data.payload.Program_Id__c;
        state.userDataManager = data.payload.Is_App_Data_Manager__c;
        state.userReviewer = data.payload.App_Reviewer__c;
        state.AccountId = data.payload.AccountId;
        state.Access_to_App__c = data.payload.Access_to_App__c;
        state.UserProfile__c = data.payload.UserProfile__c;
        state.MobilePhone = data.payload.MobilePhone;
        state.Global_Goal__c = data.payload.Global_Goal__c;
        state.Play_Philosophy__c = data.payload.Play_Philosophy__c;
        state.Program__c = data.payload.Program__c;
        state.Avatar__c = data.payload.Avatar__c;
        state.Show_details_on_GGWCUP__c = data.payload.Show_details_on_GGWCUP__c;
        state.Bio__c = data.payload.Bio__c;
        state.Affiliated_Team__c = data.payload.Affiliated_Team__c;
    },
    clearUserInStore: (state) => {
      state.userInStore = false;
      state.userId = "";
      state.userEmail = "";
      state.userName = "";
      state.userTeam = "";
      state.userProgram = "";
      state.userDataManager = "";
      state.userReviewer = "";
      state.AccountId = "";
      state.Access_to_App__c = "";
      state.UserProfile__c = "";
      state.MobilePhone = "";
      state.Global_Goal__c = "";
      state.Play_Philosophy__c = "";
      state.Program__c = "";
      state.Avatar__c = "";
      state.Show_details_on_GGWCUP__c = "";
      state.Bio__c = "";
      state.Affiliated_Team__c = "";
  },

  },
});

// Action creators are generated for each case reducer function
export const { setUserInStore, clearUserInStore } = userSlice.actions;

export default userSlice.reducer;
