const ipLookUp = async () => {
  try {
    const response = await fetch("https://pro.ip-api.com/json?key=MRKBtYn8hJDNMc7", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data.city + " / " + data.country;
  } catch (err) {}
  return "Unknown";
};

export async function notifyMonday(
  theIssue,
  theSection
) {
  var todayDate = new Date().toISOString().slice(0, 10);
  var theDevice = "Unknown";
  var theReporter = "TheApp";
  var theUserSessionEmail = sessionStorage.getItem("userEmail");
  var theLocation = await ipLookUp();

  //Detect mobile device
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    theDevice = "mobile device";
  } else {
    theDevice = "web browser";
  }
  
  let gqlquery =
    "mutation ($myItemName: String!, $columnVals: JSON!) { create_item (board_id:2530755064, item_name:$myItemName, column_values:$columnVals) { id } }";
  let gqlvars = {
    myItemName: theIssue,
    columnVals: JSON.stringify({
      email: { email: theUserSessionEmail, text: theUserSessionEmail },
      date4: { date: todayDate },
      status: { label: "New" },
      text6: theSection,
      text9: theReporter,
      text7: theLocation,
      text90: theDevice,
    }),
  };
  fetch("https://api.monday.com/v2", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE1NTA4ODM5MCwidWlkIjoyOTUzOTAyMiwiaWFkIjoiMjAyMi0wNC0xMFQwODoxMToxMS40NzBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE3OTIyNzUsInJnbiI6InVzZTEifQ.5gZReNCMt0B0nDN1otaCyI6iSuzTGug_ZmPsUlmuehE",
    },
    body: JSON.stringify({
      query: gqlquery,
      variables: JSON.stringify(gqlvars),
    }),
  }).then((res) => res.json());
}

export async function notifyMondayByForm(
  theIssue,
  theSection,
  theEmail,
  theReporter,
  theDevice
) {
  var todayDate = new Date().toISOString().slice(0, 10);
  var theLocation = await ipLookUp();

  let gqlquery =
    "mutation ($myItemName: String!, $columnVals: JSON!) { create_item (board_id:2530755064, item_name:$myItemName, column_values:$columnVals) { id } }";
  let gqlvars = {
    myItemName: theIssue,
    columnVals: JSON.stringify({
      email: { email: theEmail, text: theEmail },
      date4: { date: todayDate },
      status: { label: "New" },
      text6: theSection,
      text9: theReporter,
      text7: theLocation,
      text90: theDevice,
    }),
  };
  fetch("https://api.monday.com/v2", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE1NTA4ODM5MCwidWlkIjoyOTUzOTAyMiwiaWFkIjoiMjAyMi0wNC0xMFQwODoxMToxMS40NzBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE3OTIyNzUsInJnbiI6InVzZTEifQ.5gZReNCMt0B0nDN1otaCyI6iSuzTGug_ZmPsUlmuehE",
    },
    body: JSON.stringify({
      query: gqlquery,
      variables: JSON.stringify(gqlvars),
    }),
  }).then((res) => res.json());
}
