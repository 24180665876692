import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Container, Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "react-avatar";
import { UniqueArray } from "@lib";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { format, parseJSON } from "date-fns";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useConfirm } from "material-ui-confirm";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

const EmailButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

function TrainingPrograms() {
  let navigate = useNavigate();
  const confirm = useConfirm();

  const theUserID = sessionStorage.getItem("userId");
  const theTeamID = sessionStorage.getItem("userTeam");
  const theProgramID = sessionStorage.getItem("userProgram");
  const [openPreview, setOpenPreview] = React.useState(false);
  const [cloneState, setCloneState] = useState(true);
  const [tabPreview, setTabPreview] = useState("1");

  const [rawData, setRawData] = useState([]);
  const [valuesPreview, setValuesPreview] = useState([]);
  const [values, setValues] = useState([]);
  const [listCounter, setListCounter] = useState(0);

  function viewProgram(theID, theState) {
    if (theState === "Published") {
      GetTraining(theID);
    } else {
      navigate("/training?type=new&id=" + theID);
    }
  }

  const togglePreview = (newOpen) => () => {
    setOpenPreview(newOpen);
  };

  useEffect(() => {
    FetchPrograms();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const CreateProgram = async () => {
    var theJSON = {
      Reviewed__c: false,
      Type__c: "Regular",
      Name: "New training program",
      Program__c: theProgramID,
      Coach__c: theUserID,
      Team__c: theTeamID,
      State__c: "Draft",
    };

    //console.log("theJSON= ", theJSON);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/",
      requestOptions
    );

    const data = await response.json();
    //console.log("data = ", data);

    try {
      toast("New program created");
      navigate("/training?type=new&id=" + data.id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabChangePreview = (event, newValue) => {
    setTabPreview(newValue);
  };

  const GetTraining = async (theGetId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/" +
        theGetId,
      requestOptions
    );
    try {
      const data = await response.json();
      setValuesPreview(data);
      if (data.Coach__c === theUserID || data.Type__c === 'Template') {
        setCloneState(false);
      } else {
        setCloneState(true);

      }
      setOpenPreview(true);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchPrograms = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Reviewed__c";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Name";
    sqol = sqol + ", Theme__c";
    sqol = sqol + ", Program__r.Name";
    sqol = sqol + ", Coach__c";
    sqol = sqol + ", Coach__r.Name";
    sqol = sqol + ", Team__r.Name";
    sqol = sqol + ", State__c";
    sqol = sqol + ", LastModifiedDate";
    sqol = sqol + " from Training_Program__c";
    sqol = sqol + " where Team__c = '" + theTeamID + "'";
    sqol = sqol + " or Type__c = 'Template'";
    sqol = sqol + " ORDER BY LastModifiedDate DESC";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      var scanData = data.records;
      scanData = scanData.reduce((filterItems, item) => {
        if (item.Coach__c === theUserID || item.State__c === "Published") {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
      setRawData(scanData);
      setValues(scanData);
      setListCounter(scanData.length);
    } catch (err) {
      console.log(err);
    }
  };

  function confirmCloneFirst() {
    confirm({
      description: "Do you really want to clone this program",
    })
      .then(() => {
        CloneProgram();
      })
      .catch(() => {
        /* ... */
      });
  }

  const CloneProgram = async () => {
    var cloneValues = valuesPreview;

    cloneValues.Name = "Cloned from " + cloneValues.Name;
    cloneValues.Coach__c = theUserID;
    cloneValues.State__c = "Draft";
    delete cloneValues.Id;
    delete cloneValues.CoachName__c;
    delete cloneValues.Theme_Name__c;
    delete cloneValues.SystemModstamp;
    delete cloneValues.IsDeleted;
    delete cloneValues.CreatedById;
    delete cloneValues.CreatedDate;
    delete cloneValues.LastActivityDate;
    delete cloneValues.LastModifiedById;
    delete cloneValues.LastModifiedDate;
    delete cloneValues.Sequence_Number__c;

    //console.log("cloneValues = ", cloneValues);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(cloneValues);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Training_Program__c/",
      requestOptions
    );

    const data = await response.json();
    //console.log("data = ", data);

    try {
      toast("Program cloned");
      FetchPrograms();
      setOpenPreview(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Training Programs">
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            },
          },
        }}
        anchor="right"
        open={openPreview}
        onClose={togglePreview(false)}
        onOpen={togglePreview(true)}
      >
        <Container>
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              {valuesPreview.Name || ""}
            </Typography>

            <CloseIcon
              fontSize="large"
              align="right"
              onClick={togglePreview(false)}
            />
          </Box>
          <Typography align="left" variant="body1">
            By {valuesPreview.CoachName__c || "Name"}
          </Typography>
          <Button
              variant="contained"
              disabled={cloneState}
              sx={{ mb: 2 }}
              onClick={()=>confirmCloneFirst()}
            >
              Clone program
            </Button>

          {valuesPreview.Theme__c && (
            <div>
              <NiceTextField
                id="training-Theme__c"
                InputProps={{
                  readOnly: Boolean(true),
                  disableUnderline: true,
                }}
                label="Theme"
                name="Theme__c"
                variant="standard"
                multiline
                value={valuesPreview.Theme_Name__c}
              />
            </div>
          )}
          {valuesPreview.Reflections__c && (
            <div>
              <NiceTextField
                id="training-Reflections__c"
                InputProps={{
                  readOnly: Boolean(true),
                  disableUnderline: true,
                }}
                label="Reflection"
                name="Reflections__c"
                variant="standard"
                multiline
                value={valuesPreview.Reflections__c || ""}
              />
            </div>
          )}
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabPreview}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTabChangePreview}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="1 Togetherness moment"
                    sx={{
                      color: "#FF3200",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="1"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FF3200",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="1"
                  />
                  <Tab
                    label="2 Technical moment"
                    sx={{
                      color: "#FF33F3",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="2"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FF33F3",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="2"
                  />
                  <Tab
                    label="3 Game moment"
                    sx={{
                      color: "#FAC200",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="3"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#FAC200",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="3"
                  />
                  <Tab
                    label="4 Game"
                    sx={{
                      color: "#00B287",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        xl: "flex",
                      },
                    }}
                    value="4"
                  />
                  <Tab
                    icon={<SportsSoccerIcon />}
                    sx={{
                      color: "#00B287",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "none",
                        xl: "none",
                      },
                    }}
                    value="4"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                {" "}
                <Grid
                  sx={{ display: "block" }}
                  align="left"
                  container
                  spacing={2}
                >
                  <Grid item>
                    <div>
                      {valuesPreview.Togetherness_Moment_What__c && (
                        <div>
                          <NiceTextField
                            sx={{ display: "flex" }}
                            id="training-togetherness-what"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="What - Togetherness Moment"
                            name="Togetherness_Moment_What__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Togetherness_Moment_What__c || ""
                            }
                          />
                        </div>
                      )}
                      {valuesPreview.Togetherness_Moment_Why__c && (
                        <div>
                          <NiceTextField
                            id="training-togetherness-why"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Why - Togetherness Moment"
                            name="Togetherness_Moment_Why__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Togetherness_Moment_Why__c || ""
                            }
                          />
                        </div>
                      )}
                      {valuesPreview.Togetherness_Moment_How__c && (
                        <div>
                          <NiceTextField
                            id="training-togetherness-how"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="How - Togetherness Moment"
                            name="Togetherness_Moment_How__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Togetherness_Moment_How__c || ""
                            }
                          />
                        </div>
                      )}
                      {valuesPreview.Togetherness_Moment_Intro__c && (
                        <div>
                          <NiceTextField
                            id="training-togetherness-intro"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Introduction - Togetherness Moment"
                            name="Togetherness_Moment_Intro__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Togetherness_Moment_Intro__c || ""
                            }
                          />
                        </div>
                      )}
                      {valuesPreview.Togetherness_Moment_Progress__c && (
                        <div>
                          <NiceTextField
                            id="training-togetherness-Progression"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Progression - Togetherness Moment"
                            name="Togetherness_Moment_Progress__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Togetherness_Moment_Progress__c ||
                              ""
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                {" "}
                <Grid
                  sx={{ display: "block" }}
                  align="left"
                  container
                  spacing={2}
                >
                  <Grid item>
                    <div>
                      {valuesPreview.Technical_Moment_What__c && (
                        <div>
                          <NiceTextField
                            sx={{ display: "flex" }}
                            id="training-Technical-what"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="What - Technical Moment"
                            name="Technical_Moment_What__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Technical_Moment_What__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Technical_Moment_Why__c && (
                        <div>
                          <NiceTextField
                            id="training-Technical-why"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Why - Technical Moment"
                            name="Technical_Moment_Why__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Technical_Moment_Why__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Technical_Moment_How__c && (
                        <div>
                          <NiceTextField
                            id="training-Technical-how"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="How - Technical Moment"
                            name="Technical_Moment_How__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Technical_Moment_How__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Technical_Moment_Intro__c && (
                        <div>
                          <NiceTextField
                            id="training-Technical-intro"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Introduction - Technical Moment"
                            name="Technical_Moment_Intro__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Technical_Moment_Intro__c || ""
                            }
                          />
                        </div>
                      )}
                      {valuesPreview.Technical_Moment_Progress__c && (
                        <div>
                          <NiceTextField
                            id="training-Technical-Progression"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Progression - Technical Moment"
                            name="Technical_Moment_Progress__c"
                            variant="standard"
                            multiline
                            value={
                              valuesPreview.Technical_Moment_Progress__c || ""
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                {" "}
                <Grid
                  sx={{ display: "block" }}
                  align="left"
                  container
                  spacing={2}
                >
                  <Grid item>
                    <div>
                      {valuesPreview.Game_Moment_What__c && (
                        <div>
                          <NiceTextField
                            sx={{ display: "flex" }}
                            id="training-Game-what"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="What - Game Moment"
                            name="Game_Moment_What__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Moment_What__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Moment_Why__c && (
                        <div>
                          <NiceTextField
                            id="training-Game-why"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Why - Game Moment"
                            name="Game_Moment_Why__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Moment_Why__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Moment_How__c && (
                        <div>
                          <NiceTextField
                            id="training-game-how"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="How - Game Moment"
                            name="Game_Moment_How__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Moment_How__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Moment_Intro__c && (
                        <div>
                          <NiceTextField
                            id="training-game-intro"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Introduction - Game Moment"
                            name="Game_Moment_Intro__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Moment_Intro__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Moment_Progress__c && (
                        <div>
                          <NiceTextField
                            id="training-game-Progression"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Progression - Game Moment"
                            name="Game_Moment_Progress__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Moment_Progress__c || ""}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="4">
                {" "}
                <Grid
                  sx={{ display: "block" }}
                  align="left"
                  container
                  spacing={2}
                >
                  <Grid item>
                    <div>
                      {valuesPreview.Game_What__c && (
                        <div>
                          <NiceTextField
                            sx={{ display: "flex" }}
                            id="training-game2-what"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="What - Game"
                            name="Game_What__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_What__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Why__c && (
                        <div>
                          <NiceTextField
                            id="training-game2-why"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Why - Game"
                            name="Game_Why__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Why__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_How__c && (
                        <div>
                          <NiceTextField
                            id="training-game2-how"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="How - Game"
                            name="Game_How__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_How__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Intro__c && (
                        <div>
                          <NiceTextField
                            id="training-game2-intro"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Introduction - Game"
                            name="Game_Intro__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Intro__c || ""}
                          />
                        </div>
                      )}
                      {valuesPreview.Game_Progress__c && (
                        <div>
                          <NiceTextField
                            id="training-game2-Progression"
                            InputProps={{
                              readOnly: Boolean(true),
                              disableUnderline: true,
                            }}
                            label="Progression - Game"
                            name="Game_Progress__c"
                            variant="standard"
                            multiline
                            value={valuesPreview.Game_Progress__c || ""}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Drawer>
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Training Programs
        </Typography>
        <div>
          <Box sx={{ display: "flex", justifyContent: "flex-begin" }}>
            <Button
              sx={{ ml: 2, mb: 3, mt: 1 }}
              variant="contained"
              size="small"
              align="left"
              onClick={CreateProgram}
            >
              Create new program
            </Button>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#{listCounter}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              variant="body1"
                            >
                              <MuiLink
                                component="button"
                                onClick={() =>
                                  viewProgram(row.Id, row.State__c)
                                }
                                variant="body1"
                                underline="hover"
                              >
                                {row.Name}
                              </MuiLink>
                            </Typography>
                            <Typography
                              align="left"
                              component="div"
                              variant="caption"
                            >
                              {"By: " + row.Coach__r.Name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {/*                             <Typography align="left" variant="caption">
                              {"Last modified: " + format( parseJSON(row.LastModifiedDate), "EEEE do MMM" )
                                }
                            </Typography> */}
                            <Typography
                              align="left"
                              component="div"
                              variant="caption"
                            >
                              {"State: " + row.State__c}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default TrainingPrograms;
