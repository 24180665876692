import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Container, Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { format, parseJSON } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { notifyMonday } from "@lib";
import RsvpIcon from '@mui/icons-material/Rsvp';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';

const EmailButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
  border: "none",
});

function Calendar() {
  let navigate = useNavigate();

  const theContactID = sessionStorage.getItem("userId");

  const [rawData, setRawData] = useState([]);
  const [values, setValues] = useState([]);
  const [event, setEvent] = useState([]);
  const [invitees, setInvitees] = useState([]);

  const [listCounter, setListCounter] = useState(0);

  function viewEvent(theRow) {
    FetchEvent(theRow.Training_Event__c);
  }

  const [open, setOpen] = useState(false);

  function OpenZoom(theURL) {
    console.log("URL= ", theURL);
    window.open(theURL, "_blank");
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    FetchInvitations();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const FetchInvitations = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Invitee__c";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Name";
    sqol = sqol + ", Date_and_Time__c";
    sqol = sqol + ", Training_Event_Name__c";
    sqol = sqol + ", Training_Event_Type__c";
    sqol = sqol + ", Training_event__c";
    sqol = sqol + " from Invitation__c";
    sqol = sqol + " where Invitee__c = '" + theContactID + "'";
    sqol = sqol + " AND Type__c = 'Training Invitation'";
    sqol = sqol + " ORDER BY Date_and_Time__c ASC";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" +
          sqol,
        requestOptions
      );
      const data = await response.json();
      console.log("Show me ",data);
      setRawData(data.records);
      setValues(data.records);
      console.log(values);
      setListCounter(data.records.length);
    } catch (err) {
      notifyMonday(err, "FetchInvitations()");
      console.log(err);
    }
  };

  const FetchEvent = async (theID) => {
    var sqol = "select Id";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Name";
    sqol = sqol + ", Date_and_Time__c";
    sqol = sqol + ", Global_Goal__c";
    sqol = sqol + ", Group__c";
    sqol = sqol + ", Zoom_Link__c";
    sqol = sqol + ", Zoom_Meeting_ID__c";
    sqol = sqol + ", Zoom_Passcode__c";
    sqol = sqol + ", Training_Program__c";
    sqol = sqol + ", State__c";
    sqol = sqol + ", Minutes__c";
    sqol = sqol + ", Message__c";
    sqol = sqol + ", Location__c";
    sqol = sqol + ", Invite_Group__c";
    sqol = sqol + ", Inviter_Name__c";
    sqol = sqol + ", Global_Goal_Name__c";
    sqol = sqol + ", Program_Name__c";
    sqol = sqol + " from Training_event__c";
    sqol = sqol + " where Id = '" + theID + "'";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      if (data.records[0].Date_and_Time__c) {
        data.records[0].NiceDateTime = format(
          parseJSON(data.records[0].Date_and_Time__c),
          "EEEE do MMM hh:mm"
        )
       } else {
          data.records[0].NiceDateTime = "";
        };  
      data.records[0].ProgramLink =
        "/training?id=" + data.records[0].Training_Program__c;
      setEvent(data.records[0]);
      //console.log("Event= ", data);
      FetchInvitees(data.records[0].Id);
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchInvitees = async (theEventID) => {
    var sqol = "select Id";
    sqol = sqol + ", Invitee__c";
    sqol = sqol + ", Name__c";
    sqol = sqol + ", Email__c";
    sqol = sqol + ", RSVP__c";
    sqol = sqol + " from Invitation__c";
    sqol = sqol + " where Training_Event__c = '" + theEventID + "'";
    sqol = sqol + " ORDER BY Name ASC";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" +
          sqol,
        requestOptions
      );
      const data = await response.json();
      //console.log("Invitees= ", data);
      setInvitees(data.records);
    } catch (err) {
      notifyMonday(err, "FetchInvitees()");
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Calendar">
      <div>
        <Drawer
          PaperProps={{
            sx: { width: "66%" },
          }}
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{ pt: 2, pr: 2 }} display="flex" justifyContent="space-between">
          <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
            Event
          </Typography>
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={() => setOpen(false)}
            />
          </Box>
          <NiceTextField
            id="event-inviter"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Inviter"
            value={event.Inviter_Name__c || ""}
          />
          <NiceTextField
            id="event-title"
            variant="standard"
            label="Subject"
            InputProps={{ disableUnderline: true }}
            value={event.Name || ""}
          />
          <NiceTextField
            id="event-message"
            InputProps={{ disableUnderline: true }}
            variant="standard"
            label="Message"
            name="Message__c"
            multiline
            value={event.Message__c || ""}
          />
          <NiceTextField
            id="event-date"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Date & Time"
            value={event.NiceDateTime || ""}
          />
          <NiceTextField
            id="event-minutes"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Duration (in minutes)"
            value={event.Minutes__c || ""}
          />
          <Typography sx={{ p: 1 }} gutterBottom variant="h6" component="div">
            EVENT CONTEXT
          </Typography>
          {event.Global_Goal_Name__c && (
            <NiceTextField
              id="training-globalgoal"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              label="Global Goal"
              value={event.Global_Goal_Name__c || ""}
            />
          )}

          {event.Type__c !== "Zoom" && (
            <div>
              <NiceTextField
                id="event-location"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                label="Location"
                multiline
                value={event.Location__c || ""}
              />
              <NiceTextField
                id="event-Training_Program"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                label="Training Program"
                value={event.Program_Name__c || ""}
              />
              <Button
                sx={{ ml: 1, mb: 3, mt: 0 }}
                variant="contained"
                size="small"
                align="left"
                href={event.ProgramLink || ""}
              >
                Open Program
              </Button>
            </div>
          )}

          {event.Type__c === "Zoom" && (
            <div>
              <NiceTextField
                id="training-zoomurl"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                label="Zoom Link"
                value={event.Zoom_Link__c || ""}
              />
              <Button
                sx={{ ml: 1, mb: 3, mt: 0 }}
                variant="contained"
                size="small"
                align="left"
                href={event.Zoom_Link__c}
                target={"_blank"}
              >
                Open Zoom
              </Button>
              <NiceTextField
                id="training-zoomid"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                label="Zoom Meeting ID"
                value={event.Zoom_Meeting_ID__c || ""}
              />
              <NiceTextField
                id="training-zoompc"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                label="Zoom Passcode"
                value={event.Zoom_Passcode__c || ""}
              />
            </div>
          )}
          <Typography sx={{ p: 1 }} gutterBottom variant="h6" component="div">
            Guests: {invitees.length}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table guests">
                    <TableBody>
                      {invitees.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell style={{ width: 30 }}>
                          {row.RSVP__c === "Awaiting" && ( <div> <RsvpIcon fontSize="large" /> </div> )}
                          {row.RSVP__c === "Yes" && ( <div> <RsvpIcon fontSize="large" sx={{ color: green[500] }} /> </div> )}
                          {row.RSVP__c === "No" && ( <div> <RsvpIcon fontSize="large" sx={{ color: red[500] }} /> </div> )}
                          </TableCell>
                          <TableCell>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              variant="body1"
                            >
                              {row.Name__c}
                            </Typography>
                            <Typography align="left" variant="caption">
                              {row.Email__c}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </Drawer>
      </div>
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Calendar
        </Typography>
        <div>
          <Box sx={{ display: "flex", justifyContent: "flex-begin" }}>
            <Button
              sx={{ ml: 2, mb: 3, mt: 1 }}
              variant="contained"
              size="small"
              align="left"
              component={Link}
              to={"/event"}
            >
              Create event
            </Button>
            <Button
              sx={{ ml: 2, mb: 3, mt: 1 }}
              variant="contained"
              size="small"
              align="left"
              onClick={FetchInvitations}
            >
              Refresh list
            </Button>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#{listCounter}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell style={{ width: 50 }}>
                            <Typography align="left" variant="body1">
                              {format(
                                parseJSON(row.Date_and_Time__c),
                                "EEEE do MMM hh:mm"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              variant="body1"
                            >
                              <MuiLink
                                component="button"
                                onClick={() => viewEvent(row)}
                                variant="body1"
                                underline="hover"
                              >
                                {row.Training_Event_Name__c}
                              </MuiLink>
                            </Typography>
                            <Typography align="left" variant="caption">
                              {row.Training_Event_Type__c}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Calendar;
