import { createSlice } from "@reduxjs/toolkit";

export const credentialSlice = createSlice({
  name: "credential",
  initialState: {
        isAuthorized: false,
        sftoken: "",
        sfid: "",
  },
  reducers: {
    setCredentialInStore: (state, data) => {
        state.isAuthorized = true;
        state.sftoken = data.payload.sftoken.access_token;
        state.sfid = data.payload.sfid;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCredentialInStore } = credentialSlice.actions;

export default credentialSlice.reducer;
