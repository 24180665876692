import React from "react";
import Document from "./Document";
import TopBar from "./Workbench/TopBar";
import DemoStatusBar from "./demo-status-bar";
import ProgramBanner from "./top-banner";
import BottomBanner from "./bottom-banner";

const AuthenticatedLayout = ({ title = "GGWCUP", children }) => (
  <Document>
    <TopBar />
    <DemoStatusBar />
    <ProgramBanner />
    {children}
    <BottomBanner />
  </Document>
);

export default AuthenticatedLayout;
