import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const DemoStatusBar = () => {
  const showDemoStatusBar = sessionStorage.getItem("demoUser");
  if (showDemoStatusBar === 'true') {
    return (
      <Box sx={{ backgroundColor: "red", flexGrow: 1 }}>
        <Typography style={{ color: "#FFF" }} variant="h5" component="span">
          DEMO SESSION
        </Typography>
      </Box>
    );
  } else {
    return <span></span>;
  }
};

export default DemoStatusBar;
