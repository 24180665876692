import React, { useState, useEffect } from "react";
import { PublicLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const WhiteTypography = styled(Typography)({
  width: "95%",
  margin: "10px",
  color: "#FFFFFF",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  width: "95%",
  margin: "10px",
});

const sampleCountries = [
  {
    sfid: "30275027350753",
    name: "Denmark",
    call_code__c: "45",
  },
  {
    sfid: "98374849",
    name: "Palestine (Gaza)",
    call_code__c: "970",
  },
  {
    sfid: "363737377",
    name: "Jordan",
    call_code__c: "962",
  },
];

const sampleTournaments = [
  {
    id: 519,
    sfid: "0010900001OUyvHAAT",
    heruko_id__c: "0010900001OUyvHAAT",
    name: "GGWCUP Palestine 2023",
    local_name__c: "GGWCUP Palestine 2023",
    program_language__c: "Arabic",
    eir_program_colour__c: "FCC7F3",
    program_waiver_url__c:
      "https://ggwcup-user-uploads.s3.eu-central-1.amazonaws.com/GGWCUP-Consent-and-Liability-Waiver-Bilingual-August-2022.pdf",
  },
  {
    id: 511,
    sfid: "0010900001OSeNSAA1",
    heruko_id__c: "0010900001OSeNSAA1",
    name: "GGWCUP Denmark 2023",
    local_name__c: "GGWCUP Denmark 2023",
    program_language__c: "English",
    eir_program_colour__c: "4C8CE4",
    program_waiver_url__c:
      "https://ggwcup-user-uploads.s3.eu-central-1.amazonaws.com/GGWCUP-Consent-and-Liability-Waiver-Bilingual-August-2022.pdf",
  },
];
const sampleNoTournaments = [];

const SignUp = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [waiverURL, setWaiverURL] = useState(
    "https://ggwcup-user-uploads.s3.eu-central-1.amazonaws.com/GGWCUP-Consent-and-Liability-Waiver-English-July-2022.pdf"
  );

  const [values, setValues] = useState({
    program: "",
    persona: "",
    first_name: "",
    last_name: "",
    team_name: "",
    team_id: "",
    callcode: "",
    email: "",
    email2: "",
    mobile: "",
    globalgoal: "",
    preferred_language: "",
    team_sport: "",
  });

  const [checkAcknowledge, setCheckAcknowledge] = useState(false);
  const [checkWaiver, setCheckWaiver] = useState(false);

  const [program, setProgram] = useState();
  const [programSports, setProgramSports] = useState(1);
  const [programSportsDefault, setProgramSportsDefault] = useState(1);
  const [showProgramSports, setShowProgramSports] = useState(false);
  const [programName, setProgramName] = useState();
  const [bgNext, setBgNext] = useState();
  const [colorNext, setColorNext] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [textNext, setTextNext] = useState();
  const [textReset, setTextReset] = useState();
  const [activeTournaments, setActiveTournaments] = useState([]);
  const [activeTeams, setActiveTeams] = useState([]);
  const [countries, setCountries] = useState([]);
  const [signupStep, setSignupStep] = useState("Intro");
  const [showErrorMandatory, setShowErrorMandatory] = useState(false);
  const [showErrorDublicateTeamName, setShowErrorDublicateTeamName] =
    useState(false);
  const [showErrorEmailMismatch, setShowErrorEmailMismatch] = useState(false);
  const [showErrorEmailDublicate, setShowErrorEmailDublicate] = useState(false);

  const handleProgramChange = (event) => {
    //console.log("Form::onChange", event);
  };

  const FetchOpenTournaments = async () => {
    //const theURL = "http://localhost:5000/openTournaments";
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/openTournaments";
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setActiveTournaments(data);
      //setActiveTournaments(sampleTournaments);
      //setActiveTournaments(sampleNoTournaments);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTeamsforTournaments = async () => {
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/squareTeams/" + program;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setActiveTeams(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchCountries = async () => {
    //const theURL = "http://localhost:5000/countries";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/countries";
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setCountries(data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkEmail = async (theEmail) => {
    //const theURL = "http://localhost:5000/checkEmail";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/checkEmail";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      email: theEmail,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const doSubmit = async () => {
    //const theURL = "http://localhost:5000/signup";
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/signup";

    if (values.team_id === "waitingforteam") {
      values.persona = "Waiting Player";
      values.team_id = "";
    }
    if (values.team_id === "floatingteam") {
      values.persona = "Floating Player";
      values.team_id = "";
    }
    if (!showProgramSports) {
      values.team_sport = programSportsDefault;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      program: values.program,
      persona: values.persona,
      first_name: values.first_name,
      last_name: values.last_name,
      team_name: values.team_name,
      team_id: values.team_id,
      callcode: values.callcode,
      email: values.email,
      mobile: values.mobile,
      globalgoal: values.globalgoal,
      preferred_language: values.preferred_language,
      team_sport: values.team_sport,
      type: "Signup",
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      showConfirmation();
    } catch (err) {
      console.log(err);
    }
  };

  function isTeamNameDublicate(theName) {
    var scanData = activeTeams;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.name.toLowerCase() === theName.toLowerCase()) {
        filterItems.push(item);
        console.log("found = ", theName);
      }
      return filterItems;
    }, []);
    if (scanData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const [searchParams] = useSearchParams();
  const theLanguage = searchParams.get("language");
  const ref = React.useRef(null);

  useEffect(() => {
    setBgNext("#FFF");
    setDisableNext(true);
    setTextNext(t("signup_blank"));
    setColorNext("#FFF");
    FetchOpenTournaments();
    FetchCountries();
    i18n.changeLanguage("en");
    setLanguage("en");
    setTextReset(t("signup_reset"));
    if (theLanguage !== "") {
      i18n.changeLanguage(theLanguage);
      setLanguage(theLanguage);
    }
    ref.current.ownerDocument.body.scrollTop = 0;
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  function selectTournament(theProgramId, theProgramName, theProgramSports) {
    setProgram(theProgramId);
    setProgramName(theProgramName);
    let raw_sports = theProgramSports;
    let sports = raw_sports.split(";");
    setProgramSports(sports);
    let sports_count = sports.length;
    if (sports_count > 1) {
      setShowProgramSports(true);
    } else {
      setProgramSportsDefault(sports[0]);
    }

    setValues({
      ...values,
      program: theProgramId,
    });
    showPickPersona();
  }

  function selectPersona(thePersona) {
    setValues({
      ...values,
      persona: thePersona,
    });
    if (thePersona === "User") {
      navigate("/gate?code=" + values.program);
    } else {
      FetchTeamsforTournaments(values.program);
      showAddContact();
    }
  }

  function selectTeam(theTeamID) {
    setValues({
      ...values,
      team_id: theTeamID,
    });
    showConfirmWaivers();
  }

  const handleChangeAcknowledge = (event) => {
    setCheckAcknowledge(event.target.checked);
  };
  const handleChangeWaiver = (event) => {
    setCheckWaiver(event.target.checked);
  };

  function showPickTournament() {
    setBgNext("#FFF");
    setDisableNext(true);
    setTextNext(t("signup_blank"));
    setSignupStep("PickTournament");
  }
  function showNoTournament() {
    setDisableNext(true);
    setTextNext(t("signup_blank"));
    setBgNext("#FFF");
    setSignupStep("NoTournament");
  }
  function showPickTeam() {
    setDisableNext(true);
    setTextNext(t("signup_blank"));
    setBgNext("#FFF");
    setSignupStep("PickTeam");
  }
  function showPickPersona() {
    setDisableNext(true);
    setTextNext(t("signup_blank"));
    setBgNext("#FFF");
    setSignupStep("PickPersona");
  }
  function showAddContact() {
    setDisableNext(false);
    setTextNext(t("signup_next"));
    setBgNext("#4a7830");
    setSignupStep("AddContactDetails");
  }
  function showAddTeam() {
    setDisableNext(false);
    setTextNext(t("signup_next"));
    setBgNext("#4a7830");
    setSignupStep("AddTeamDetails");
  }
  function showConfirmWaivers() {
    setDisableNext(false);
    setTextNext(t("signup_submit"));
    setBgNext("#4a7830");
    setSignupStep("ConfirmWaivers");
  }
  function showConfirmation() {
    setDisableNext(true);
    setDisableReset(true);
    setBgNext("#FFF");
    setTextNext(t("signup_blank"));
    setTextReset(t("signup_blank"));
    setSignupStep("Confirmation");
  }

  const handleChange = async (event, newValue) => {
    if (newValue === "reset") {
      switch (signupStep) {
        case "AddContactDetails":
          setValues({
            program: values.program,
            persona: "",
            first_name: "",
            last_name: "",
            team_name: "",
            team_id: "",
            callcode: "",
            email: "",
            email2: "",
            mobile: "",
            globalgoal: "",
            preferred_language: "",
            team_sport: "",
          });
          showPickPersona();
          break;
        case "PickTeam":
          setValues({
            program: values.program,
            persona: values.persona,
            first_name: values.first_name,
            last_name: values.last_name,
            team_name: "",
            team_id: "",
            callcode: "",
            email: values.email,
            email2: values.email2,
            mobile: "",
            globalgoal: "",
            preferred_language: "",
            team_sport: "",
          });
          showAddContact();
          break;
          case "AddTeamDetails":
            setValues({
              program: values.program,
              persona: values.persona,
              first_name: values.first_name,
              last_name: values.last_name,
              team_name: "",
              team_id: "",
              callcode: "",
              email: values.email,
              email2: values.email2,
              mobile: "",
              globalgoal: "",
              preferred_language: "",
              team_sport: "",
            });
            showAddContact();
            break;
            case "ConfirmWaivers":
            setValues({
              program: values.program,
              persona: values.persona,
              first_name: values.first_name,
              last_name: values.last_name,
              team_name: values.team_name,
              team_id: values.team_id,
              callcode: "",
              email: values.email,
              email2: values.email2,
              mobile: "",
              globalgoal: values.globalgoal,
              preferred_language: values.preferred_language,
              team_sport: values.team_sport,
            });
            if (values.team_id) {
              showPickTeam();
            } else {
              showAddTeam();
            }
            break;
        // code 200
        default:
          //NoTournament
          //PickPersona
          setBgNext("#FFF");
          setDisableNext(true);
          setTextNext(t("signup_blank"));
          setColorNext("#FFF");
          setSignupStep("Intro");
          setCheckAcknowledge(false);
          setCheckWaiver(false);
          setShowErrorMandatory(false);
          setShowErrorEmailMismatch(false);
          setShowErrorEmailDublicate(false);
          setShowErrorDublicateTeamName(false);
          setValues({
            program: "",
            persona: "",
            first_name: "",
            last_name: "",
            team_name: "",
            team_id: "",
            callcode: "",
            email: "",
            email2: "",
            mobile: "",
            globalgoal: "",
            preferred_language: "",
            team_sport: "",
          });
      }
    }
    if (newValue === "next") {
      if (signupStep === "Intro") {
        if (activeTournaments.length > 0) {
          showPickTournament();
        } else {
          showNoTournament();
        }
      }
      //Tournament
      if (signupStep === "PickTournament") {
      }
      //Contact
      if (signupStep === "AddContactDetails") {
        setShowErrorMandatory(false);
        setShowErrorEmailMismatch(false);
        setShowErrorEmailDublicate(false);

        if (values.first_name.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.last_name.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.email.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.email2.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.email !== values.email2) {
          setShowErrorEmailMismatch(true);
          return;
        }
        var stateEmail = await checkEmail(values.email);
        if (stateEmail.code == 200) {
          setShowErrorEmailDublicate(true);
          return;
        }
        if (values.persona === "Player") {
          showPickTeam();
        } else {
          showAddTeam();
        }
      }
      //Team
      if (signupStep === "AddTeamDetails") {
        setShowErrorDublicateTeamName(false);
        setShowErrorMandatory(false);
        if (values.team_name.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.globalgoal.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.preferred_language.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (values.team_sport.length === 0) {
          setShowErrorMandatory(true);
          return;
        }
        if (isTeamNameDublicate(values.team_name)) {
          setShowErrorDublicateTeamName(true);
          return;
        }
        showConfirmWaivers();
      }
      //waivers & acknowledgements
      if (signupStep === "ConfirmWaivers") {
        setShowErrorMandatory(false);
        if (!checkAcknowledge) {
          setShowErrorMandatory(true);
          return;
        }
        if (!checkWaiver) {
          setShowErrorMandatory(true);
          return;
        }
        doSubmit();
      }
    }
    if (newValue === "cancel") {
      window.location.replace("https://ggwcup.com");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <PublicLayout>
      <Container maxWidth="lg">
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          <TabContext value={signupStep}>
            <TabPanel value="Intro">
              <FormControl size="small" sx={{ pt: 4, pb: 1, minWidth: 100 }}>
                <Select
                  labelId="language-simple-select-label"
                  id="i18n1"
                  name="i18n1"
                  value={language || "en"}
                  onChange={handleLanguageChange}
                >
                  <MenuItem key="1" value="en">
                    English
                  </MenuItem>
                  <MenuItem key="3" value="arab">
                    عربي
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_welcome")}
              </Typography>
              {/*               <Typography sx={{ pt: 1, pb: 2 }} variant="body2">
                {t("signup_intro")}
              </Typography>
              <Typography sx={{ pt: 0, pb: 2 }} variant="body2">
                {t("signup_subintro")}
              </Typography>
 */}{" "}
              <Typography sx={{ pt: 1, pb: 2 }} variant="h6">
                {t("signup_tournament")}
              </Typography>
              {activeTournaments.map((row) => (
                <Box
                  id={row.sfid}
                  sx={{
                    height: "75px",
                    backgroundColor: "#" + row.eir_program_colour__c,
                    flexGrow: 1,
                  }}
                  onClick={() =>
                    selectTournament(row.sfid, row.name, row.program_sports__c)
                  }
                >
                  <WhiteTypography sx={{ pt: 3, pb: 3 }} variant="h6">
                    {row.name}
                  </WhiteTypography>
                </Box>
              ))}
            </TabPanel>
            <TabPanel value="PickTournament">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_tournament")}
              </Typography>
              {activeTournaments.map((row) => (
                <Box
                  id={row.sfid}
                  sx={{
                    height: "75px",
                    backgroundColor: "#" + row.eir_program_colour__c,
                    flexGrow: 1,
                  }}
                  onClick={() => selectTournament(row.sfid, row.name)}
                >
                  <WhiteTypography sx={{ pt: 3, pb: 3 }} variant="h6">
                    {row.name}
                  </WhiteTypography>
                </Box>
              ))}
            </TabPanel>
            <TabPanel value="NoTournament">
              {" "}
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_notournament")}
              </Typography>
            </TabPanel>
            <TabPanel value="PickPersona">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_selected_program")} {programName}
              </Typography>
              <Box
                id="persona2"
                sx={{
                  height: "100px",
                  flexGrow: 1,
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectPersona("Captain")}
              >
                <Typography sx={{ pt: 3, pb: 0 }} variant="h6">
                  {t("signup_captain_headline")}
                </Typography>
                <Typography variant="body2">
                  {t("signup_captain_subtext")}
                </Typography>
              </Box>
              <Box
                id="persona3"
                sx={{
                  height: "100px",
                  flexGrow: 1,
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectPersona("Player")}
              >
                <Typography sx={{ pt: 3, pb: 0 }} variant="h6">
                  {t("signup_player_headline")}
                </Typography>
                <Typography variant="body2">
                  {t("signup_player_subtext")}
                </Typography>
              </Box>
              <Box
                id="persona4"
                sx={{
                  height: "100px",
                  flexGrow: 1,
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectPersona("User")}
              >
                <Typography sx={{ pt: 3, pb: 0 }} variant="h6">
                  {t("signup_user_headline")}
                </Typography>
                <Typography variant="body2">
                  {t("signup_user_subtext")}
                </Typography>
              </Box>
              <Box
                id="persona1"
                sx={{
                  height: "100px",
                  flexGrow: 1,
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectPersona("Admin")}
              >
                <Typography sx={{ pt: 3, pb: 0 }} variant="body1">
                  {t("signup_admin_headline")}
                </Typography>
                <Typography variant="body2">
                  {t("signup_admin_subtext")}
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value="AddContactDetails">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_selected_program")} {programName}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("signup_contact_headline")}
              </Typography>
              <NiceTextField
                required
                id="first_name"
                label={t("signup_firstname")}
                name="first_name"
                value={values.first_name || ""}
                onChange={handleInputChange}
              />
              <NiceTextField
                required
                id="last_name"
                label={t("signup_lastname")}
                name="last_name"
                value={values.last_name || ""}
                onChange={handleInputChange}
              />
              <NiceTextField
                required
                id="email"
                label={t("signup_email")}
                name="email"
                value={values.email || ""}
                onChange={handleInputChange}
              />
              <NiceTextField
                required
                id="email2"
                label={t("signup_email_verify")}
                name="email2"
                value={values.email2 || ""}
                onChange={handleInputChange}
              />
              {/*               <NiceSelectFormControl fullWidth>
                <InputLabel id="country-simple-select-label">
                  {t("signup_country")}
                </InputLabel>
                <Select
                  align="left"
                  labelId="country-simple-select-label"
                  id="callcode"
                  label={t("signup_country")}
                  name="callcode"
                  value={values.callcode || ""}
                  onChange={handleInputChange}
                >
                  {countries.map((row) => (
                    <MenuItem key={row.sfid} value={row.call_code__c}>
                      {row.name} (+{row.call_code__c})
                    </MenuItem>
                  ))}
                </Select>
              </NiceSelectFormControl>
              <NiceTextField
                id="mobile"
                label={t("signup_mobile")}
                name="mobile"
                value={values.mobile || ""}
                onChange={handleInputChange}
              />
 */}{" "}
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorEmailMismatch ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 1, pb: 2 }} variant="body2">
                    {t("error_email_mishmatch")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorEmailDublicate ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 1, pb: 1 }} variant="body2">
                    {t("error_email_dublicate")}
                  </WhiteTypography>
                  <Link rel="noopener" href="/gate">
                    {t("signup_gotogate")}
                  </Link>
                </Box>
              ) : (
                <div />
              )}
            </TabPanel>
            <TabPanel value="PickTeam">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_selected_program")} {programName}
              </Typography>
              <Typography sx={{ pt: 2, pb: 2 }} variant="body1">
                {t("signup_pickteam")}
              </Typography>
              <Typography sx={{ pt: 1, pb: 2 }} variant="body1">
                {t("signup_active_teams")}
              </Typography>
              {activeTeams.map((row) => (
                <Box
                  id={row.id}
                  sx={{
                    height: "50px",
                    flexGrow: 1,
                    border: 1,
                    mb: 2,
                  }}
                  onClick={() => selectTeam(row.sfid)}
                >
                  <Typography sx={{ pt: 2, pb: 1 }} variant="body1">
                    {row.name}
                  </Typography>
                </Box>
              ))}
              <Box
                id="waitingforteam"
                sx={{
                  height: "80px",
                  backgroundColor: "#c5c5c5",
                  flexGrow: 1,
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectTeam("waitingforteam")}
              >
                <Typography sx={{ pt: 2, pb: 1 }} variant="body1">
                  {t("signup_waiting_for_team")}
                </Typography>
              </Box>
              <Box
                id="floatingteam"
                sx={{
                  height: "80px",
                  flexGrow: 1,
                  backgroundColor: "#c5c5c5",
                  border: 1,
                  mb: 2,
                }}
                onClick={() => selectTeam("floatingteam")}
              >
                <Typography sx={{ pt: 2, pb: 1 }} variant="body1">
                  {t("signup_floating_team")}
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value="AddTeamDetails">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_selected_program")} {programName}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("signup_team_headline")}
              </Typography>
              <NiceTextField
                id="team_name"
                required
                label={t("signup_team")}
                name="team_name"
                value={values.team_name || ""}
                onChange={handleInputChange}
              />
              <NiceSelectFormControl fullWidth>
                <InputLabel id="gg-simple-select-label">
                  {t("signup_globalgoal")} *
                </InputLabel>
                <Select
                  align="left"
                  required
                  labelId="gg-simple-select-label"
                  id="globalgoal"
                  label={t("signup_globalgoal")}
                  name="globalgoal"
                  value={values.globalgoal || ""}
                  onChange={handleInputChange}
                >
                  <MenuItem key="1" value={"a0u090000010VD5AAM"}>
                    Global Goal 1 - No Poverty
                  </MenuItem>
                  <MenuItem key="2" value={"a0u090000010VDAAA2"}>
                    Global Goal 2 - Zero Hunger
                  </MenuItem>
                  <MenuItem key="3" value={"a0u090000010VyvAAE"}>
                    Global Goal 3 - Good Health and Well-being
                  </MenuItem>
                  <MenuItem key="4" value={"a0u090000010VyhAAE"}>
                    Global Goal 4 - Quality Education
                  </MenuItem>
                  <MenuItem key="5" value={"a0u090000010Vz1AAE"}>
                    Global Goal 5 - Gender Equality
                  </MenuItem>
                  <MenuItem key="6" value={"a0u090000010VyiAAE"}>
                    Global Goal 6 - Clean Water and Sanitation
                  </MenuItem>
                  <MenuItem key="7" value={"a0u090000010Vz5AAE"}>
                    Global Goal 7 - Affordable and Clean Energy
                  </MenuItem>
                  <MenuItem key="8" value={"a0u090000010VzAAAU"}>
                    Global Goal 8 - Decent Work and Economic Growth
                  </MenuItem>
                  <MenuItem key="9" value={"a0u090000010Vz6AAE"}>
                    Global Goal 9 - Industry, Innovation and Infrastructure
                  </MenuItem>
                  <MenuItem key="10" value={"a0u090000010VzKAAU"}>
                    Global Goal 10 - Reduced Inequalities
                  </MenuItem>
                  <MenuItem key="11" value={"a0u090000010VzLAAU"}>
                    Global Goal 11 - Sustainable Cities and Communities
                  </MenuItem>
                  <MenuItem key="12" value={"a0u090000010VzBAAU"}>
                    Global Goal 12 - Responsible Consumption and Production
                  </MenuItem>
                  <MenuItem key="13" value={"a0u090000010VzPAAU"}>
                    Global Goal 13 - Climate Action
                  </MenuItem>
                  <MenuItem key="14" value={"a0u090000010VymAAE"}>
                    Global Goal 14 - Life Below Water
                  </MenuItem>
                  <MenuItem key="15" value={"a0u090000010VzUAAU"}>
                    Global Goal 15 - Life On Land
                  </MenuItem>
                  <MenuItem key="16" value={"a0u090000010VzZAAU"}>
                    Global Goal 16 - Peace, Justice and Strong Institutions
                  </MenuItem>
                  <MenuItem key="17" value={"a0u090000010VywAAE"}>
                    Global Goal 17 - Partnership For The Goals
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="language-simple-select-label">
                  {t("signup_language")} *
                </InputLabel>
                <Select
                  align="left"
                  required
                  labelId="language-simple-select-label"
                  id="preferred_language"
                  label={t("signup_language")}
                  name="preferred_language"
                  value={values.preferred_language || ""}
                  onChange={handleInputChange}
                >
                  <MenuItem key="100" value={"English"}>
                    English
                  </MenuItem>
                  <MenuItem key="101" value={"Arabic"}>
                    عربي
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              {showProgramSports ? (
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="sport-simple-select-label">
                    {t("signup_sport")} *
                  </InputLabel>
                  <Select
                    align="left"
                    required
                    labelId="sport-simple-select-label"
                    id="team_sport"
                    label={t("signup_sport")}
                    name="team_sport"
                    value={values.team_sport || ""}
                    onChange={handleInputChange}
                  >
                    {programSports.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </NiceSelectFormControl>
              ) : (
                <div />
              )}
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_feilds_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
              {showErrorDublicateTeamName ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 1, pb: 2 }} variant="body2">
                    {t("error_team_dublicate")}
                    {programName}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
            </TabPanel>
            <TabPanel value="ConfirmWaivers">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_selected_program")} {programName}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("signup_concent")}
              </Typography>
              <NiceCheckboxFormControl
                align="left"
                sx={{ mb: 0, pb: 0 }}
                control={
                  <Checkbox
                    id="acknowledge"
                    checked={checkAcknowledge}
                    onChange={handleChangeAcknowledge}
                    name="acknowledge"
                  />
                }
                label={t("signup_acknowledge")}
              />
              <NiceCheckboxFormControl
                sx={{ mt: 0, pt: 0 }}
                control={
                  <Checkbox
                    id="waiver"
                    checked={checkWaiver}
                    onChange={handleChangeWaiver}
                    name="waiver"
                  />
                }
                label={
                  <Link target="_blank" rel="noopener" href={waiverURL}>
                    {t("signup_concent")}
                  </Link>
                }
              />
              {showErrorMandatory ? (
                <Box
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    flexGrow: 1,
                  }}
                >
                  <WhiteTypography sx={{ pt: 2, pb: 2 }} variant="body2">
                    {t("error_boxes_mandatory")}
                  </WhiteTypography>
                </Box>
              ) : (
                <div />
              )}
            </TabPanel>
            <TabPanel value="Confirmation">
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("signup_thank_you")} {programName}
              </Typography>
              <Typography sx={{ pt: 3, pb: 3 }} variant="body1">
                {t("signup_remind")}
              </Typography>
            </TabPanel>
          </TabContext>
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              value={signupStep}
              showLabels
              onChange={handleChange}
            >
              <BottomNavigationAction
                disabled={disableReset}
                label={textReset}
                value="reset"
              />
              <BottomNavigationAction
                disabled={disableNext}
                label={textNext}
                sx={{ bgcolor: bgNext, color: colorNext }}
                value="next"
              />
              <BottomNavigationAction
                label={t("signup_cancel")}
                value="cancel"
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default SignUp;
