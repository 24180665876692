import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const BottomBanner = () => {
  const eirProgram = useSelector((state) => state.program);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography sx={{ p: 1, m: 1, pb: 0 }} variant="body1">
        {eirProgram.Program_hashtags__c}
      </Typography>
    </Box>
  );
};

export default BottomBanner;
