export const TRANSLATIONS_EN = {
  signup_welcome: "Welcome to the Global Goals World Cup team sign up",
  signup_intro: "You are about to sign up a team to play in a GGWCUP.",
  signup_subintro:
    "Your team will join a global network of women playing for a sustainable and a more just future for all.",
  signup_tournament: "Choose tournament",
  signup_pickteam: "Join your team",
  signup_active_teams: "Active teams (so far)",
  signup_waiting_for_team:
    "I don's see my team yet. I'll check my inbox for more information.",
  signup_floating_team: "I like to sign up as player and play for any team.",
  signup_globalgoal: "Choose Global Goal (you can change later)",
  signup_firstname: "Your first name",
  signup_lastname: "Your last name",
  signup_email: "Your email address",
  signup_country: "Your call code",
  signup_email_verify: "Re-enter your email address",
  signup_mobile: "Your mobile number",
  signup_team: "Your team name? (you can change later)",
  signup_acknowledge:
    "You know that the tournament is for women players/teams only.  ",
  signup_concent: "Consent and Liability Waiver ",
  signup_remind: "We just sent you an email confirmation. ",
  signup_submit: "SUBMIT",
  signup_language: "Choose language",
  signup_notournament: "There is currently no tournaments open for signup",
  signup_visitggwcup: "Visit GGWCUP homepage",
  signup_reset: "BACK",
  signup_start: "START",
  signup_next: "NEXT",
  signup_blank: "",
  signup_cancel: "CLOSE",
  signup_contact_headline: "Contact details",
  signup_team_headline: "Team details",
  signup_admin_headline: "Want to sign up on behalf of a team?",
  signup_admin_subtext: "Click here to continue.",
  signup_captain_headline: "Team Captain",
  signup_captain_subtext: "I'm will be the main contact for the team",
  signup_player_headline: "Player",
  signup_player_subtext: "I want to join a team",
  signup_user_headline: "Already played in a GGWCUP?",
  signup_user_subtext: "Login and continue",
  signup_selected_program: "Signing up for",
  signup_thank_you: "Thank you for signing up to",
  signup_gotogate: "Goto login",
  signup_sport: "Choose sport",

  login_welcome: "Welcome to EIR member app",
  login_intro: "You are about to log into the GGWCUP APP.",
  login_subintro:
    "Your will maybe be presented with a list of programs that you are engaged in and also on occasion being asked to refresh your password.",
  login_reset: "RESET",
  login_start: "START",
  login_next: "NEXT",
  login_blank: "",
  login_cancel: "CLOSE",
  login_email_headline: "Please provide your email address",
  login_password_headline: "Please provide your password",
  login_email: "Your email address",
  login_magiccode: "Your magic code",
  login_magiccode_headline: "Please provide the code from the email you got",
  login_resend_magic: "Resend the email with a new magic code",
  login_magic_sent: "Look in your inbox for some magic",
  login_return_to_magic: "Return to input the magic",
  login_password: "Your new password",
  login_password_verify: "Re-enter your password",
  login_resetpassword_headline: "Set your new password",

  error_password_mishmatch: "Your passwords does not match",
  error_password_weak: "Your password should be minimum 10 characters long",
  error_feilds_mandatory: "All * marked fields are mandatory",
  error_boxes_mandatory: "Please check all boxes",
  error_email_mishmatch:
    "Hmmm, something is not right. Please type your email again. ",
  error_email_dublicate:
    "Your email is already registered as an active member, please login to continue.",
  error_team_dublicate:
    "Another Team Captain already signed up a team with this name for ",
  error_email_wrong: "The email is not known",
  error_password_wrong: "The password is wrong",
  Login_tournament: "Select which program to see the APP through",
  login_app_blocked: "Something is not right!",
  login_app_blocked_info: "Please contact EIR for further info",
  error_magic_100: "The magic code is wrong",
  error_magic_101: "The magic has expired",
  login_magic_information_headline: "Request new magic",
  login_magic_information:
    "As part of our efforts to safeguard our APP we ask you to reset your password by requesting a new magic code",

  public_blank: "",
  public_switch: "Switch",
  public_welcome: "Welcome to the League App",
  public_tournament: "Choose league",
  public_notournament: "There is currently no league available",
  public_schedule: "Schedule",
  public_details: "Actions",
  public_ranking: "Ranking",
  public_bio: "About the Team",
  public_gg: "Which of the 17 Global Goals are most important for the Team?",
  public_why: "Why is the Global Goal important to the Team?",
  public_how: "How the Team take action for the Global Goal?",
  public_success: "How will the Team know its success?",
  public_objective: "What is most important for the Team this season?",
  public_ranking_intro: "Each team will score points in the categories: ",
  public_game_point: "F - Football Game",
  public_game_point_text: "Winning team will get 3 points. Loosing team will get 1 point. Both teams will get 2 points when a tie. Each football match is 15 minutes long.",
  public_style_point: "S - Style",
  public_style_point_text: "Given based on the team’s creative power and how the Global Goal is integrated with the team uniform. Teams can score 1 to 5 points. ",
  public_followers_point: "F - Followers ",
  public_followers_point_text: "In the Action Reports you will share the number of your followers. Teams can score 1 to 5 points.",
  public_crowd_point: "C - Crowd ",
  public_crowd_point_text: "Rewards the team’s effort on social media when sharing about: Game days, trainings and activities for the Global Goal. You can score 1, 3 or 5 points. (This point wil also include auduence).",
  public_spirit_point: "P - Play Spirit",
  public_spirit_point_text: "Rewards the play activist team efforts and attitude on game day. Teams can score 1 to 5 points.",
  public_action_point: "A - Action",
  public_action_point_text: "Rewards the team’s Global Goal activities taken in between game days. To capture this, each team captain will fill out a bi-weekly Action Report. Teams can score 1 to 5 points.",
  public_action_point_link:"More point system here",
  public_game_day_filter: "Game day",
  public_team_filter: "Team",
  public_ar_program: "Program Activities",
  public_ar_team: "Team Activities",
  public_ar_hours: "Hours trained",
  public_ar_observers: "Observers/Attendance",
  public_ar_actions: "Community actions",
  public_ar_people: "Potential reach"
};
