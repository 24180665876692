import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const validationSchemaEvent = yup.object({
  AQ0001: yup.number("Enter a number").required("Is required"),
  AQ0002: yup.boolean().required(),
  AQ0003: yup
    .number()
    .when("AQ0002", { is: true, then: yup.number().required() }),
  AQ0004: yup
    .number()
    .when("AQ0002", { is: true, then: yup.number().required() }),
  AQ0005: yup
    .boolean()
    .when("AQ0002", { is: true, then: yup.boolean().required() }),
  AQ0006: yup
    .boolean()
    .when("AQ0002", { is: true, then: yup.boolean().required() }),
  AQ0007: yup
    .number()
    .when("AQ0002", { is: true, then: yup.number().required() }),
  AQ0008: yup
    .string()
    .when("AQ0002", { is: true, then: yup.string().required() }),
  AQ0010: yup
    .number()
    .when("AQ0002", { is: true, then: yup.number().required() }),
  AQ0011: yup
    .number()
    .when("AQ0002", { is: true, then: yup.number().required() }),
  AQ0012: yup.boolean().required(),
  AQ0013: yup.boolean().required(),
  AQ0014: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0015: yup
    .string()
    .when("AQ0013", { is: true, then: yup.string().required() }),
  AQ0017: yup
    .string()
    .when("AQ0013", { is: true, then: yup.string().required() }),
  AQ0018: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0019: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0020: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0021: yup.number().required(),
});

function WeeklyReportCoach() {
  let navigate = useNavigate();
  const [todoAR, setTodoAR] = useState([]);
  const [themes, setThemes] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [themesAttack, setThemesAttack] = useState([]);
  const [themesDefend, setThemesDefend] = useState([]);

  const [searchParams] = useSearchParams();
  const theARId = searchParams.get("id");

  const GetTodo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
        theARId,
      requestOptions
    );
    const data = await response.json();
    try {
      setTodoAR(data);
    } catch (err) {
      console.log(err);
    }
  };

  function splitThemes(rawData) {
    var attackData = rawData;
    attackData = attackData.reduce((filterItems, item) => {
      if (item.Grouping__c === "Attacking - ”Creating”") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setThemesAttack(attackData);

    var defendData = rawData;
    defendData = defendData.reduce((filterItems, item) => {
      if (item.Grouping__c === "Defending – ”Resolving”") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setThemesDefend(defendData);
  }

  const FetcThemes = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Grouping__c";
    sqol = sqol + ", Sequence__c";
    sqol = sqol + " from Theme__c";
    sqol = sqol + " ORDER BY Grouping__c ASC, Sequence__c ASC";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      setThemes(data.records);
      splitThemes(data.records);
    } catch (err) {
      console.log(err);
    }
  };

  function lookupTheme(theId) {
    for (let x in themes) {
      if (themes[x].Id === theId) {
        return themes[x].Name;
      }
    }
    return null;
  }

  function lookupGG(theId) {
    if (theId === "a0u090000010VD5AAM") {
      return "Global Goal 1 - No Poverty";
    }
    if (theId === "a0u090000010VDAAA2") {
      return "Global Goal 2 - Zero Hunger";
    }
    if (theId === "a0u090000010VyvAAE") {
      return "Global Goal 3 - Good Health and Well-being";
    }
    if (theId === "a0u090000010VyhAAE") {
      return "Global Goal 4 - Quality Education";
    }
    if (theId === "a0u090000010Vz1AAE") {
      return "Global Goal 5 - Gender Equality";
    }
    if (theId === "a0u090000010VyiAAE") {
      return "Global Goal 6 - Clean Water and Sanitation";
    }
    if (theId === "a0u090000010Vz5AAE") {
      return "Global Goal 7 - Affordable and Clean Energy";
    }
    if (theId === "a0u090000010VzAAAU") {
      return "Global Goal 8 - Decent Work and Economic Growth";
    }
    if (theId === "a0u090000010Vz6AAE") {
      return "Global Goal 9 - Industry, Innovation and Infrastructure";
    }
    if (theId === "a0u090000010VzKAAU") {
      return "Global Goal 10 - Reduced Inequalities";
    }
    if (theId === "a0u090000010VzLAAU") {
      return "Global Goal 11 - Sustainable Cities and Communities";
    }
    if (theId === "a0u090000010VzBAAU") {
      return "Global Goal 12 - Responsible Consumption and Production";
    }
    if (theId === "a0u090000010VzPAAU") {
      return "Global Goal 13 - Climate Action";
    }
    if (theId === "a0u090000010VymAAE") {
      return "Global Goal 14 - Life Below Water";
    }
    if (theId === "a0u090000010VzUAAU") {
      return "Global Goal 15 - Life On Land";
    }
    if (theId === "a0u090000010VzZAAU") {
      return "Global Goal 16 - Peace, Justice and Strong Institutions";
    }
    if (theId === "a0u090000010VywAAE") {
      return "Global Goal 17 - Partnership For The Goals";
    }
    return null;
  }

  useEffect(() => {
    GetTodo();
    FetcThemes();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const sfdemouser = sessionStorage.getItem("demoUser");

  const SaveResponse = async (theValue, theDisplay, theQuestion, theType) => {
    var theJSON = {
      Display_Value__c: theDisplay,
      EIR_Action_Question__c: theQuestion,
      EIR_Action_Todo__c: todoAR.Id,
      App_User__c: todoAR.App_Data_Manager__c,
    };

    if (theType === "Boolean") {
      if (theValue === "True") {
        theJSON.Value_as_Boolean__c = true;
      } else {
        theJSON.Value_as_Boolean__c = false;
      }
    }
    if (theType === "String") {
      theJSON.Value_as_String__c = theValue;
    }
    if (theType === "Number") {
      theJSON.Value_as_Number__c = theValue;
    }
    if (theType === "Id") {
      theJSON.Value_as_ID__c = theValue;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Response__c/",
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      //toast("Response created");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateTodoAR = async () => {
    var theJSON = {
      Submit_Date__c: today,
      State__c: "Submitted",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(theJSON, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
        todoAR.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Action Report submited"))
      .then((result) => navigate("/home"))
      .catch((error) => console.log("error", error));
  };

  const today = new Date();

  const formikEvent = useFormik({
    initialValues: {
      AQ0001: "",
      AQ0002: "",
      AQ0003: "",
      AQ0004: "",
      AQ0005: "",
      AQ0006: "",
      AQ0007: "",
      AQ0008: "",
      AQ0009: "",
      AQ0010: "",
      AQ0011: "",
      AQ0012: "",
      AQ0013: "",
      AQ0014: "",
      AQ0015: "",
      AQ0016: "",
      AQ0017: "",
      AQ0018: "",
      AQ0019: "",
      AQ0020: "",
      AQ0021: "",
      AQ0022: "",
    },
    validationSchema: validationSchemaEvent,
    onSubmit: (values) => {
      setSubmited(true);
      //alert(JSON.stringify(values, null, 2));
      SaveResponse(
        values.AQ0001,
        values.AQ0001,
        "a2D090000004OVDEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0002,
        values.AQ0002,
        "a2D090000004OVIEA2",
        "Boolean"
      );
      if (values.AQ0002 === "True") {
        SaveResponse(
          values.AQ0003,
          values.AQ0003,
          "a2D090000004OVEEA2",
          "Number"
        );
        SaveResponse(
          values.AQ0004,
          values.AQ0004,
          "a2D090000004OVNEA2",
          "Number"
        );
        SaveResponse(
          values.AQ0005,
          values.AQ0005,
          "a2D090000004OVOEA2",
          "Boolean"
        );
        SaveResponse(
          values.AQ0006,
          values.AQ0006,
          "a2D090000004OVSEA2",
          "Boolean"
        );
        SaveResponse(
          values.AQ0007,
          values.AQ0007,
          "a2D090000004OVTEA2",
          "Number"
        );
        SaveResponse(
          values.AQ0008,
          lookupTheme(values.AQ0008),
          "a2D090000004OVFEA2",
          "Id"
        );
        if (values.AQ0009 !== "") {
          SaveResponse(
            values.AQ0009,
            lookupTheme(values.AQ0009),
            "a2D090000004OVGEA2",
            "Id"
          );
        }
        SaveResponse(
          values.AQ0010,
          values.AQ0010,
          "a2D090000004OVUEA2",
          "Number"
        );
        SaveResponse(
          values.AQ0011,
          values.AQ0011,
          "a2D090000004OVHEA2",
          "Number"
        );
      }
      SaveResponse(
        values.AQ0012,
        values.AQ0012,
        "a2D090000004OVXEA2",
        "Boolean"
      );
      SaveResponse(
        values.AQ0013,
        values.AQ0013,
        "a2D090000004OVYEA2",
        "Boolean"
      );
      if (values.AQ0013 === "True") {
        SaveResponse(
          values.AQ0014,
          values.AQ0014,
          "a2D090000004OVcEAM",
          "Number"
        );
        SaveResponse(
          values.AQ0015,
          lookupGG(values.AQ0015),
          "a2D090000004OVVEA2",
          "Id"
        );
        if (values.AQ0016 !== "") {
          SaveResponse(
            values.AQ0016,
            lookupGG(values.AQ0016),
            "a2D090000004OVdEAM",
            "Id"
          );
        }
        SaveResponse(
          values.AQ0017,
          values.AQ0017,
          "a2D090000004OVPEA2",
          "String"
        );
        SaveResponse(
          values.AQ0018,
          values.AQ0018,
          "a2D090000004OVhEAM",
          "Number"
        );
        SaveResponse(
          values.AQ0019,
          values.AQ0019,
          "a2D090000004OVmEAM",
          "Number"
        );
        SaveResponse(
          values.AQ0020,
          values.AQ0020,
          "a2D090000004OVrEAM",
          "Number"
        );
      }
      SaveResponse(
        values.AQ0021,
        values.AQ0021,
        "a2D090000004OVsEAM",
        "Number"
      );
      if (values.AQ0022 !== "") {
        SaveResponse(
          values.AQ0022,
          values.AQ0022,
          "a2D090000004OVwEAM",
          "String"
        );
      }
      UpdateTodoAR();
    },
  });

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form onSubmit={formikEvent.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h4"
                  component="div"
                >
                  ACTION REPORT
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Time to track and add your event to the data pool. All data
                  will be displayed on the dashboard in the app.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  This should take no more than 5 to 10 minutes to complete.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Thank you.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  Title: {todoAR.Title__c}
                </Typography>
                {/* AQ0001 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q1: How many people did your coaching group reach via social
                  media this past week?
                </Typography>
                <NiceTextField
                  id="AQ0001"
                  label="Number of people"
                  name="AQ0001"
                  placeholder="Please include posts about both training and Global Goal activities. If you did not do any posts this past week, please write 0."
                  value={formikEvent.values.AQ0001 || ""}
                  onChange={formikEvent.handleChange}
                  disabled={submited}
                  error={
                    formikEvent.touched.AQ0001 &&
                    Boolean(formikEvent.errors.AQ0001)
                  }
                  helperText={
                    formikEvent.touched.AQ0001 && formikEvent.errors.AQ0001
                  }
                />
                {/* AQ0002 - Boolean */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q2: Did you do any training sessions this past week?
                </Typography>

                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0002-select-label">Yes or No</InputLabel>
                  <Select
                    labelId="AQ0002-select-label"
                    id="AQ0002"
                    label="Duration (in minutes)"
                    name="AQ0002"
                    disabled={submited}
                    value={formikEvent.values.AQ0002 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0002 &&
                      Boolean(formikEvent.errors.AQ0002)
                    }
                    helperText={
                      formikEvent.touched.AQ0002 && formikEvent.errors.AQ0002
                    }
                  >
                    <MenuItem key="True" value="True">
                      Yes
                    </MenuItem>
                    <MenuItem key="False" value="False">
                      No
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {formikEvent.values.AQ0002 === "True" ? (
                  <div>
                    {/* AQ0003 - Select - Number - 0-9 */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q3: How many training sessions did your coaching group do
                      in total this past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0003-select-label">Number</InputLabel>
                      <Select
                        labelId="AQ0003-select-label"
                        id="AQ0003"
                        label="Number"
                        name="AQ0003"
                        disabled={submited}
                        value={formikEvent.values.AQ0003 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0003 &&
                          Boolean(formikEvent.errors.AQ0003)
                        }
                        helperText={
                          formikEvent.touched.AQ0003 &&
                          formikEvent.errors.AQ0003
                        }
                      >
                        <MenuItem key="1" value="1">
                          1
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          2
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          3
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          4
                        </MenuItem>
                        <MenuItem key="5" value="5">
                          5
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          6
                        </MenuItem>
                        <MenuItem key="7" value="7">
                          7
                        </MenuItem>
                        <MenuItem key="8" value="8">
                          8
                        </MenuItem>
                        <MenuItem key="9" value="9">
                          9
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0004 - Select - Minutes */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q4: How long time in total did you train with your
                      coaching group this past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0004-select-label">
                        Duration (in minutes)
                      </InputLabel>
                      <Select
                        labelId="AQ0004-select-label"
                        id="AQ0004"
                        label="Duration (in minutes)"
                        name="AQ0004"
                        disabled={submited}
                        value={formikEvent.values.AQ0004 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0004 &&
                          Boolean(formikEvent.errors.AQ0004)
                        }
                        helperText={
                          formikEvent.touched.AQ0004 &&
                          formikEvent.errors.AQ0004
                        }
                      >
                        <MenuItem key="30" value="30">
                          30 minutes
                        </MenuItem>
                        <MenuItem key="60" value="60">
                          1 hour
                        </MenuItem>
                        <MenuItem key="90" value="90">
                          1 hour and 30 minutes
                        </MenuItem>
                        <MenuItem key="120" value="120">
                          2 hours
                        </MenuItem>
                        <MenuItem key="180" value="180">
                          3 hours
                        </MenuItem>
                        <MenuItem key="240" value="240">
                          4 hours
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0005 - Boolean */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q5: Did any of the new coaches lead on one of the
                      activities (take the role of the coach)?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0005-select-label">
                        Yes or No
                      </InputLabel>
                      <Select
                        labelId="AQ0005-select-label"
                        id="AQ0005"
                        label="Yes or No"
                        name="AQ0005"
                        disabled={submited}
                        value={formikEvent.values.AQ0005 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0005 &&
                          Boolean(formikEvent.errors.AQ0005)
                        }
                        helperText={
                          formikEvent.touched.AQ0005 &&
                          formikEvent.errors.AQ0005
                        }
                      >
                        <MenuItem key="True" value="True">
                          Yes
                        </MenuItem>
                        <MenuItem key="False" value="False">
                          No
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0006 - Boolean */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q6: Did you play a game at the end of the training(s)?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0006-select-label">
                        Yes or No
                      </InputLabel>
                      <Select
                        labelId="AQ0006-select-label"
                        id="AQ0006"
                        label="Yes or No"
                        name="AQ0006"
                        disabled={submited}
                        value={formikEvent.values.AQ0006 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0006 &&
                          Boolean(formikEvent.errors.AQ0006)
                        }
                        helperText={
                          formikEvent.touched.AQ0006 &&
                          formikEvent.errors.AQ0006
                        }
                      >
                        <MenuItem key="True" value="True">
                          Yes
                        </MenuItem>
                        <MenuItem key="False" value="False">
                          No
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0007 - Number*/}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q7: How many from outside the program attended the
                      trainings this past week?
                    </Typography>
                    <NiceTextField
                      id="AQ0007-number"
                      label="Number of people"
                      name="AQ0007"
                      placeholder="Include players, coaches and audience/people watching your sessions."
                      value={formikEvent.values.AQ0007 || ""}
                      disabled={submited}
                      onChange={formikEvent.handleChange}
                      error={
                        formikEvent.touched.AQ0007 &&
                        Boolean(formikEvent.errors.AQ0007)
                      }
                      helperText={
                        formikEvent.touched.AQ0007 && formikEvent.errors.AQ0007
                      }
                    />
                    {/* AQ0008 - Select*/}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q8: What was the primary theme you practiced this past
                      week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0008-select-label">Theme</InputLabel>
                      <Select
                        labelId="AQ0008-select-label"
                        id="AQ0008"
                        label="Theme"
                        name="AQ0008"
                        disabled={submited}
                        value={formikEvent.values.AQ0008 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0008 &&
                          Boolean(formikEvent.errors.AQ0008)
                        }
                        helperText={
                          formikEvent.touched.AQ0008 &&
                          formikEvent.errors.AQ0008
                        }
                      >
                        <ListSubheader>Attacking - ”Creating”</ListSubheader>
                        {themesAttack.map((row) => (
                          <MenuItem value={row.Id}>{row.Name}</MenuItem>
                        ))}
                        <ListSubheader>Defending – ”Resolving”</ListSubheader>
                        {themesDefend.map((row) => (
                          <MenuItem value={row.Id}>{row.Name}</MenuItem>
                        ))}
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0009 - Select*/}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q9: Did you train using another theme this past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0009-select-label">Theme</InputLabel>
                      <Select
                        labelId="AQ0009-select-label"
                        id="AQ0009"
                        label="Theme"
                        name="AQ0009"
                        disabled={submited}
                        value={formikEvent.values.AQ0009 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0009 &&
                          Boolean(formikEvent.errors.AQ0009)
                        }
                        helperText={
                          formikEvent.touched.AQ0009 &&
                          formikEvent.errors.AQ0009
                        }
                      >
                        <ListSubheader>Attacking - ”Creating”</ListSubheader>
                        {themesAttack.map((row) => (
                          <MenuItem value={row.Id}>{row.Name}</MenuItem>
                        ))}
                        <ListSubheader>Defending – ”Resolving”</ListSubheader>
                        {themesDefend.map((row) => (
                          <MenuItem value={row.Id}>{row.Name}</MenuItem>
                        ))}
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0010 - Select - NPS - 1-10 */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q10: How would you rate your coaching/ leading of the
                      training sessions in terms of the objectives set as head
                      coach?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0010-select-label">
                        Net Promotor Score
                      </InputLabel>
                      <Select
                        labelId="AQ0010-select-label"
                        id="AQ0010"
                        label="Score"
                        name="AQ0010"
                        disabled={submited}
                        value={formikEvent.values.AQ0010 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0010 &&
                          Boolean(formikEvent.errors.AQ0010)
                        }
                        helperText={
                          formikEvent.touched.AQ0010 &&
                          formikEvent.errors.AQ0010
                        }
                      >
                        <MenuItem key="1" value="1">
                          1 (lowest)
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          2
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          3
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          4
                        </MenuItem>
                        <MenuItem key="5" value="5">
                          5
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          6
                        </MenuItem>
                        <MenuItem key="7" value="7">
                          7
                        </MenuItem>
                        <MenuItem key="8" value="8">
                          8
                        </MenuItem>
                        <MenuItem key="9" value="9">
                          9
                        </MenuItem>
                        <MenuItem key="10" value="10">
                          10 (highest)
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0011 - Select - NPS - 1-10 */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q11: How would you rate the engagement and involvement of
                      your new coaches?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0011-select-label">
                        Net Promotor Score
                      </InputLabel>
                      <Select
                        labelId="AQ0011-select-label"
                        id="AQ0011"
                        label="Score"
                        name="AQ0011"
                        disabled={submited}
                        value={formikEvent.values.AQ0011 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0011 &&
                          Boolean(formikEvent.errors.AQ0011)
                        }
                        helperText={
                          formikEvent.touched.AQ0011 &&
                          formikEvent.errors.AQ0011
                        }
                      >
                        <MenuItem key="1" value="1">
                          1 (lowest)
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          2
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          3
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          4
                        </MenuItem>
                        <MenuItem key="5" value="5">
                          5
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          6
                        </MenuItem>
                        <MenuItem key="7" value="7">
                          7
                        </MenuItem>
                        <MenuItem key="8" value="8">
                          8
                        </MenuItem>
                        <MenuItem key="9" value="9">
                          9
                        </MenuItem>
                        <MenuItem key="10" value="10">
                          10 (highest)
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                  </div>
                ) : (
                  <div>
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      No questions about training sessions!
                    </Typography>
                  </div>
                )}
                {/* AQ0012 - Boolean */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q12: Do you already have the next training scheduled?
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0012-select-label">Yes or No</InputLabel>
                  <Select
                    labelId="AQ0012-select-label"
                    id="AQ0012"
                    label="Yes or No"
                    name="AQ0012"
                    disabled={submited}
                    value={formikEvent.values.AQ0012 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0012 &&
                      Boolean(formikEvent.errors.AQ0012)
                    }
                    helperText={
                      formikEvent.touched.AQ0012 && formikEvent.errors.AQ0012
                    }
                  >
                    <MenuItem key="True" value="True">
                      Yes
                    </MenuItem>
                    <MenuItem key="False" value="False">
                      No
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0013 - Boolean */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q13: Did you do any community actions / Global Goal activities
                  this past week?
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0013-select-label">Yes or No</InputLabel>
                  <Select
                    labelId="AQ0013-select-label"
                    id="AQ0013"
                    label="Yes or No"
                    name="AQ0013"
                    disabled={submited}
                    value={formikEvent.values.AQ0013 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0013 &&
                      Boolean(formikEvent.errors.AQ0013)
                    }
                    helperText={
                      formikEvent.touched.AQ0013 && formikEvent.errors.AQ0013
                    }
                  >
                    <MenuItem key="True" value="True">
                      Yes
                    </MenuItem>
                    <MenuItem key="False" value="False">
                      No
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {formikEvent.values.AQ0013 === "True" ? (
                  <div>
                    {/* AQ0014 - Select - Number - 0-9 */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q14: How many community actions / Global Goal did your
                      coaching group do in total this past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0014-select-label">Number</InputLabel>
                      <Select
                        labelId="AQ0014-select-label"
                        id="AQ0014"
                        label="Number"
                        name="AQ0014"
                        disabled={submited}
                        value={formikEvent.values.AQ0014 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0014 &&
                          Boolean(formikEvent.errors.AQ0014)
                        }
                        helperText={
                          formikEvent.touched.AQ0014 &&
                          formikEvent.errors.AQ0014
                        }
                      >
                        <MenuItem key="1" value="1">
                          1
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          2
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          3
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          4
                        </MenuItem>
                        <MenuItem key="5" value="5">
                          5
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          6
                        </MenuItem>
                        <MenuItem key="7" value="7">
                          7
                        </MenuItem>
                        <MenuItem key="8" value="8">
                          8
                        </MenuItem>
                        <MenuItem key="9" value="9">
                          9
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0015 - Select */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q15: What was the primary Global Goal you activated this
                      past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0015-select-label">
                        Global Goal
                      </InputLabel>
                      <Select
                        labelId="AQ0015-select-label"
                        id="AQ0015"
                        label="Global Goal"
                        name="AQ0015"
                        disabled={submited}
                        value={formikEvent.values.AQ0015 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0015 &&
                          Boolean(formikEvent.errors.AQ0015)
                        }
                        helperText={
                          formikEvent.touched.AQ0015 &&
                          formikEvent.errors.AQ0015
                        }
                      >
                        <MenuItem key="1" value={"a0u090000010VD5AAM"}>
                          Global Goal 1 - No Poverty
                        </MenuItem>
                        <MenuItem key="2" value={"a0u090000010VDAAA2"}>
                          Global Goal 2 - Zero Hunger
                        </MenuItem>
                        <MenuItem key="3" value={"a0u090000010VyvAAE"}>
                          Global Goal 3 - Good Health and Well-being
                        </MenuItem>
                        <MenuItem key="4" value={"a0u090000010VyhAAE"}>
                          Global Goal 4 - Quality Education
                        </MenuItem>
                        <MenuItem key="5" value={"a0u090000010Vz1AAE"}>
                          Global Goal 5 - Gender Equality
                        </MenuItem>
                        <MenuItem key="6" value={"a0u090000010VyiAAE"}>
                          Global Goal 6 - Clean Water and Sanitation
                        </MenuItem>
                        <MenuItem key="7" value={"a0u090000010Vz5AAE"}>
                          Global Goal 7 - Affordable and Clean Energy
                        </MenuItem>
                        <MenuItem key="8" value={"a0u090000010VzAAAU"}>
                          Global Goal 8 - Decent Work and Economic Growth
                        </MenuItem>
                        <MenuItem key="9" value={"a0u090000010Vz6AAE"}>
                          Global Goal 9 - Industry, Innovation and
                          Infrastructure
                        </MenuItem>
                        <MenuItem key="10" value={"a0u090000010VzKAAU"}>
                          Global Goal 10 - Reduced Inequalities
                        </MenuItem>
                        <MenuItem key="11" value={"a0u090000010VzLAAU"}>
                          Global Goal 11 - Sustainable Cities and Communities
                        </MenuItem>
                        <MenuItem key="12" value={"a0u090000010VzBAAU"}>
                          Global Goal 12 - Responsible Consumption and
                          Production
                        </MenuItem>
                        <MenuItem key="13" value={"a0u090000010VzPAAU"}>
                          Global Goal 13 - Climate Action
                        </MenuItem>
                        <MenuItem key="14" value={"a0u090000010VymAAE"}>
                          Global Goal 14 - Life Below Water
                        </MenuItem>
                        <MenuItem key="15" value={"a0u090000010VzUAAU"}>
                          Global Goal 15 - Life On Land
                        </MenuItem>
                        <MenuItem key="16" value={"a0u090000010VzZAAU"}>
                          Global Goal 16 - Peace, Justice and Strong
                          Institutions
                        </MenuItem>
                        <MenuItem key="17" value={"a0u090000010VywAAE"}>
                          Global Goal 17 - Partnership For The Goals
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0016 - Select */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q16: Did you activate another Global Goal this past week?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0016-select-label">
                        Global Goal
                      </InputLabel>
                      <Select
                        labelId="AQ0016-select-label"
                        id="AQ0016"
                        label="Global Goal"
                        name="AQ0016"
                        disabled={submited}
                        value={formikEvent.values.AQ0016 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0016 &&
                          Boolean(formikEvent.errors.AQ0016)
                        }
                        helperText={
                          formikEvent.touched.AQ0016 &&
                          formikEvent.errors.AQ0016
                        }
                      >
                        <MenuItem key="1" value={"a0u090000010VD5AAM"}>
                          Global Goal 1 - No Poverty
                        </MenuItem>
                        <MenuItem key="2" value={"a0u090000010VDAAA2"}>
                          Global Goal 2 - Zero Hunger
                        </MenuItem>
                        <MenuItem key="3" value={"a0u090000010VyvAAE"}>
                          Global Goal 3 - Good Health and Well-being
                        </MenuItem>
                        <MenuItem key="4" value={"a0u090000010VyhAAE"}>
                          Global Goal 4 - Quality Education
                        </MenuItem>
                        <MenuItem key="5" value={"a0u090000010Vz1AAE"}>
                          Global Goal 5 - Gender Equality
                        </MenuItem>
                        <MenuItem key="6" value={"a0u090000010VyiAAE"}>
                          Global Goal 6 - Clean Water and Sanitation
                        </MenuItem>
                        <MenuItem key="7" value={"a0u090000010Vz5AAE"}>
                          Global Goal 7 - Affordable and Clean Energy
                        </MenuItem>
                        <MenuItem key="8" value={"a0u090000010VzAAAU"}>
                          Global Goal 8 - Decent Work and Economic Growth
                        </MenuItem>
                        <MenuItem key="9" value={"a0u090000010Vz6AAE"}>
                          Global Goal 9 - Industry, Innovation and
                          Infrastructure
                        </MenuItem>
                        <MenuItem key="10" value={"a0u090000010VzKAAU"}>
                          Global Goal 10 - Reduced Inequalities
                        </MenuItem>
                        <MenuItem key="11" value={"a0u090000010VzLAAU"}>
                          Global Goal 11 - Sustainable Cities and Communities
                        </MenuItem>
                        <MenuItem key="12" value={"a0u090000010VzBAAU"}>
                          Global Goal 12 - Responsible Consumption and
                          Production
                        </MenuItem>
                        <MenuItem key="13" value={"a0u090000010VzPAAU"}>
                          Global Goal 13 - Climate Action
                        </MenuItem>
                        <MenuItem key="14" value={"a0u090000010VymAAE"}>
                          Global Goal 14 - Life Below Water
                        </MenuItem>
                        <MenuItem key="15" value={"a0u090000010VzUAAU"}>
                          Global Goal 15 - Life On Land
                        </MenuItem>
                        <MenuItem key="16" value={"a0u090000010VzZAAU"}>
                          Global Goal 16 - Peace, Justice and Strong
                          Institutions
                        </MenuItem>
                        <MenuItem key="17" value={"a0u090000010VywAAE"}>
                          Global Goal 17 - Partnership For The Goals
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0017 - Select - Minutes */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q17: Was the event an online or in-person meetup?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0017-select-label">Type</InputLabel>
                      <Select
                        labelId="AQ0017-select-label"
                        id="AQ0017"
                        label="Meeting type"
                        name="AQ0017"
                        disabled={submited}
                        value={formikEvent.values.AQ0017 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0017 &&
                          Boolean(formikEvent.errors.AQ0017)
                        }
                        helperText={
                          formikEvent.touched.AQ0017 &&
                          formikEvent.errors.AQ0017
                        }
                      >
                        <MenuItem key="Online" value="Online">
                          Online
                        </MenuItem>
                        <MenuItem key="In Person" value="In Persen">
                          In Person
                        </MenuItem>
                        <MenuItem key="Both" value="Both">
                          Both
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0018 - Number*/}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q18: How many people in total took part in your community
                      action / Global Goal activity this past week?
                    </Typography>
                    <NiceTextField
                      id="AQ0018-number"
                      label="Number of people"
                      name="AQ0018"
                      disabled={submited}
                      value={formikEvent.values.AQ0018 || ""}
                      onChange={formikEvent.handleChange}
                      error={
                        formikEvent.touched.AQ0018 &&
                        Boolean(formikEvent.errors.AQ0018)
                      }
                      helperText={
                        formikEvent.touched.AQ0018 && formikEvent.errors.AQ0018
                      }
                    />
                    {/* AQ0019 - Select - Minutes */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q19: How long time in total did you and your coaches spend
                      on the event?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0019-select-label">
                        Duration (in minutes)
                      </InputLabel>
                      <Select
                        labelId="AQ0019-select-label"
                        id="AQ0019"
                        label="Duration (in minutes)"
                        name="AQ0019"
                        disabled={submited}
                        value={formikEvent.values.AQ0019 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0019 &&
                          Boolean(formikEvent.errors.AQ0019)
                        }
                        helperText={
                          formikEvent.touched.AQ0019 &&
                          formikEvent.errors.AQ0019
                        }
                      >
                        <MenuItem key="30" value="30">
                          30 minutes
                        </MenuItem>
                        <MenuItem key="60" value="60">
                          1 hour
                        </MenuItem>
                        <MenuItem key="90" value="90">
                          1 hour and 30 minutes
                        </MenuItem>
                        <MenuItem key="120" value="120">
                          2 hours
                        </MenuItem>
                        <MenuItem key="180" value="180">
                          3 hours
                        </MenuItem>
                        <MenuItem key="240" value="240">
                          4 hours
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0020 - Select - NPS - 1-10 */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q20: How would you rate your event?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0020-select-label">
                        Net Promotor Score
                      </InputLabel>
                      <Select
                        labelId="AQ0020-select-label"
                        id="AQ0020"
                        label="Score"
                        name="AQ0020"
                        disabled={submited}
                        value={formikEvent.values.AQ0020 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0020 &&
                          Boolean(formikEvent.errors.AQ0020)
                        }
                        helperText={
                          formikEvent.touched.AQ0020 &&
                          formikEvent.errors.AQ0020
                        }
                      >
                        <MenuItem key="1" value="1">
                          1 (lowest)
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          2
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          3
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          4
                        </MenuItem>
                        <MenuItem key="5" value="5">
                          5
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          6
                        </MenuItem>
                        <MenuItem key="7" value="7">
                          7
                        </MenuItem>
                        <MenuItem key="8" value="8">
                          8
                        </MenuItem>
                        <MenuItem key="9" value="9">
                          9
                        </MenuItem>
                        <MenuItem key="10" value="10">
                          10 (highest)
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                  </div>
                ) : (
                  <div>
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      No questions about action events!
                    </Typography>
                  </div>
                )}
                {/* AQ0021 - Select - NPS - 1-10 */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q21: From your perspective, how likely is it that your Global
                  Goal activity inspires others to also act?
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0021-select-label">
                    Net Promotor Score
                  </InputLabel>
                  <Select
                    labelId="AQ0021-select-label"
                    id="AQ0021"
                    label="Score"
                    name="AQ0021"
                    disabled={submited}
                    value={formikEvent.values.AQ0021 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0021 &&
                      Boolean(formikEvent.errors.AQ0021)
                    }
                    helperText={
                      formikEvent.touched.AQ0021 && formikEvent.errors.AQ0021
                    }
                  >
                    <MenuItem key="1" value="1">
                      1 (lowest)
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      2
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      3
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      4
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      5
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      6
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      7
                    </MenuItem>
                    <MenuItem key="8" value="8">
                      8
                    </MenuItem>
                    <MenuItem key="9" value="9">
                      9
                    </MenuItem>
                    <MenuItem key="10" value="10">
                      10 (highest)
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0022 - 4row text */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q22 Is there anything you like to share and/or add to your
                  weekly activity report?
                </Typography>
                <NiceTextField
                  id="AQ0022-message"
                  label="Feedback"
                  name="AQ0022"
                  disabled={submited}
                  placeholder="General Feedback"
                  multiline
                  rows={4}
                  value={formikEvent.values.AQ0022 || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0022 &&
                    Boolean(formikEvent.errors.AQ0022)
                  }
                  helperText={
                    formikEvent.touched.AQ0022 && formikEvent.errors.AQ0022
                  }
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button variant="contained" type="submit" disabled={submited}>
                  SUBMIT
                </Button>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/home"}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default WeeklyReportCoach;
