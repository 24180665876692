import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { format, parseJSON } from "date-fns";
import { useNavigate } from "react-router-dom";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

function DisplayEvent() {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const theID = searchParams.get("id");

  const [values, setValues] = useState([]);

  useEffect(() => {
    FetcEvent();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  function viewProgram(theId) {
    navigate("/training?id=" + theID);
  }

  const FetcEvent = async () => {
    var sqol = "select Id";
    sqol = sqol + ", Name";
    sqol = sqol + ", Date_and_Time__c";
    sqol = sqol + ", Minutes__c";
    sqol = sqol + ", Message__c";
    sqol = sqol + ", Group__r.Name";
    sqol = sqol + ", Global_Goal__r.Name";
    sqol = sqol + ", Inviter__r.Name";
    sqol = sqol + ", Location__c";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Training_Program__r.Name";
    sqol = sqol + ", Training_Program__c";
    sqol = sqol + ", Zoom_Link__c";
    sqol = sqol + ", Zoom_Meeting_ID__c";
    sqol = sqol + ", Zoom_Passcode__c";
    sqol = sqol + " from Training_Event__c";
    sqol = sqol + " where Id = '" + theID + "'";

    //console.log("sqol= ", sqol);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    console.log("New Data= ", data.records[0]);

    try {
      data.records[0].InviterName = data.records[0].Inviter__r.Name;
      if (data.records[0].Training_Program__r != null) {
        data.records[0].TrainingName = data.records[0].Training_Program__r.Name;
      } else {
        data.records[0].TrainingName = "";
      }
      if (data.records[0].Global_Goal__r != null) {
        data.records[0].GGName = data.records[0].Global_Goal__r.Name;
      } else {
        data.records[0].GGName = "";
      }

      setValues(data.records[0]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  EVENT DETAILS
                </Typography>
                <NiceTextField
                  id="event-inviter"
                  label="Inviter"
                  value={values.InviterName || ""}
                />
                <NiceTextField
                  id="event-Type"
                  label="Type"
                  value={values.Type__c || ""}
                />

                <NiceTextField
                  id="event-title"
                  label="Subject"
                  value={values.Name || ""}
                />
                <NiceTextField
                  id="event-message"
                  label="Message"
                  name="Message__c"
                  multiline
                  rows={4}
                  value={values.Message__c || ""}
                />
                <NiceTextField
                  id="event-date"
                  label="Date & Time"
                  value={
                    values.Date_and_Time__c
                    /*                     format(parseJSON(values.Date_and_Time__c),"EEEE do MMM" ) || ""
                     */
                  }
                />
                <NiceTextField
                  id="event-minutes"
                  label="Duration (in minutes)"
                  value={values.Minutes__c || ""}
                />
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  EVENT CONTEXT
                </Typography>
                <NiceTextField
                  id="event-location"
                  label="Location"
                  multiline
                  rows={4}
                  value={values.Location__c || ""}
                />
                <NiceTextField
                  id="event-Training_Program"
                  label="Training Program"
                  value={values.TrainingName || ""}
                />
                <NiceTextField
                  id="training-globalgoal"
                  label="Global Goal"
                  value={values.GGName || ""}
                />
                <NiceTextField
                  id="training-zoomurl"
                  label="Zoom Link"
                  value={values.Zoom_Link__c || ""}
                />
                <NiceTextField
                  id="training-zoomid"
                  label="Zoom Meeting ID"
                  value={values.Zoom_Meeting_ID__c || ""}
                />
                <NiceTextField
                  id="training-zoompc"
                  label="Zoom Passcode"
                  value={values.Zoom_Passcode__c || ""}
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/calendar"}
                  size="small"
                >
                  Return
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default DisplayEvent;
