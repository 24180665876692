import React from "react";
import Box from "@mui/material/Box";
import Document from "./Document";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => (
  <Document>
    <Box sx={{ p:2, pt:1, pb:2, backgroundImage: "url('https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/71bd688e-aee0-4b48-ae56-12979d5d60c4/Eir-League-JO-fall-2022-FE8P7457.jpg')", backgroundSize: "cover", flexGrow: 1 }}>
      <img
        src="https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/dfcc150c-642b-4306-9c3b-57c892299b4f/Leageu+logo+white.png"
        alt="EIR League"
        height="120px"
      />
    </Box>
    {children}
  </Document>
);
