export const TRANSLATIONS_ARAB = {
    signup_welcome: "أهلا بكم في كأس العالم للأهداف المستدامة",
    signup_intro: "أنت على وشك تسجيل فريق للعب في كأس العالم للأهداف المستدامة سينضم فريقك إلى شبكة عالمية من النساء اللواتي يلعبن من أجل مستقبل مستدام وأكثر عدلاً للجميع.",
    signup_subintro: "",
    signup_tournament: "اختاري المنافسة",
    signup_globalgoal: "اختاري احد اهداف التمية المستدامة (بامكانك التغيير لاحقا)",
    signup_firstname: "الاسم الاول",
    signup_lastname: "أسم العائلة",
    signup_email: "عنوان بريدك الالكتروني",
    signup_email_verify: "تأكيد بريدك الالكتروني",
    signup_team: "أسم فريقك(بأمكانك التغيير لاحقا)",
    signup_acknowledge: "أنت تعلمين أن البطولة مخصصة للاعبات / الفرق فقط.",
    signup_concent: "الموافقة والتنازل عن المسؤولية",
    signup_remind: "بعد التسجيل ، ستتلقي رسالة بريد إلكتروني تحتوي على مزيد من المعلومات حول البطولة وكيفية إضافة لاعبين إلى فريقك وغير ذلك الكثير.",
    signup_submit: "سجلي الفريق",
    signup_language: "اختار اللغة",

    public_blank: "",
    public_switch: "تبديل",
    public_welcome: "مرحباً بك في تطبيق دوري كرة القدم",
    public_tournament: "اختر الدوري",
    public_notournament: "لا يوجد حاليا أي دوري متاح",
    public_schedule: "الجدول",
    public_details: "النشاطات",
    public_ranking: "التصنيف",
    public_bio: "عن الفريق",
    public_gg: "ما هو أهم هدف مستدام  بالنسبة للفريق؟",
    public_why: "لماذا الهدف المستدام الذي تم اختياره مهم للفريق؟",
    public_how: "كيف سيعمل الفريق على تحقيق الهدف المستدام؟",
    public_success: "كيف سيعرف الفريق نجاحه؟",
    public_objective: "ما هو أهم شيء بالنسبة للفريق في هذا الموسم؟",
    public_ranking_intro: "كل فريق سيسجل نقاط في عدة فئات:",
    public_game_point: "كرة القدم",
    public_game_point_text: "سيحصل الفريق الفائز على ٣ نقاط. سيحصل الفريق الخاسر على نقطة واحدة. سيحصل كلا الفريقين على نقطتين عند التعادل. مدة كل مباراة كرة قدم ١٥ دقيقة.",
    public_style_point: "الزي المبتكر",
    public_style_point_text: "يُعطى بناءً على ابداع الفريق وكيفية دمج وتجسيد الهدف المستدام مع زي الفريق. يمكن للفرق تسجيل ١ إلى ٥ نقاط.",
    public_followers_point: "الجماهير و المتابعين ",
    public_followers_point_text: "في تقارير النشاط ، ستشارك عدد متابعيك. يمكن للفرق تسجيل ١ إلى ٥ نقاط.",
    public_crowd_point: "الجماهير و المتابعين",
    public_crowd_point_text: "بناءً على مجهود الفريق على وسائل التواصل الاجتماعي وذلك عند مشاركة المواضيع التالية: أيام المباريات وتدريبات الفريق والأنشطة الخاصة بالهدف المستدام. يمكنك تسجيل ١ أو ٣ أو ٥ نقاط. (ستشمل هذه النقطة الجمهور أيضاً)",  
    public_spirit_point: "الروح الرياضية",
    public_spirit_point_text: "بناءً على الفريق ومواقفهم وسلوكياتهم في يوم المباراة. يمكن للفرق تسجيل ١ إلى ٥ نقاط.",
    public_action_point: " النشاط",
    public_action_point_text: "بناءً على نشاط وعمل الفريق الفريق حيث سيقوم كل قائد فريق بملء تقرير النشاط قبل كل يوم مباراة. يمكن للفرق تسجيل ١ إلى ٥ نقاط",
    public_action_point_link:"للمزيد حول نظام تسجيل النقاط هنا",
    public_game_day_filter: "يوم المباراة",
    public_team_filter: "Team",
    public_ar_program: "Program Activities",
    public_ar_team: "Team Activities",
    public_ar_hours: "Hours trained",
    public_ar_observers: "Observers/Attendance",
    public_ar_actions: "Community actions",
    public_ar_people: "People reached"
    
  };