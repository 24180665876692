import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const NiceCard = styled(Card)({
  margin: "10px",
});

const NiceBox = styled(Box)({
  margin: "10px",
});

function Survey() {
  const [contenttype, setContenttype] = useState("");
  const [values, setValues] = useState([]);

  const handleChange = (event) => {
    setContenttype(event.target.value);
  };

  const showJSON = () => {
    console.log("JSON= ", values);
  };

  useEffect(() => {
    FetchSurveys();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");
  const sqol =
    "select Id, ArdiraSurvey__Due_Date__c, ArdiraSurvey__Response_URL__c, ArdiraSurvey__Status__c, OwnerId, ArdiraSurvey__Survey_Name__c from ArdiraSurvey__Survey_Result__c where ArdiraSurvey__Target_Record_Id__c = '0030900000xDSovAAG'";
  //console.log("sqol= ", sqol);

  const FetchSurveys = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      setValues(data.records);
      //console.log("Surveys= ", data.records);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Library">
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Todo
        </Typography>
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      {values.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell style={{ width: 50 }}>
                            <Typography
                              sx={{ p: 1, m: 1, pb: 0 }}
                              align="left"
                              variant="body2"
                            >
                              {row.ArdiraSurvey__Status__c}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row.ArdiraSurvey__Status__c === "Completed" ? (
                              <Typography
                                sx={{ p: 1, m: 1, pb: 0 }}
                                align="left"
                                variant="body2"
                              >
                                {row.ArdiraSurvey__Survey_Name__c}
                              </Typography>
                            ) : (
                              <Button
                                href={row.ArdiraSurvey__Response_URL__c}
                                target={"_blank"}
                              >
                                {row.ArdiraSurvey__Survey_Name__c}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Survey;
