import i18n from "i18next";
import { initReactI18next } from "react-i18next";
 
import { TRANSLATIONS_DK } from "@locales/dk/translation";
import { TRANSLATIONS_EN } from "@locales/en/translation";
import { TRANSLATIONS_ARAB } from "@locales/arab/translation";
 
i18n
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     dk: {
       translation: TRANSLATIONS_DK
     },
     arab: {
       translation: TRANSLATIONS_ARAB
     }
   }
 });
 
export default i18n;