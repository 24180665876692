import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

function DisplayProfile() {
  let navigate = useNavigate();
  const [values, setValues] = useState([]);

  const [searchParams] = useSearchParams();
  const theContactID = searchParams.get("id");
  const theOrigin = searchParams.get("origin");

  useEffect(() => {
    GetUser(theContactID);
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function goBacktoOrigin() {
    navigate("/" + theOrigin);
  }

  const GetUser = async (theID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        theID,
      requestOptions
    );
    const data = await response.json();
    try {
      setValues(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <div>
                  <Box sx={{ p: 1, pt: 3 }}>
                    <Avatar
                      name={values.Name || "XX XX"}
                      size="150"
                      src={"https://agxpxlknzr.cloudimg.io/" + values.Avatar__c + "?width=150&height=150&func=face"}
                      color={Avatar.getRandomColor("sitebase", ["red", "blue"])}
                    />
                  </Box>
                </div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  PERSONAL DETAILS
                </Typography>
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-role"
                  label="Role"
                  value={values.UserProfile__c || ""}
                  variant="standard"
                />
                                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-team"
                  label="Team"
                  value={values.Active_Team_Name__c || ""}
                  variant="standard"
                />
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-firstname"
                  label="First Name"
                  value={values.FirstName || ""}
                  name="FirstName"
                  variant="standard"
                />
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-lastname"
                  label="Last Name"
                  value={values.LastName || ""}
                  name="LastName"
                  variant="standard"
                />
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-email"
                  label="Email"
                  value={values.Email || ""}
                  name="Email"
                  variant="standard"
                />
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-phone"
                  label="Mobile Phone"
                  value={values.MobilePhone || ""}
                  name="MobilePhone"
                  variant="standard"
                />
                <NiceCardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={flipGG(values.Global_Goal__c)}
                  alt="GGWCUP Ball"
                />
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  OTHER DETAILS
                </Typography>
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-bio"
                  label="Bio"
                  value={values.Bio__c || ""}
                  name="Bio__c"
                  multiline
                  rows={4}
                  variant="standard"
                />
                <NiceTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="profile-philosophy"
                  label="Play Philosophy"
                  value={values.Play_Philosophy__c || ""}
                  name="Play_Philosophy__c"
                  multiline
                  rows={4}
                  variant="standard"
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button onClick={goBacktoOrigin} size="small">
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default DisplayProfile;
