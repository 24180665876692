import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Container, Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UniqueArray } from "@lib";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import CardMedia from "@mui/material/CardMedia";

const EmailButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

function Scorecards() {
  let navigate = useNavigate();

  const sfid = sessionStorage.getItem("sfid");
  const userProgram = sessionStorage.getItem("userProgram");
  const userId = sessionStorage.getItem("userId");

  const [openScoreCard, setOpenScoreCard] = useState(false);
  const [values, setValues] = useState([]);
  const [scoreCardValues, setScoreCardValues] = useState([]);

  function doScoreCard(theRow) {
    setScoreCardValues(theRow);
    setOpenScoreCard(true);
  }

  function closeScoreCard() {
    setOpenScoreCard(false);
  }

  const toggleOpenScoreCard = (newOpen) => () => {
    setOpenScoreCard(newOpen);
  };

  useEffect(() => {
    FetchScorecardGames(userProgram);
  }, []);

  const FetchScorecardGames = async (Id) => {
    //const theURL = "http://localhost:5000/scorecards/" + Id;
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/scorecards/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      console.log(data);
      setValues(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    //console.log("e.target=", e.target);
    //console.log("e.target.checked=", e.target.checked);
    const { name, value } = e.target;
    setScoreCardValues({
      ...scoreCardValues,
      [name]: value,
    });
  };

  const SaveScoreCard = async (state) => {
    var theCount = 0;
    if (state === "Closed") {
      theCount = 1;
    }
    //const theURL = "http://localhost:5000/updateScoreCard";
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/updateScoreCard";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      score_id: scoreCardValues.score_id,
      score_ar_id: scoreCardValues.score_ar_id,
      score_count: theCount,
      score_state: state,
      score_style_p: scoreCardValues.score_style_p,
      score_spirit_p: scoreCardValues.score_spirit_p,
      score_crowd_p: scoreCardValues.score_crowd_p,
      score_game_p: scoreCardValues.score_game_p,
      reporter: userId,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      FetchScorecardGames(userProgram);
      setOpenScoreCard(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Scorecards">
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            },
          },
        }}
        anchor="right"
        open={openScoreCard}
        onClose={toggleOpenScoreCard(false)}
        onOpen={toggleOpenScoreCard(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              SCORECARD
            </Typography>

            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleOpenScoreCard(false)}
            />
          </Box>
          <Typography sx={{ pt: 2, pr: 2 }} align="left" variant="body1">
            The game was {scoreCardValues.team_name} vs{" "}
            {scoreCardValues.opponent_name}
          </Typography>
          <Typography sx={{ pb: 2, pr: 2 }} align="left" variant="h6">
            Score for {scoreCardValues.team_name}
          </Typography>
          <NiceSelectFormControl fullWidth>
            <InputLabel id="style_label">Action Points</InputLabel>
            <Select
              labelId="style_label"
              id="stylepoints"
              disabled
              value={scoreCardValues.score_action_p || "0"}
              label="Action Points"
              name="score_action_p"
            >
              <MenuItem key="style0" value="0">
                No points available from Action Report yet
              </MenuItem>
              <MenuItem key="style1" value="1">
                1 Point - Team met up once
              </MenuItem>
              <MenuItem key="style3" value="3">
                3 Points - Team met up once and engaged more than 10 external
                people
              </MenuItem>
              <MenuItem key="style5" value="5">
                5 Points - Team met up once and super engaged over 40 external
                people
              </MenuItem>
            </Select>
          </NiceSelectFormControl>
          {scoreCardValues.score_type === "Game" ? (
            <div>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="game_label">Footbal Points</InputLabel>
                <Select
                  labelId="game_label"
                  id="gamepoints"
                  value={scoreCardValues.score_game_p || "0"}
                  label="Football Points"
                  name="score_game_p"
                  onChange={handleInputChange}
                >
                  <MenuItem key="game0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="style1" value="1">
                    1 Point - Team lost
                  </MenuItem>
                  <MenuItem key="gane2" value="2">
                    2 Points - It was a tie
                  </MenuItem>
                  <MenuItem key="game3" value="3">
                    3 Points - Team won
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
            </div>
          ) : (
            <div>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="style_label">Style Points</InputLabel>
                <Select
                  labelId="style_label"
                  id="stylepoints"
                  value={scoreCardValues.score_style_p || "0"}
                  label="Style Points"
                  name="score_style_p"
                  onChange={handleInputChange}
                >
                  <MenuItem key="style0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="style1" value="1">
                    1 Point - Team identity element iconic to the team
                  </MenuItem>
                  <MenuItem key="style2" value="2">
                    2 Point
                  </MenuItem>
                  <MenuItem key="style3" value="3">
                    3 Points - Team's style is sustainably conscious or
                    up-cycled
                  </MenuItem>
                  <MenuItem key="style4" value="4">
                    4 Point
                  </MenuItem>
                  <MenuItem key="style5" value="5">
                    5 Points - Team's style goes beyond the uniforms on gameday
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="playspirit_label">
                  Play Spirit Points
                </InputLabel>
                <Select
                  labelId="playspirit_label"
                  id="spiritpoints"
                  value={scoreCardValues.score_spirit_p || "0"}
                  label="Play Spirit Points"
                  name="score_spirit_p"
                  onChange={handleInputChange}
                >
                  <MenuItem key="spirit0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="spirit1" value="1">
                    1 Point - Chants & celebrations
                  </MenuItem>
                  <MenuItem key="spirit2" value="2">
                    2 Point
                  </MenuItem>
                  <MenuItem key="spirit3" value="3">
                    3 Points - All players showed enthusiasm
                  </MenuItem>
                  <MenuItem key="spirit4" value="4">
                    2 Point
                  </MenuItem>
                  <MenuItem key="spirit5" value="5">
                    5 Points - Becoming better and stronger
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="crowd_label">Crowd Points</InputLabel>
                <Select
                  labelId="crowd_label"
                  id="crowdpoints"
                  value={scoreCardValues.score_crowd_p || "0"}
                  label="Crowd Points"
                  name="score_crowd_p"
                  onChange={handleInputChange}
                >
                  <MenuItem key="crowd0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="crowd1" value="1">
                    1 Point - Team showed up
                  </MenuItem>
                  <MenuItem key="crowd2" value="2">
                    2 Points - Team brought family, friends or co-workers
                  </MenuItem>
                  <MenuItem key="crowd3" value="3">
                    3 Points - Teams fans are dressed up and have props
                  </MenuItem>
                  <MenuItem key="crowd4" value="4">
                    4 Points - All of the above plus team fans have that extra
                    love
                  </MenuItem>
                  <MenuItem key="crowd5" value="5">
                    5 Points - All of the above- and surprise
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
            </div>
          )}
          <Box sx={{ m: 1, pb: 1 }}>
            <Button variant="contained" onClick={() => SaveScoreCard("Open")}>
              Only Update
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => SaveScoreCard("Closed")}
            >
              Submit For Ranking
            </Button>
          </Box>
          <Box sx={{ ml: 1, pt: 0 }}>
            <Button onClick={() => closeScoreCard()} size="small">
              Return to Scorecards
            </Button>
          </Box>
        </Container>
      </Drawer>
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Scorecards
        </Typography>
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ pl: 0, pr: 0 }}>Team</TableCell>
                        <TableCell sx={{ pl: 0, pr: 0 }}>Game Day</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((row) => (
                        <TableRow
                          key={row.game_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              variant="body2"
                            >
                              <MuiLink
                                component="button"
                                onClick={() => doScoreCard(row)}
                                variant="body1"
                                noWrap
                                underline="hover"
                              >
                                {row.team_name}
                              </MuiLink>
                              &nbsp;vs {row.opponent_name}
                            </Typography>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              noWrap
                              variant="body2"
                            >
                              {row.score_type}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ pl: 0, pr: 0 }}>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              noWrap
                              variant="body2"
                            >
                              {row.game_day_name}
                            </Typography>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              noWrap
                              variant="body2"
                            >
                              {row.score_state}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Scorecards;
