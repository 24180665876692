import React from "react";

const Container = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row"
    }}
  >
    {children}
  </div>
);

const FormWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "center",
      width: "500px",
      height: "100vh"
    }}
  >
    <div
      style={{
        textAlign: "center",
      }}
    >
    </div>
    {children}
    <div>
    </div>
  </div>
);

const ImageBackground = () => (
  <div
    style={{
      width: "calc(100vw - 500px)",
      backgroundImage: 'url("assets/coach-hero.jpeg")',
      backgroundColor: "#ddd",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100vh"
    }}
  />
);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => (
  <Container>
    <FormWrapper>{children}</FormWrapper>
    <ImageBackground />
  </Container>
);
