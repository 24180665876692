import React, { useState, useEffect } from "react";
import { PublicLeagueLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import Avatar from "react-avatar";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import CardMedia from "@mui/material/CardMedia";
import { Link as MuiLink } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { format, parseJSON } from "date-fns";
import CardContent from "@mui/material/CardContent";

// https://translation.googleapis.com/language/translate/v2?key=AIzaSyCIgO9_Oz8NJItmppkfoDX6D59IQniZedA&q=%D9%85%D9%86%20%D8%AE%D9%84%D8%A7%D9%84%20%D8%A7%D9%84%D8%B9%D9%85%D9%84%20%D9%88%D8%A7%D9%84%D9%84%D8%B9%D8%A8%20%D9%88%D8%A3%D9%86%20%D8%AA%D9%83%D9%88%D9%86%20%D9%82%D8%AF%D9%88%D8%A9%20%D9%85%D8%AB%D8%A7%D9%84%D9%8A%D8%A9%20%D9%84%D8%BA%D9%8A%D8%B1%D9%87%D8%A7%20%D9%85%D9%86%20%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1%20%D9%84%D8%AA%D9%83%D9%88%D9%86%20%D9%85%D8%AB%D9%84%D9%86%D8%A7&source=ar&target=da

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const WhiteTypography = styled(Typography)({
  width: "95%",
  margin: "10px",
  color: "#FFFFFF",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const PublicLeague = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [open, setOpen] = useState();
  const [scheduleGameday, setScheduleGameday] = useState();
  const [scheduleTeam, setScheduleTeam] = useState();
  const [programRankings, setProgramRankings] = useState([]);
  const [programGames, setProgramGames] = useState([]);
  const [programRawGames, setProgramRawGames] = useState([]);
  const [programEvents, setProgramEvents] = useState([]);
  const [programTeams, setProgramTeams] = useState([]);
  const [programActionTeam, setProgramActionTeam] = useState([]);
  const [activeLeague, setActiveLeague] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [publicStep, setPublicStep] = useState("PickLeague");
  const [program, setProgram] = useState();
  const [programName, setProgramName] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("total_p");
  const [teamvalue, setTeamvalue] = useState([]);
  const [teamAR_Q4_hours, setTeamAR_Q4_hours] = useState(0);
  const [teamAR_Q7_observers, setTeamAR_Q7_observers] = useState(0);
  const [teamAR_Q14_actions, setTeamAR_Q14_actions] = useState(0);
  const [teamAR_Q18_people, setTeamAR_Q18_people] = useState(0);
  const [programAR_Q4_hours, setProgramAR_Q4_hours] = useState(0);
  const [programAR_Q7_observers, setProgramAR_Q7_observers] = useState(0);
  const [programAR_Q14_actions, setProgramAR_Q14_actions] = useState(0);
  const [programAR_Q18_people, setProgramAR_Q18_people] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function getQuestionValue(rawData, theQuestion) {
    var scanData = rawData;
    var theValue = 0;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.eir_action_question__c === theQuestion) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    if (scanData.length === 1) {
      theValue = scanData[0].sum;
    }
    return theValue;
  }

  const FetchProgramActionReportTotals = async (Id) => {
    const theID = Id.substr(0, 15);
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/programActionReportTotals/" +
      theID;
    const response = await fetch(theURL);
    const data = await response.json();
    //console.log(theID);
    //console.log(data);
    try {
      setProgramAR_Q4_hours(getQuestionValue(data, "a2D090000004OVNEA2") / 60);
      setProgramAR_Q7_observers(getQuestionValue(data, "a2D090000004OVTEA2") + getQuestionValue(data, "a2D090000004OVhEAM"));
      setProgramAR_Q14_actions(getQuestionValue(data, "a2D090000004OVYEA2"));
      setProgramAR_Q18_people(getQuestionValue(data, "a2D9N0000004AT8UAM"));
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramActionReportTeam = async (Id, Pid) => {
    const theID = Id.substr(0, 15);
    const thePID = Pid.substr(0, 15);
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/programActionReportTeam/" +
      theID +
      "/" +
      thePID;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setTeamAR_Q4_hours(getQuestionValue(data, "a2D090000004OVNEA2") / 60);
      setTeamAR_Q7_observers(getQuestionValue(data, "a2D090000004OVTEA2") + getQuestionValue(data, "a2D090000004OVhEAM"));
      setTeamAR_Q14_actions(getQuestionValue(data, "a2D090000004OVYEA2"));
      setTeamAR_Q18_people(getQuestionValue(data, "a2D9N0000004AT8UAM"));
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChangeActionTeam = (e) => {
    const { value } = e.target;
    setProgramActionTeam(value);
    FetchProgramActionReportTeam(value, program);
  };

  const handleInputChangeFilterTeam = (e) => {
    const { value } = e.target;
    setScheduleTeam(value);
    setScheduleGameday("All game days");
    scanListTeam(value);
  };

  const handleInputChangeFilterGameday = (e) => {
    const { value } = e.target;
    setScheduleGameday(value);
    setScheduleTeam("All teams");
    scanListGameday(value);
  };

  function scanListTeam(theTeam) {
    var scanData = programRawGames;
    if (theTeam !== "All teams") {
      scanData = scanData.reduce((filterItems, item) => {
        if (item.team_id === theTeam) {
          filterItems.push(item);
        }
        if (item.opponent_id === theTeam) {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
      setProgramGames(scanData);
    } else {
      setProgramGames(programRawGames);
    }
  }

  function scanListGameday(theGameday) {
    var scanData = programRawGames;
    if (theGameday !== "All game days") {
      scanData = scanData.reduce((filterItems, item) => {
        if (item.game_day_name === theGameday) {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
      setProgramGames(scanData);
    } else {
      setProgramGames(programRawGames);
    }
  }

  const FetchActiveLeagues = async () => {
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/activeLeagues";
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setActiveLeague(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTranslation = async (theString) => {
    if (theString === null) {
      return null;
    }
    var translationLanguage = "en";
    if (language === "arab") {
      translationLanguage = "ar";
    }
    var translatedString = "Bad data";
    const theURL =
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCIgO9_Oz8NJItmppkfoDX6D59IQniZedA&q=" +
      theString +
      "&target=" +
      translationLanguage;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      translatedString = data.data.translations[0].translatedText;
    } catch (err) {
      console.log(err);
    }
    return translatedString;
  };

  const [searchParams] = useSearchParams();
  const theLanguage = searchParams.get("language");
  const ref = React.useRef(null);

  useEffect(() => {
    setShowNavigation(false);
    FetchActiveLeagues();
    i18n.changeLanguage("en");
    setLanguage("en");
    if (theLanguage !== "") {
      i18n.changeLanguage(theLanguage);
      setLanguage(theLanguage);
    }
    ref.current.ownerDocument.body.scrollTop = 0;
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  function selectLeague(theProgramId, theProgramName) {
    setProgram(theProgramId);
    setProgramName(theProgramName);
    FetchProgramRanking(theProgramId);
    FetchProgramTeams(theProgramId);
    FetchProgramEvents(theProgramId);
    FetchProgramActionReportTotals(theProgramId);
    FetchProgramGames(theProgramId);
    setScheduleGameday("All game days");
    setScheduleTeam("All teams");
    setShowNavigation(true);
    showDisplayLeague();
  }

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function lookupGG(theId) {
    if (theId === "a0u090000010VD5AAM") {
      return "Global Goal 1 - No Poverty";
    }
    if (theId === "a0u090000010VDAAA2") {
      return "Global Goal 2 - Zero Hunger";
    }
    if (theId === "a0u090000010VyvAAE") {
      return "Global Goal 3 - Good Health and Well-being";
    }
    if (theId === "a0u090000010VyhAAE") {
      return "Global Goal 4 - Quality Education";
    }
    if (theId === "a0u090000010Vz1AAE") {
      return "Global Goal 5 - Gender Equality";
    }
    if (theId === "a0u090000010VyiAAE") {
      return "Global Goal 6 - Clean Water and Sanitation";
    }
    if (theId === "a0u090000010Vz5AAE") {
      return "Global Goal 7 - Affordable and Clean Energy";
    }
    if (theId === "a0u090000010VzAAAU") {
      return "Global Goal 8 - Decent Work and Economic Growth";
    }
    if (theId === "a0u090000010Vz6AAE") {
      return "Global Goal 9 - Industry, Innovation and Infrastructure";
    }
    if (theId === "a0u090000010VzKAAU") {
      return "Global Goal 10 - Reduced Inequalities";
    }
    if (theId === "a0u090000010VzLAAU") {
      return "Global Goal 11 - Sustainable Cities and Communities";
    }
    if (theId === "a0u090000010VzBAAU") {
      return "Global Goal 12 - Responsible Consumption and Production";
    }
    if (theId === "a0u090000010VzPAAU") {
      return "Global Goal 13 - Climate Action";
    }
    if (theId === "a0u090000010VymAAE") {
      return "Global Goal 14 - Life Below Water";
    }
    if (theId === "a0u090000010VzUAAU") {
      return "Global Goal 15 - Life On Land";
    }
    if (theId === "a0u090000010VzZAAU") {
      return "Global Goal 16 - Peace, Justice and Strong Institutions";
    }
    if (theId === "a0u090000010VywAAE") {
      return "Global Goal 17 - Partnership For The Goals";
    }
    return null;
  }

  function showPickLeague() {
    setPublicStep("PickLeague");
  }

  function showDisplayLeague() {
    setPublicStep("DisplayLeague");
  }

  function showDisplaySchedule() {
    setPublicStep("DisplaySchedule");
  }

  function showDisplayDetails() {
    setPublicStep("DisplayDetails");
  }

  const handleChange = async (event, newValue) => {
    switch (newValue) {
      case "ranking":
        showDisplayLeague();
        break;
      case "schedule":
        showDisplaySchedule();
        break;
      case "league":
        showDisplayDetails();
        break;
      default:
        setShowNavigation(false);
        showPickLeague();
    }
  };

  function viewTeam(theID) {
    FetchTeam(theID);
    setOpen(true);
  }

  const FetchProgramRanking = async (Id) => {
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/teamranking/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramRankings(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramGames = async (Id) => {
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/leagueGames/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramRawGames(data);
      setProgramGames(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramEvents = async (Id) => {
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/leagueEvents/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramEvents(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramTeams = async (Id) => {
    const theURL =
      "https://enigmatic-atoll-29291.herokuapp.com/programTeams/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramTeams(data);
      if (data.length > 0) {
        setProgramActionTeam(data[0].team_id);
        FetchProgramActionReportTeam(data[0].team_id, data[0].program_id);
        } else {
          setProgramActionTeam("none");
          setTeamAR_Q4_hours(0);
          setTeamAR_Q7_observers(0);
          setTeamAR_Q14_actions(0);
          setTeamAR_Q18_people(0);
          }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTeam = async (Id) => {
    const theURL = "https://enigmatic-atoll-29291.herokuapp.com/appTeam/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      data[0].introduce_the_team_club__c = await FetchTranslation(
        data[0].introduce_the_team_club__c
      );
      data[0].global_goal_action__c = await FetchTranslation(
        data[0].global_goal_action__c
      );
      data[0].global_goal_impact__c = await FetchTranslation(
        data[0].global_goal_impact__c
      );
      data[0].global_goal_why__c = await FetchTranslation(
        data[0].global_goal_why__c
      );
      //console.log(data[0]);
      setTeamvalue(data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "team_name",
      numeric: false,
      disablePadding: false,
      label: "Team",
    },
    {
      id: "action_p",
      numeric: true,
      disablePadding: false,
      label: "A",
    },
    {
      id: "style_p",
      numeric: true,
      disablePadding: false,
      label: "S",
    },
    {
      id: "spirit_p",
      numeric: true,
      disablePadding: false,
      label: "P",
    },
    {
      id: "game_p",
      numeric: true,
      disablePadding: false,
      label: "F",
    },
    {
      id: "crowd_p",
      numeric: true,
      disablePadding: false,
      label: "C",
    },
    {
      id: "total_p",
      numeric: true,
      disablePadding: false,
      label: "T",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ m: 0, p: 0, pb: 1 }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <PublicLeagueLayout>
      <div>
        <Drawer
          PaperProps={{
            sx: { width: "100%", pr: 2 },
          }}
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{ pt: 2 }} display="flex" justifyContent="space-between">
            <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
              {teamvalue.name || ""}
            </Typography>
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={() => setOpen(false)}
            />
          </Box>
          <NiceCardMedia
            component="img"
            sx={{ width: "348px" }}

            image={
              "https://agxpxlknzr.cloudimg.io/" +
              teamvalue.primary_photo__c +
              "?width=500&height=500"
            }
            alt="GGWCUP Ball"
          />
          <NiceTextField
            id="team-gg-label"
            variant="standard"
            multiline
            label={t("public_gg")}
            InputProps={{ disableUnderline: true }}
            value={lookupGG(teamvalue.global_goal__c) || "..."}
          />
          <NiceCardMedia
            id="team-gg"
            component="img"
            sx={{ width: 151 }}
            image={flipGG(teamvalue.global_goal__c)}
            alt="GGWCUP Ball"
          />
          <NiceTextField
            id="team-bio"
            variant="standard"
            multiline
            InputProps={{ disableUnderline: true }}
            label={t("public_bio")}
            value={teamvalue.introduce_the_team_club__c || "..."}
          />
          <NiceTextField
            id="team-ggwhy"
            variant="standard"
            multiline
            InputProps={{ disableUnderline: true }}
            label={t("public_why")}
            value={teamvalue.global_goal_why__c || "..."}
          />
          <NiceTextField
            id="team-ggaction"
            variant="standard"
            multiline
            InputProps={{ disableUnderline: true }}
            label={t("public_how")}
            value={teamvalue.global_goal_action__c || "..."}
          />
          <NiceTextField
            id="team-success"
            variant="standard"
            multiline
            InputProps={{ disableUnderline: true }}
            label={t("public_success")}
            value={teamvalue.team_success__c || "..."}
          />
          <NiceTextField
            id="team-objective"
            variant="standard"
            multiline
            InputProps={{ disableUnderline: true }}
            label={t("public_objective")}
            value={teamvalue.team_objective__c || "..."}
          />
        </Drawer>
      </div>
      <Container maxWidth="lg">
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          <TabContext value={publicStep}>
            <TabPanel value="PickLeague">
              <FormControl size="small" sx={{ pt: 4, pb: 1, minWidth: 100 }}>
                <Select
                  labelId="language-simple-select-label"
                  id="i18n1"
                  name="i18n1"
                  value={language || "en"}
                  onChange={handleLanguageChange}
                >
                  <MenuItem key="1" value="en">
                    English
                  </MenuItem>
                  <MenuItem key="3" value="arab">
                    عربي
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {t("public_welcome")}
              </Typography>
              <Typography sx={{ pt: 1, pb: 2 }} variant="h6">
                {t("public_tournament")}
              </Typography>
              {activeLeague.map((row) => (
                <Box
                  id={row.sfid}
                  sx={{
                    height: "75px",
                    backgroundColor: "#" + row.eir_program_colour__c,
                    flexGrow: 1,
                  }}
                  onClick={() =>
                    selectLeague(row.sfid, row.name, row.program_sports__c)
                  }
                >
                  <WhiteTypography sx={{ pt: 3, pb: 3 }} variant="h6">
                    {row.name}
                  </WhiteTypography>
                </Box>
              ))}
            </TabPanel>
            <TabPanel sx={{ p: 0, m: 0 }} value="DisplayLeague">
              {" "}
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {programName}
              </Typography>
              <div>
                <Typography
                  align="left"
                  sx={{ mt: 0, ml: 0, pb: 2 }}
                  variant="h5"
                >
                  {t("public_ranking")}
                </Typography>
                <TableContainer>
                  <Table
                    sx={{ overflow: "hidden" }}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(
                        programRankings,
                        getComparator(order, orderBy)
                      ).map((row) => {
                        return (
                          <TableRow
                            key={row.team_id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              sx={{ m: 0, p: 0, pt: 1, pb: 1 }}
                              component="th"
                              scope="row"
                              style={{
                                whiteSpace: "nowrap",
                                //textOverflow: "ellipsis",
                                width: "150px",
                                display: "block",
                                overflow: "hidden",
                              }}
                            >
                              <Avatar
                                name={row.name}
                                size="28"
                                src={
                                  "https://agxpxlknzr.cloudimg.io/" +
                                  row.team_image +
                                  "?width=28&height=28"
                                }
                              />
                              &nbsp;
                              <MuiLink
                                component="button"
                                onClick={() => viewTeam(row.team_id)}
                                variant="body1"
                                underline="hover"
                              >
                                {row.team_name}
                              </MuiLink>
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.action_p}
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.style_p}
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.spirit_p}
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.game_p}
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.crowd_p}
                            </TableCell>
                            <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                              {row.total_p}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <NiceTextField
                  id="rank_intro"
                  variant="standard"
                  multiline
                  label={t("Intro")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_ranking_intro")}
                />
                <NiceTextField
                  id="rank_action"
                  variant="standard"
                  multiline
                  label={t("public_action_point")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_action_point_text")}
                />
                <Typography align="left" variant="body2">
                  <Link
                    sx={{ ml: 1, mb: 1 }}
                    align="left"
                    target="_blank"
                    rel="noopener"
                    href="https://www.signupleague.com/rules"
                  >
                    {t("public_action_point_link")}
                  </Link>
                </Typography>
                <NiceTextField
                  id="rank_style"
                  variant="standard"
                  multiline
                  label={t("public_style_point")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_style_point_text")}
                />
                <NiceTextField
                  id="rank_spirit"
                  variant="standard"
                  multiline
                  label={t("public_spirit_point")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_spirit_point_text")}
                />
                <NiceTextField
                  id="rank_game"
                  variant="standard"
                  multiline
                  label={t("public_game_point")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_game_point_text")}
                />
                <NiceTextField
                  id="rank_followers"
                  variant="standard"
                  multiline
                  label={t("public_crowd_point")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_crowd_point_text")}
                />
              </div>
            </TabPanel>
            <TabPanel sx={{ p: 0, m: 0 }} value="DisplaySchedule">
              {" "}
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {programName}
              </Typography>
              <div>
                <Typography
                  align="left"
                  sx={{ mt: 0, ml: 0, pb: 2 }}
                  variant="h5"
                >
                  {t("public_schedule")}
                </Typography>
                <TableContainer>
                  <TableBody>
                    <TableRow
                      key="522555"
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        sx={{ m: 0, p: 0, pb: 1 }}
                        style={{
                          whiteSpace: "nowrap",
                          //textOverflow: "ellipsis",
                          width: "182px",
                          border: "0px",
                          display: "block",
                          overflow: "hidden",
                        }}
                        align="left"
                      >
                        <Typography
                          sx={{ pt: 1, pb: 0 }}
                          variant="body2"
                          align="left"
                        >
                          <NiceSelectFormControl sx={{ m: 0 }}>
                            <InputLabel id="demo-simple-select-label-dm">
                              {t("public_game_day_filter")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label-dm"
                              id="action_team"
                              label="Action Team"
                              value={scheduleGameday || ""}
                              name="Action_team"
                              onChange={handleInputChangeFilterGameday}
                            >
                              <MenuItem
                                key="6464sss6ddd166"
                                value="All game days"
                              >
                                All game days
                              </MenuItem>
                              {programEvents.map((row) => (
                                <MenuItem key={row.sfid} value={row.event_day}>
                                  {row.event_day}
                                </MenuItem>
                              ))}
                            </Select>
                          </NiceSelectFormControl>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                        <Typography
                          sx={{ pt: 1, pb: 0 }}
                          variant="body2"
                          align="left"
                          style={{
                            whiteSpace: "nowrap",
                            //textOverflow: "ellipsis",
                            width: "182px",
                            display: "block",
                            overflow: "hidden",
                          }}
                        >
                          <NiceSelectFormControl sx={{ m: 0 }}>
                            <InputLabel id="demo-simple-select-label-dm">
                              {t("public_team_filter")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label-dm"
                              id="action_team"
                              label="Action Team"
                              value={scheduleTeam || ""}
                              name="Action_team"
                              onChange={handleInputChangeFilterTeam}
                            >
                              <MenuItem key="64646rrreee166" value="All teams">
                                All teams
                              </MenuItem>
                              {programTeams.map((row) => (
                                <MenuItem key={row.sfid} value={row.team_id}>
                                  {row.team_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </NiceSelectFormControl>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
                <TableContainer>
                  <TableBody>
                    {programGames.map((row) => {
                      return (
                        <TableRow
                          key={row.sfid}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="left">
                            <Typography sx={{ pt: 1, pb: 0 }} variant="body2">
                              {row.game_day_name}
                            </Typography>
                            <Typography sx={{ pt: 1, pb: 0 }} variant="body2">
                              <Avatar
                                name={row.team_name}
                                size="28"
                                src={
                                  "https://agxpxlknzr.cloudimg.io/" +
                                  row.team_image +
                                  "?width=28&height=28"
                                }
                              />
                              &nbsp;
                              <MuiLink
                                component="button"
                                onClick={() => viewTeam(row.team_id)}
                                variant="body1"
                                underline="hover"
                              >
                                {row.team_name}
                              </MuiLink>
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                            <Typography sx={{ pt: 1, pb: 0 }} variant="body2">
                              {format(parseJSON(row.game_date), "d MMMM")}
                            </Typography>
                            <Typography sx={{ pt: 1, pb: 0 }} variant="body2">
                              <MuiLink
                                component="button"
                                onClick={() => viewTeam(row.opponent_id)}
                                variant="body1"
                                underline="hover"
                              >
                                {row.opponent_name}
                              </MuiLink>
                              &nbsp;
                              <Avatar
                                name={row.opponent_name}
                                size="28"
                                src={
                                  "https://agxpxlknzr.cloudimg.io/" +
                                  row.opponent_image +
                                  "?width=28&height=28"
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </TableContainer>
              </div>
            </TabPanel>
            <TabPanel sx={{ p: 0, m: 0 }} value="DisplayDetails">
              {" "}
              <Typography sx={{ pt: 3, pb: 3 }} variant="h6">
                {programName}
              </Typography>
              <div>
                <Typography
                  align="left"
                  sx={{ mt: 0, ml: 0, pb: 2 }}
                  variant="h5"
                >
                  {t("public_details")}
                </Typography>
                <TableContainer></TableContainer>
              </div>
              <div>
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <CardHeader align="left" title={t("public_ar_program")} />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_hours")}
                            </Typography>
                            <Typography variant="h4">
                              {programAR_Q4_hours}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_observers")}
                            </Typography>
                            <Typography variant="h4">
                              {programAR_Q7_observers}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_actions")}
                            </Typography>
                            <Typography variant="h4">
                              {programAR_Q14_actions}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_people")}
                            </Typography>
                            <Typography variant="h4">
                              {programAR_Q18_people}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <br />
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <CardHeader align="left" title={t("public_ar_team")} />
                  <CardContent>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-dm">
                        {t("public_team_filter")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-dm"
                        id="action_team"
                        label="Action Team"
                        value={programActionTeam || ""}
                        name="Action_team"
                        onChange={handleInputChangeActionTeam}
                      >
                        {programTeams.map((row) => (
                          <MenuItem key={row.sfid} value={row.team_id}>
                            {row.team_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </NiceSelectFormControl>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_hours")}
                            </Typography>
                            <Typography variant="h4">
                              {teamAR_Q4_hours}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_observers")}
                            </Typography>
                            <Typography variant="h4">
                              {teamAR_Q7_observers}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_actions")}
                            </Typography>
                            <Typography variant="h4">
                              {teamAR_Q14_actions}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{}}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography sx={{ pb: 1 }} variant="body2">
                              {t("public_ar_people")}
                            </Typography>
                            <Typography variant="h4">
                              {teamAR_Q18_people}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            </TabPanel>
          </TabContext>
          {showNavigation === true ? (
            <div>
              <Paper
                sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                elevation={3}
              >
                <BottomNavigation
                  value={publicStep}
                  showLabels
                  onChange={handleChange}
                >
                  <BottomNavigationAction
                    label={t("public_switch")}
                    value="switch"
                  />
                  <BottomNavigationAction
                    label={t("public_ranking")}
                    value="ranking"
                  />
                  <BottomNavigationAction
                    label={t("public_schedule")}
                    value="schedule"
                  />
                  <BottomNavigationAction
                    label={t("public_details")}
                    value="league"
                  />
                </BottomNavigation>
              </Paper>
            </div>
          ) : (
            <div />
          )}
        </Box>
      </Container>
    </PublicLeagueLayout>
  );
};

export default PublicLeague;
