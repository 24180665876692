import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


function Program() {
  const [values, setValues] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [listCounter, setListCounter] = useState(0);
  const [searcName, setSearcName] = useState("");
  const sftoken = sessionStorage.getItem("sftoken");

  function resetFilters() {
    setSearcName("");
    setValues(rawData);
    setListCounter(rawData.length);
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearcName(value);
    scanList(value);
  };

  function scanList(theSearch) {
    var scanData = rawData;
    // for Search
    if (theSearch !== "") {
      scanData = scanData.reduce((filterItems, item) => {
        const tlc = item.Headline__c.toLowerCase();
        const tlc2 = item.Summary__c.toLowerCase();
        const slc = theSearch.toLowerCase();
        if (tlc.includes(slc) || tlc2.includes(slc)) {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
    }
    setValues(scanData);
    setListCounter(scanData.length);
  }

  useEffect(() => {
    FetchLibrary();
  },[]);

  var sqol = "select id";
  sqol = sqol + ", Headline__c";
  sqol = sqol + ", Asset__c";
  sqol = sqol + ", Origin__c";
  sqol = sqol + ", OwnerId";
  sqol = sqol + ", Release_Date__c";
  sqol = sqol + ", Type__c";
  sqol = sqol + ", Subtype__c";
  sqol = sqol + ", Summary__c";
  sqol = sqol + " from Media__c";
  sqol = sqol + " where Show_in_portal_library__c = True ";
  sqol = sqol + " ORDER BY Headline__c";

  const FetchLibrary = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      setRawData(data.records);
      setValues(data.records);
      setListCounter(data.records.length);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Library">
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Library
        </Typography>
        <Box>
          <FormControl sx={{ minWidth: 150 }}>
            <TextField
              id="filled-search"
              label="Free text search"
              value={searcName}
              type="search"
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <Button onClick={resetFilters}>Reset</Button>
          </FormControl>
        </Box>
        <div>
          <Grid container>
            <Grid item xs={12}>
              {/*
              <Button onClick={showJSON}>Show Items</Button>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue="All types"
                    value={contenttype}
                    label="Type"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>All types</MenuItem>
                    <MenuItem value={10}>Collateral</MenuItem>
                    <MenuItem value={20}>Templates</MenuItem>
                    <MenuItem value={30}>HowTos</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              */}
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                      <TableRow>
                        <TableCell>#{listCounter}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Button href={row.Asset__c} target={"_blank"}>
                              {row.Headline__c}
                            </Button>
                            <Typography
                              sx={{ ml: 1, pb: 0 }}
                              align="left"
                              variant="body2"
                            >
                              {row.Summary__c}
                            </Typography>
                          </TableCell>
                          <TableCell>{row.Type__c}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Program;
