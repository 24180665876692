import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";

function Guideline() {
  const [values, setValues] = useState([]);

  const [searchParams] = useSearchParams();
  const theGuidelineID = searchParams.get("code");

  useEffect(() => {
    GetGuideline();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const GetGuideline = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Media__c/App_Code__c/CoachGuide",
      requestOptions
    );
    const data = await response.json();
    // console.log("Date= ", data);
    try {
      setValues(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <form>
        <Grid align="left" container spacing={2}>
          <Grid item>
            <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
              Guideline
            </Typography>
            <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="body1">
              {values.Headline__c}
            </Typography>
            <Typography
              sx={{ pl: 1, ml: 1, pb: 0 }}
              align="left"
              variant="body2"
            >
              {values.Summary__c}
            </Typography>
            <Box sx={{ pl: 1, m: 1, pb: 4 }}>
              <div dangerouslySetInnerHTML={{ __html: values.Content__c }} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Guideline;
